import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { allTagsState, selectedTagsState } from '../../../AppState'
import { isSameTag, type NamespacedTag, stringToNamespacedTag } from '@src/utils/tags'

export default function Menus(props: { tags: [string, NamespacedTag[]][] }) {
    const [selectedTags, setSelectedTags] = useRecoilState(selectedTagsState)
    const allTags = useRecoilValue(allTagsState)
    const selectedTagsArray = Array.from(selectedTags)

    const selectHandler = (namespace: string, tag: NamespacedTag | null) => {
        setSelectedTags((prev) => {
            const updatedTags: string[] = Array.from(prev ?? [])
                .map((t) => {
                    const currentNamespacedTag = stringToNamespacedTag(t)

                    // se a tag nao pertence ao namespace, nao altera
                    if (currentNamespacedTag.namespace !== namespace) {
                        return t
                    }

                    return null
                })
                .filter((t) => t !== null) as string[]

            if (tag !== null) {
                const originalTag = allTags?.find((t) => isSameTag(t, tag)) ?? '' // the null condition can't happen
                updatedTags.push(originalTag)
            }
            return new Set(updatedTags)
        })
    }

    const result = props.tags.map(([namespace, tag]) => {
        const selectedTag = stringToNamespacedTag(selectedTagsArray.find((s) => stringToNamespacedTag(s).namespace === namespace) || '')
        return (
            <select
                key={namespace}
                className='select select-xs select-ghost max-w-xs text-xs '
                onChange={(e) => {
                    const tag = e.target.value
                    if (tag === '') {
                        selectHandler(namespace, null)
                    } else {
                        const selectedTag = tag
                        selectHandler(namespace, {
                            namespace,
                            tag: selectedTag,
                            position: 0,
                        })
                    }
                }}
                value={selectedTag.tag}
            >
                <option disabled selected>
                    {namespace}
                </option>
                <option value=''>Qualquer {namespace}</option>
                {tag.map((t) => (
                    <option value={t.tag} key={t.tag}>
                        {t.tag}
                    </option>
                ))}
            </select>
        )
    })

    return <>{result}</>
}
