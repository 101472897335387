import React, { useEffect } from 'react'
import { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { PlayerStatus, zoomState } from '../AppState'
import CreativeZoom from '../creative/zoom'
import { ZOOM_DICTIONARY } from '../utils/zoom'
import PreviewTimeBarControl from './time-bar-control'
import { MdCenterFocusStrong } from 'react-icons/md'

type Props = {
    gifRef: any
    handleCenterPreview: () => void
}

const PreviewGifControls = ({ gifRef, handleCenterPreview }: Props) => {
    const zoom = useRecoilValue(zoomState)
    const [{ time, isPlaying, totalTime }, setStatus] = useRecoilState(PlayerStatus)
    const [actualTime, setActualTime] = useState(0)
    const onPlay = () => {
        gifRef.play()

        setStatus((value: any) => {
            return {
                ...value,
                isPlaying: true,
            }
        })
    }

    const onPause = () => {
        gifRef.pause()

        setStatus((value: any) => ({
            ...value,
            actualTime: gifRef.currentFrameIndex,
            isPlaying: false,
        }))
    }

    const restartHandle = () => {
        gifRef.currentFrameIndex = 0
        gifRef.pause()
        setActualTime(0)

        setStatus((value: any) => {
            return {
                ...value,
                actualTime: 0,
                isPlaying: false,
            }
        })
    }

    const playPauseHandle = () => {
        if (!isPlaying) onPlay()
        else onPause()
    }

    useEffect(() => {
        if (gifRef) {
            setActualTime(() => {
                gifRef.moveTo(time)

                return time
            })
        }
    }, [time])

    useEffect(() => {
        if (gifRef?.isReady?.() && isPlaying)
            onPlay()

        if (gifRef && !gifRef.onUpdate) {
            gifRef.onUpdate = (frame: number) => {
                setActualTime(() => {
                    return frame
                })
            }
        }
    }, [gifRef])

    const generateRootCurrentTimeVar = useMemo(() => {
        return `
        :root {
          --current-time: ${actualTime}ms !important;
        }
      `
    }, [actualTime, zoom])

    return (
        <>
            <PreviewTimeBarControl actualTime={actualTime} />
            <style>{generateRootCurrentTimeVar}</style>
            <div className='w-full grow flex flex-row items-center space-between pt-3'>
                <div className='w-full grow flex flex-row items-center justify-start gap-x-5'>
                    <button className='btn p-1 btn-sm' onClick={playPauseHandle}>
                        {isPlaying ? (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-5 h-5'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M15.75 5.25v13.5m-7.5-13.5v13.5'
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-5 h-5'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z'
                                />
                            </svg>
                        )}
                    </button>
                    <button className='btn p-1 btn-sm' onClick={restartHandle}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-5 h-5'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                            />
                        </svg>
                    </button>
                    <p className='tabular-nums text-sm whitespace-nowrap'>
                        {actualTime} / {totalTime}
                    </p>
                </div>

                <div className='flex space-x-4'>
                    <CreativeZoom />
                    <button className='btn btn-sm' onClick={handleCenterPreview} data-tooltip-id='my-tooltip' data-tooltip-content='Centralizar formato'>
                        <MdCenterFocusStrong />
                    </button>
                </div>
            </div>
        </>
    )
}

export default PreviewGifControls
