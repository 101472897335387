import React, { useTransition, ChangeEvent, useMemo } from 'react'
import ButtonChangeOrder from '../button-change-order'
import { BiSearchAlt as SearchIcon } from 'react-icons/bi'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { campaignListOrderState, campaignsListState, clientFilterState, searchTextState } from '../../AppState'
import { DictionaryForOrder } from '../../types'
import classNames from 'classnames'
import { SelectInput } from '../SelectInput/SelectInput'

const SearchBar = () => {
    const order = useRecoilValue(campaignListOrderState)
    const setSearch = useSetRecoilState(searchTextState)
    const campaigns = useRecoilValue(campaignsListState)
    const [clientFilter, setClientFilter] = useRecoilState(clientFilterState)
    const [isPending, startTransition] = useTransition()

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        startTransition(() => {
            setSearch(event.target.value)
        })
    }

    const orderDictionary: DictionaryForOrder = {
        client: 'nome do cliente',
        name: 'nome da campanha',
        updatedAt: 'última atualização',
        createdAt: 'último comentário'
    }

    const inputDisabled = order.by === 'createdAt' || order.by === 'updatedAt'

    const clients = useMemo(() => {
        const clients = new Set<string>()
        campaigns.forEach((campaign) => clients.add(campaign.client))
        const sortedClients = Array.from(clients)
            .sort((a, b) => a.localeCompare(b))
        return [
            { label: 'Filtrar por cliente', value: null },
            ...sortedClients.map((client) => ({ label: client, value: client }))
        ]
    }, [campaigns])

    return (
        <div className='flex flex-row gap-x-4 flex-nowrap form-control w-full my-4'>
            <div className={
                classNames('flex items-center w-full bg-base-100 rounded-lg pr-4 focus-within:ring focus-within:ring-blue-500 focus-within:rounded', {
                    'opacity-50 cursor-not-allowed': inputDisabled
                })
            }>
                <input
                    type='text'
                    placeholder={`Busque campanhas pelo ${orderDictionary[order.by]}...`}
                    className={classNames('input input-bordered w-full bg-transparent border-0 focus:border-0 focus:outline-none', {
                        'cursor-not-allowed': inputDisabled
                    })}
                    onChange={handleSearch}
                    readOnly={inputDisabled}
                />
                <SearchIcon size='1.5rem' />
            </div>

            <div className='min-w-[200px] z-20'>
                <SelectInput
                    name='client-filter'
                    defaultOption={clientFilter 
                        ? { label: clientFilter, value: clientFilter }
                        : undefined
                    }
                    options={clients}
                    onChange={(value) => setClientFilter(value)}
                />
            </div>

            <ButtonChangeOrder />
        </div>
    )
}

export default SearchBar
