import classNames from 'classnames'
import React from 'react'

type Props = {
    onClose: () => void
    onConfirm: () => void
    onDecline?: () => void
    isLoading: boolean
    title: string
    description: string
    moreDetails?: JSX.Element // Para receber um componente com uma lista de usuários por ex
    onCloseMessage?: string
    onConfirmMessage?: string
    destructiveAction?: boolean
}

const ConfirmDialog = ({
    onClose,
    onConfirm,
    onDecline = onClose,
    isLoading,
    title,
    description,
    moreDetails,
    onCloseMessage = 'Cancelar',
    onConfirmMessage = 'Excluir',
    destructiveAction = true,
}: Props) => {
    const hiddenBodyOverflow = `
        body { overflow: hidden }
    `

    return (
        <div className='modal modal-open bg-slate-800/90'>
            <style>{hiddenBodyOverflow}</style>
            <div className='modal-box w-[50rem] max-w-none h-auto overflow-hidden rounded-md'>
                <button className='btn btn-sm btn-circle absolute right-4 top-4' onClick={onClose}>
                    ✕
                </button>
                <h3 className='font-bold text-lg'>{title}</h3>
                <small className='text-info text-sm'>{description}</small>
                {moreDetails && (
                    <section>
                        <div className='divider my-2'></div>
                        {moreDetails}
                    </section>
                )}
                <div className='bg-base-100 sm:rounded-box sm:py-4 sm:px-0 grow'>
                    <div className='modal-action'>
                        <button className={classNames('btn', {
                                loading: isLoading,
                            })} onClick={onDecline}>
                            {onCloseMessage}
                        </button>
                        <button
                            className={classNames('btn', {
                                'btn-error': destructiveAction,
                                'btn-primary': !destructiveAction,
                                loading: isLoading,
                            })}
                            onClick={onConfirm}
                        >
                            {onConfirmMessage}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConfirmDialog
