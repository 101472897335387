import React, { ReactNode, ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
    children: ReactNode
}

const LayoutContainer = ({ children, className, ...props }: Props) => {
    return (
        <div
            className={twMerge(
                'flex flex-col gap-y-4 mx-auto bg-base-300 w-full sm:max-w-8xl sm:rounded-box p-3 relative h-full',
                className
            )}
            {...props}
        >
            {children}
        </div>
    )
}

export default LayoutContainer
