import React from 'react'
import TagsList from './tags-list'
import StatusList from './status-list'
import { useRecoilValue } from 'recoil'
import { selectedTagsState } from '../../../AppState'

export default function FilterArea() {
    const { size } = useRecoilValue(selectedTagsState)
    const hasFilters = size > 0 

    return (
        <div className='w-full relative z-[101] h-12'>
            <div className='flex-1 flex-wrap w-full'>
                <div
                    className='collapse collapse-arrow border border-base-100 bg-base-100 opacity-95 rounded-box w-full shadow-md'
                    tabIndex={0}
                >
                    <input type='checkbox' className='min-h-fit' />
                    <div className='flex items-center collapse-title text-base font-semibold w-full h-12 c min-h-fit px-4 py-0'>
                        Filtros {hasFilters && (<>aplicados: <span className='font-light text-gray-500'>&nbsp;{size}</span></>)}
                    </div>
                    <div className='collapse-content max-w-none select-none'>
                        <TagsList />
                        <div className='divider my-2'></div>
                        <StatusList />
                    </div>
                </div>
            </div>
        </div>
    )
}
