import React, { useState } from 'react';
import Select, { StylesConfig } from 'react-select';

interface Props {
  options: SelectOptionsProps[];
  name: string;
  error?: string;
  defaultOption?: SelectOptionsProps;
  onChange: (value: any) => void;
}

export interface SelectOptionsProps {
  label: string;
  value: any;
}

const customStyles: StylesConfig<SelectOptionsProps> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#0f1729',
    border: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#89909c',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#0f1729',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#212a40' : '#0f1729',
    '&:hover': {
      backgroundColor: '#212a40',
    }
  }),
};

export const SelectInput = ({
  name,
  error,
  options,
  defaultOption,
  onChange,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<SelectOptionsProps | null>(defaultOption || null);

  const handleChange = (selectedOption: SelectOptionsProps | null) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption?.value);
  };

  return (
    <div className="w-full">
      <div className="mt-1">
        <Select
          id={name}
          value={selectedOption}
          isSearchable
          options={options}
          onChange={handleChange}
          placeholder="Filtrar por cliente"
          styles={customStyles}
          isMulti={false}
          isClearable
        />

        {error && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
