import React, { useRef, useEffect, useMemo } from 'react'
import { Rect, Circle, Line, Transformer } from 'react-konva'
import { ShapeType, Shapes } from '../../../utils/useDraw/draw.types'
import { TextShape } from './text-shape'
import useDraw from '@src/utils/useDraw'

type Props = {
    isSelected: boolean
    onSelect: () => void
    shapeProps: Shapes
    type: ShapeType
    drawHook: ReturnType<typeof useDraw>
}

const ShapeElement = ({ shapeProps, type, isSelected, onSelect, drawHook }: Props) => {
    const shapeRef = useRef<any>(null)
    const transformerRef = useRef<any>(null)

    const memoizedShapeProps = useMemo(() => ({ ...shapeProps }), [{ ...shapeProps }])

    useEffect(() => {
        if (!transformerRef.current || !shapeRef.current) return

        if (isSelected) {
            transformerRef.current.nodes([shapeRef.current])
            transformerRef.current.getLayer().batchDraw()
        }
    }, [isSelected, onSelect, memoizedShapeProps])

    const shapeElement = useMemo(() => {
        const props = {
            onClick: onSelect,
            onTap: onSelect,
            draggable: isSelected,
            ...memoizedShapeProps,
        }

        switch (type) {
            case 'rectangle':
                return <Rect ref={shapeRef} {...props} />
            case 'pen':
                return <Line ref={shapeRef} {...props} />
            case 'eraser':
                return <Line ref={shapeRef} {...props} />
            case 'circle':
                return <Circle ref={shapeRef} {...props} />
            case 'text':
                return <TextShape ref={shapeRef} drawHook={drawHook} shapeProps={memoizedShapeProps} {...props} />
            default:
                null
        }
    }, [memoizedShapeProps, isSelected, onSelect, type])

    return (
        <>
            {shapeElement}
            {isSelected && <Transformer ref={transformerRef} />}
        </>
    )
}

export default ShapeElement
