import React from 'react'
import AdminUsers from '.'

type Props = {
    client: string
    campaign: string
    onSubmit: () => void
    onClose: () => void
}

const AddUsersDialog = ({ client, campaign, onClose, onSubmit }: Props) => {
    const hiddenBodyOverflow = `
        body { overflow: hidden }
    `

    return (
        <div className='modal modal-open bg-slate-800/90 overflow-y-auto py-10'>
            <style>{hiddenBodyOverflow}</style>
            <div className='m-auto max-h-max w-full max-w-4xl relative'>
                <button className='btn btn-sm btn-circle absolute right-4 top-10 z-50' onClick={onClose}>
                    ✕
                </button>
                <AdminUsers selectedClient={client} selectedCampaign={campaign} onSubmit={onSubmit} />
            </div>
        </div>
    )
}

export default AddUsersDialog
