import React from 'react'
import dayjs from 'dayjs'

import { router } from '../AppState'

type Props = {
    comment: {
        authorName: string
        text: string
        createdAt: string
        creative: string
        url: string
    }
}

const CommentTimeline = ({ comment }: Props) => {
    const { authorName, text, createdAt, creative, url } = comment

    const onClick = () => {
        router.history.push('/view' + url)
    }

    return (
        <div className='pb-4 px-2 mb-4 relative'>
            <div className='flex items-center mb-1'>
                <span className='absolute left-5 top-6 bg-slate-500/75 h-full w-[1px]'></span>
                <div
                    className='border rounded-full w-6 h-6 mr-2 flex justify-center items-center relative border-slate-500/75 bg-slate-300 text-cyan-900 cursor-pointer'
                    onClick={onClick}
                >
                    <svg xmlns='http://www.w3.org/2000/svg' height='12' viewBox='0 0 18 18' width='12'>
                        <title>S Comment 18 N</title>
                        <rect id='Canvas' fill='currentColor' opacity='0' width='18' height='18' />
                        <path
                            fill='currentColor'
                            d='M3,2A2,2,0,0,0,1,4v7a2,2,0,0,0,2,2H4v4.3965a.25.25,0,0,0,.427.1765L9,13h6a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2Z'
                        />
                    </svg>
                </div>
                <p className='text-xs text-slate-300'>
                    <strong>{authorName}</strong> comentou: - {dayjs(createdAt).locale('pt-br').fromNow()}
                </p>
            </div>
            <h3 className='text-xs text-slate-400 ml-8 mb-2'>
                <strong>Criativo:</strong> {creative}
            </h3>
            <h3 className='text-sm text-slate-300 ml-8'>{text}</h3>
        </div>
    )
}

export default CommentTimeline
