import React, { useState } from 'react'

type Option = {
    value: string
    label: string
}

type Props = {
    label: string
    value: string
    onChange: (value: string) => void
    description?: string
    items: Option[]
    disabled?: boolean
}

const FormSelect = ({ label, value, onChange, description, items, disabled }: Props) => {
    const [error, setError] = useState<string | undefined>()

    const handleChange = (e: any) => {
        const value = e.target.value

        onChange(value)
    }

    return (
        <div className='form-control'>
            <div className='flex justify-between items-center'>
                <label className='label'>
                    <span className='label-text'>{label}</span>
                </label>
            </div>

            <select
                className='input input-bordered'
                name='login'
                value={value}
                onChange={handleChange}
                disabled={disabled}
            >
                <option value='' disabled>
                    Selecionar
                </option>

                {items.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>

            {error && (
                <label>
                    <span className='label-text-alt text-red-600'>{error}</span>
                </label>
            )}
            {!error && description && (
                <label>
                    <span className='label-text-alt'>{description}</span>
                </label>
            )}
        </div>
    )
}

export default FormSelect
