import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import Loading from '../components/loading'
import CreativeComponent from './creative.component'
import StatusChange from './status-change.component'
import Reaction from './reaction.component'
import CommentTimeline from './comment.component'
import LayoutContainer from '../components/layout-container'

const Timeline = () => {
    const params = useParams()
    const { key } = params

    const [timeline, setTimeline] = useState([])
    const [loading, isLoading] = useState(true)

    const getTimelineActions = async () => {
        isLoading(true)

        try {
            const request = await axios.get(`/api/admin/campaign/${key}/timeline`)
            const response = request.data
            if (response.ok) {
                setTimeline(response.items)
            }
        } catch (err) {
            console.error(err)
        } finally {
            isLoading(false)
        }
    }

    const componentRender = useMemo(() => {
        const components = timeline.map((item: any, i: number) => {

            if (item.name) {
                return <CreativeComponent key={item.key+i} creative={item} />
            } else if (item.text) {
                return <CommentTimeline key={item.key+i} comment={item} />
            } else if (item.status) {
                return <StatusChange key={item.key+i} statusChange={item} />
            } else if (item.icon) {
                return <Reaction key={item.key+i} reaction={item}  />
            }
        })

        return components
    }, [timeline])

    useEffect(() => {
        getTimelineActions()
    }, [key])

    return loading ? (
        <Loading className='h-96' />
    ) : (
        <LayoutContainer>
            <div className='flex-1 w-full mx-auto bg-base-300 sm:rounded-box mt-6 p-4 relative overflow-hidden pb-16'>
                <div className='w-full mx-auto p-4'>
                    <h1 className='text-lg sm:text-2xl font-bold grow'>Linha do tempo de ações</h1>
                </div>

                <div className='w-full mx-auto p-4 bg-base-200 h-full overflow-y-auto'>{componentRender}</div>
            </div>
        </LayoutContainer>
    )
}

export default Timeline
