import React, { useCallback, useMemo, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'
import { useRecoilValue } from 'recoil'

import { currentUserAtom } from '../../../../AppState'

import { Reaction } from '../../../../types'

type Props = {
    campaign: string
    creative: string
    revision: string
    comment: string
    reactions: Reaction[]
    onChange: (value: Reaction[]) => void
}

const CommentReactions = ({ campaign, creative, revision, comment, reactions, onChange }: Props) => {
    const icons = {
        ':heart:': {
            icon: '❤️',
            count: 0,
            isMine: false,
        },
        ':like:': {
            icon: '👍',
            count: 0,
            isMine: false,
        },
        ':unlike:': {
            icon: '👎',
            count: 0,
            isMine: false,
        },
        ':smile:': {
            icon: '😄',
            count: 0,
            isMine: false,
        },
        ':check:': {
            icon: '✅',
            count: 0,
            isMine: false,
        },
        ':star:': {
            icon: '🌟',
            count: 0,
            isMine: false,
        },
        ':sad:': {
            icon: '😕',
            count: 0,
            isMine: false,
        },
        ':question:': {
            icon: '❓',
            count: 0,
            isMine: false,
        },
    }

    const [showAll, setShowAll] = useState(false)
    const [sending, isSending] = useState(false)
    const currentUser = useRecoilValue(currentUserAtom)

    const filteredReactions = useMemo(() => {
        if (!currentUser) return
        const tmpIcons = Object.assign(icons, {})

        reactions?.forEach((r) => {
            let icon = tmpIcons[r.icon]
            icon.count += 1

            if (r.user === currentUser.email) icon.isMine = true
        })

        return tmpIcons
    }, [reactions, currentUser])

    const reactionsList = useCallback(
        (name: string) => {
            const tmpReactions = reactions?.filter((r) => r.icon === name)
            if (tmpReactions?.length > 9)
                return [...tmpReactions.slice(0, 9).map((r, index) => <p key={index}>{r.user}</p>), <p>+{tmpReactions.length - 9}</p>]

            return tmpReactions?.map((r, index) => <p key={index}>{r.user}</p>) || []
        },
        [reactions]
    )

    const toggleReaction = async (name: string) => {
        const iconIsMine = filteredReactions?.[name].isMine

        isSending(true)
        if (iconIsMine) await removeReact(name)
        else await addReact(name)
        isSending(false)
    }

    const addReact = async (name: string) => {
        if (!currentUser) return

        const request = await axios.post(
            `/api/common/comments/${campaign}/creative/${creative}/${revision}/comment/${comment}`,
            {
                reaction: name,
            }
        )
        const result = request.data

        if (!result.ok) return

        const newReactions: Reaction[] = [...reactions, result.item]

        onChange(newReactions)
    }

    const removeReact = async (name: string) => {
        const reaction = reactions?.find((r) => r.icon === name && r.user === currentUser?.email)

        if (reaction) {
            const newReactions = reactions?.filter((r) => r.icon !== name || r.user !== currentUser?.email)

            const request = await axios.delete(
                `/api/common/comments/${campaign}/creative/${creative}/comment/${comment}/reaction/${reaction.key}`
            )
            const result = request.data

            if (result.ok) onChange(newReactions)
        }
    }

    const toggleAll = () => {
        setShowAll((value) => !value)
    }

    return (
        <div className='absolute right-4 -bottom-3'>
            {filteredReactions &&
                Object.keys(filteredReactions).map((name) => {
                    const icon = filteredReactions[name]
                    const reactionUsers = reactionsList(name)

                    if (reactionUsers?.length === 0 && !showAll) return null

                    return (
                        <button
                            key={`${comment}-${name}`}
                            className={`relative btn btn-xs rounded-full py-[0.4rem] px-1 ${
                                icon.isMine ? 'bg-blue-500' : 'bg-gray-700'
                            } ${!showAll ? 'pointer-events-none -mr-3' : ''}`}
                            onClick={() => toggleReaction(name)}
                            data-tooltip-id='my-tooltip'
                            data-tooltip-html={
                                reactionUsers?.length > 0
                                    ? ReactDOMServer.renderToStaticMarkup(<div>{reactionUsers}</div>)
                                    : undefined
                            }
                            data-tooltip-place='top'
                            disabled={sending}
                        >
                            {icon.icon}
                            {icon.count > 0 && (
                                <span className='absolute -top-1 right-1 text-xs font-light'>
                                    {icon.count > 9 ? '+9' : icon.count}
                                </span>
                            )}
                        </button>
                    )
                })}
            <button
                key={`${comment}-toggle-all`}
                className={`relative rounded-full w-6 h-6 bg-gray-700 text-xs z-20`}
                onClick={toggleAll}
                data-tooltip-id='my-tooltip'
                data-tooltip-html='Mostrar reações'
                data-tooltip-place='top'
            >
                ...
            </button>
        </div>
    )
}

export default CommentReactions
