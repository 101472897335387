import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
const ButtonClose = ({ ...props }: ComponentProps<'button'>) => {
    return (
        <button
            {...props}
            className={twMerge(
                'btn btn-neutral btn-sm btn-circle absolute right-4 top-4 z-[1]',
                props.className
            )}>
            x
        </button>
    )
}

export default ButtonClose
