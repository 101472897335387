import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

export type HttpResponseProps<T> = {
    data: T
    status: number
}

export type HttpRequestErrorProps = {
    response?: {
        status?: number
    }
    message: string
}

export class HttpService {
    private readonly http: AxiosInstance

    constructor() {
        this.http = axios.create()
        this.setupInterceptors()
    }

    private setupInterceptors() {
        this.http.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                const { message } = error
                return Promise.reject({
                    response: {
                        status: error.response?.status,
                    },
                    message: message,
                })
            }
        )
    }

    async request<T>(config: AxiosRequestConfig): Promise<HttpResponseProps<T>> {
        try {
            const response: AxiosResponse<T> = await this.http.request({
                baseURL: '',
                timeout: 30000,
                ...config,
            })

            return {
                data: response.data,
                status: response.status,
            }
        } catch (error) {
            throw error
        }
    }
}
