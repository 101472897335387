import { useRef, useState } from "react"

export const usePlayerUtils = () => {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const draggingTimerRef = useRef<NodeJS.Timeout | null>(null)

  const dragEvents = {
    displayVerticalScroll: false,
    displayHorizontalScroll: false,
    useMouseDrag: true,
    onDragStart: () => handleIsDragging(true),
    onDragEnd: () => handleIsDragging(false),
  }

  const handleIsDragging = (value: boolean) => {
    clearTimeout(draggingTimerRef.current as NodeJS.Timeout)
    
    if (!value)
      return setIsDragging(value);

    draggingTimerRef.current = setTimeout(() => {
      setIsDragging(value)
    }, 100)
  }

  return {
    isHovering,
    setIsHovering,
    isDragging,
    dragEvents,
  }
}