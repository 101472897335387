import React from 'react'
import TableHeaderCell from './table-header.cell'

const TableHeader = () => {
    const campaignAttr = ['client', 'name', 'updatedAt', 'createdAt']

    return (
        <thead>
            <tr>
                {campaignAttr.map((attr, idx) => (
                    <TableHeaderCell label={attr} className='bg-base-200' key={`${idx}-${attr}`} />
                ))}
                <th className='cursor-not-allowed bg-base-200'> Opções </th>
            </tr>
        </thead>
    )
}

export default TableHeader