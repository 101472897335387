import React from 'react'
import classNames from 'classnames'
import { useRecoilState } from 'recoil'
import { selectedStatusState } from '../../../AppState'

const StatusList = () => {
    const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusState)

    const statusDict = [
        ['new', 'Nova '],
        ['approved', 'Aprovado'],
        ['reproved', 'Reprovado'],
        ['revised', 'Revisado'],
    ]

    const toggleStatus = (tag: string) => {
        if (!selectedStatus.delete(tag)) selectedStatus.add(tag)
        const s = Array.from(selectedStatus)
        setSelectedStatus(new Set(s))
    }

    return (
        <>
            {statusDict.map(([key, label]) => (
                <span
                    key={key}
                    tabIndex={0}
                    className={classNames('cursor-pointer badge m-1 badge-sm badge-secondary', {
                        'badge-outline': !selectedStatus.has(key),
                    })}
                    onClick={toggleStatus.bind(null, key)}
                >
                    {label}
                </span>
            ))}
        </>
    )
}

export default StatusList
