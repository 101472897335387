import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type LoadingSpinnerProps = ComponentProps<'div'> & {
	text?: string
	progress?: number
	spinnerSize?: number
}

const LoadingSpinner = ({ text, spinnerSize, progress, className }: LoadingSpinnerProps) => {
	return (
		<div className={twMerge('w-full h-full flex flex-col justify-center items-center', className)}>
			<svg
				className={twMerge(
					'animate-spin -ml-1 mb-3 text-white',
					spinnerSize ? `h-${spinnerSize} w-${spinnerSize}` : 'h-5 w-5'
				)}
				xmlns='http://www.w3.org/2000/svg'
				fill='none'
				viewBox='0 0 24 24'
			>
				<circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
				<path
					className='opacity-75'
					fill='currentColor'
					d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
				></path>
			</svg>
			{(text || 'Carregando...')} {progress !== undefined ? `${progress}%` : ''}
		</div>
	)
}

export default LoadingSpinner