import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import classNames from 'classnames'
import { useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import toast from 'react-hot-toast'

import { currentUserAtom, router } from '../AppState'
import { User } from '../types'

const ClientLogin = () => {
    const {
        loginWithPopup,
        logout,
        getIdTokenClaims,
        isAuthenticated,
        isLoading: authLoading,
        error: auth0Error,
        user,
    } = useAuth0()

    const [popupOpened, setPopupOpened] = useState(false)
    const popupRef = useRef<Window | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState('')
    const [hasAuthError, setHasAuthError] = useState<boolean>(false)
    const setCurrentUser = useSetRecoilState(currentUserAtom)

    const params = useParams()
    const client = params.client as string
    const campaign = params.campaign as string
    const key = params.key as string

    const doLogin = async (data: any) => {
        if (isLoading) return
        setIsLoading(true)
        try {
            const claims = await getIdTokenClaims()
            const token = claims?.__raw

            let req = await axios.post(
                '/api/login',
                { client, campaign, key, login: data.email },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )

            const me = req.data as null | { ok: boolean; user: User, key: string }
            
            if (me === null) {
                setHasAuthError(true)
                setCurrentUser(null)
                setError('Você não tem permissão para acessar essa campanha.')
                logout()
            } else {
                setCurrentUser(me.user)
                if(!key) router.history.push(`/view/${client}/${campaign}/${me.key}`)

                toast.success('Usuário logado com sucesso')
            }
        } catch (e) {
            const message =
                e.response.status === 404
                    ? 'Você não tem permissão para acessar essa campanha.'
                    : 'Alguma coisa deu errado'

            setError(message)
            setHasAuthError(true)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (auth0Error && popupRef.current) {
            switch (auth0Error.message) {
                case 'Timeout':
                    popupRef.current.close()
                    setError('Tempo de login excedido, tente novamente.')
                    break
                case 'Popup closed':
                    setError('Popup fechado antes da autenticação, tente novamente.')
                    break
                default:
                    popupRef.current.close()
                    setError('Erro inesperado, tente novamente.')
            }
            setHasAuthError(true)
        }
    }, [auth0Error])

    const usePopup = () => {
        if (!window.top) return

        const y = window.top.outerHeight / 2 + window.top.screenY - 600 / 2
        const x = window.top.outerWidth / 2 + window.top.screenX - 400 / 2

        popupRef.current = window.open('', 'popup', `height=${600},width=${400},top=${y},left=${x}`)
    }

    const loginAction = async () => {
        setError('')
        setHasAuthError(false)

        if (!authLoading && !popupOpened) {
            usePopup()
            setPopupOpened(true)

            try {
                if (!popupRef.current) throw new Error('Não foi possível iniciar a autenticação, tente novamente.')
                await loginWithPopup(undefined, {
                    popup: popupRef.current,
                    timeoutInSeconds: 600,
                })

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message)
                }
                setHasAuthError(true)
            } finally {
                setPopupOpened(false)
            }
        }
    }

    useEffect(() => {
        if (isAuthenticated && user) {
            doLogin(user)
        }
    }, [isAuthenticated])

    return (
        <div className='hero h-full'>
            <div className='hero-content flex-col lg:flex-row '>
                <div className='text-center lg:text-left lg:min-w-[425px] lg:max-w-[425px]'>
                    <h1 className='text-5xl font-bold'>Bem vindo</h1>
                    <p className='py-6 max-w-lg'>
                        Faça login para prosseguir, você será redirecionado para a campanha após autenticação: <br/><br/>
                        {client} &gt; {campaign}
                    </p>
                </div>
                <div
                    className={classNames('card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100 border-2 ', {
                        'border-error': hasAuthError,
                        'border-base-100': !hasAuthError,
                    })}
                >
                    <div className='card-body'>
                        <div className='form-control'>
                            <button
                                className={classNames('btn btn-primary', {
                                    'btn-disabled': authLoading || popupOpened,
                                    loading: authLoading || popupOpened,
                                })}
                                onClick={loginAction}
                            >
                                {authLoading || popupOpened ? 'Aguardando autenticação...' : 'Login'}
                            </button>

                            {hasAuthError && <p className='mt-2 text-center text-sm text-red-400'>{error}</p>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientLogin
