import React, { CSSProperties } from 'react'
import CreativePreview from '../creative-preview/creative-preview'

import { useRecoilState, useRecoilValue } from 'recoil'
import { CampaignDetails, currentCreative, fullscreenState, router } from '../../../AppState'
import classNames from 'classnames'
import { EditorProvider } from '@src/contexts/useEditor/use.editor'
import { PreviewProvider } from '@src/contexts/usePreview/use.preview'
import Comments from '@src/components/editor/comments'
import { NavigationProvider } from '@src/utils/useNavigationHandlers'
import { twMerge } from 'tailwind-merge'

export const CreativeDialog = () => {
    const fullscreen = useRecoilValue(fullscreenState)
    const [creative, setCreative] = useRecoilState(currentCreative)
    const { key, url } = useRecoilValue(CampaignDetails)

    if (!creative) {
        return null
    }

    const onClose = () => {
        router.history.push(`${url}/${key + router.history.location.search}`)
        setCreative(null)
    }

    const fullscreenStyle: CSSProperties = fullscreen
        ? {
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            maxHeight: 'none',
            width: '100vw',
        }
        : {
            maxHeight: 'none',
        }

    return (
        <div className='modal modal-open bg-slate-800/90'>
            <style>{"body { overflow: hidden }"}</style>

            <div
                className={classNames(
                    'modal-box max-w-none w-[calc(100vw-2rem)] h-[calc(100vh-2rem)] overflow-hidden rounded-sm pl-2',
                    {
                        'py-0 px-4': fullscreen,
                    }
                )}
                style={fullscreenStyle}
            >
                <div
                    className={twMerge(
                        'absolute -right-2 -top-2 none z-10 p-2 cursor-pointer rounded-full',
                        fullscreen && 'invisible hidden',
                    )}
                    onClick={onClose}
                >
                    <button
                        className={twMerge(
                            'btn btn-sm text-lg text-black btn-circle bg-white',
                            'hover:bg-slate-200',
                        )}
                    >
                        ✕
                    </button>
                </div>

                <div className='w-full h-full bg-base-100 sm:rounded-box grow'>
                    <div className='block overflow-y-auto horizontal h-full md:flex md:overflow-hidden'>
                        <div className='relative flex flex-row w-full h-full'>
                            <NavigationProvider>
                                <EditorProvider>
                                    <PreviewProvider>
                                        <CreativePreview className='bg-base-300' />

                                        <div className='w-1/4 min-w-[320px] overflow-hidden rounded-xl'>
                                            <Comments
                                                campaignKey={key}
                                                creative={creative}
                                            />
                                        </div>
                                    </PreviewProvider>
                                </EditorProvider>
                            </NavigationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
