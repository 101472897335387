import React from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { RecoilReactRouter } from '@lagunovsky/recoil-react-router'
import { Route, Routes } from 'react-router'
import { Toaster } from 'react-hot-toast'
import { Tooltip } from 'react-tooltip'
import { useRecoilValue } from 'recoil'

import { currentUserAtom, router } from './AppState'

import { AdminCampaigns } from './admin/campaigns'
import Users from './admin/campaigns/users'
import Gallery from './Views/gallery'
import Empty from './empty'
import CheckAdminAuth from './auth/admin'
import NeedLogin from './auth/login'
import AdminUsers from './admin/users'

import Timeline from './timeline'
import Navbar from './components/navbar'
import Warning from './components/warning'

import 'react-tooltip/dist/react-tooltip.css'
import classNames from 'classnames'
import ClientLogin from './auth/client'
import { Logout } from './auth/logout'

dayjs.extend(customParseFormat)

export function App() {
    const currentUser = useRecoilValue(currentUserAtom)
    const isDev = process.env.NODE_ENV === 'development'

    return (
        <main>
            <Navbar />
            <div className={classNames('w-full overflow-hidden mx-auto p-4', {
                'h-development': isDev,
                'h-production': !isDev
            })}>
                <RecoilReactRouter router={router}>
                    <Routes>
                        <Route path={'/logout'} element={<Logout />} />
                        <Route path={'/admin'} element={<CheckAdminAuth />} />
                        <Route path={'/admin/redirect'} element={<CheckAdminAuth />} />

                        {currentUser === null || typeof currentUser === 'undefined' ? (
                            <>
                                <Route path='*' element={<NeedLogin />} />
                                <Route path={'/view/:client/:campaign'} element={<ClientLogin />} />
                                <Route path={'/view/:client/:campaign/:key'} element={<ClientLogin />} />
                                <Route path={'/view/:client/:campaign/:key/:creative/:revision'} element={<ClientLogin />} />
                                <Route path={'/view/:client/:campaign/:key/:creative'} element={<ClientLogin />} />
                            </>
                        ) : (
                            <>
                                {currentUser.company === 'stormx' && (
                                    <>
                                        <Route path={'/admin/campaigns'} element={<AdminCampaigns />} />
                                        <Route path={'/admin/users'} element={<AdminUsers />} />
                                        <Route path={'/view/:client/:campaign/users'} element={<Users />} />
                                        <Route path={'/view/:key/timeline'} element={<Timeline />} />
                                    </>
                                )}
                                {currentUser && (
                                    <>
                                        <Route path={'/view/:client/:campaign/:key/:creative/:revision'} element={<Gallery />} />
                                        <Route path={'/view/:client/:campaign/:key'} element={<Gallery />} />
                                        <Route
                                            path={'*'}
                                            element={currentUser?.company === 'stormx' ? <AdminCampaigns /> : <Empty />}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Routes>
                </RecoilReactRouter>

                <Toaster
                    toastOptions={{
                        duration: 4000,
                    }}
                />
            </div>
            <Warning />
            <Tooltip id='my-tooltip' positionStrategy='fixed' style={{ zIndex: 9999 }} />
        </main>
    )
}
