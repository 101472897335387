import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { CampaignDetails, currentCreative, router } from '../AppState'

const CreativeSelectRevision = ({}) => {
    const params = useParams()
    const client = params.client as string
    const campaign = params.campaign as string
    const creativeKey = params.creative as string
    const revision = params.revision as string

    const { key: campaignKey } = useRecoilValue(CampaignDetails)
    const [selectedRevision, setSelectedRevision] = useState(revision)
    const creative = useRecoilValue(currentCreative)!

    const selectRevision = (version: string) => {
        setSelectedRevision(version)
        router.history.push(`/view/${client}/${campaign}/${campaignKey}/${creativeKey}/${version}`)
    }

    return (
        <div className='dropdown dropdown-top dropdown-end'>
            <label tabIndex={0} className='btn btn-sm m-1'>
                {selectedRevision === creative.revisions[creative.revisions.length - 1]
                    ? 'Versão atual'
                    : 'Versão anterior'}
            </label>
            <ul tabIndex={0} className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52'>
                <li onClick={() => selectRevision(creative.revisions[creative.revisions.length - 1])}>
                    <a>Versão atual</a>
                </li>
                <li onClick={() => selectRevision(creative.revisions[creative.revisions.length - 2])}>
                    <a>Versão anterior</a>
                </li>
            </ul>
        </div>
    )
}

export default CreativeSelectRevision
