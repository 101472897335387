import { useEffect } from 'react'
import axios from 'axios'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { router, currentUserAtom } from '../AppState'
import React from 'react'
import { User } from '../types'

const CheckAdminAuth = () => {
    const { location } = useRecoilValue(router.state)
    const setCurrentUser = useSetRecoilState(currentUserAtom)

    useEffect(() => {
        const validate = async () => {
            const query = location.search
            const data = (await axios.get('/api/token-management/validate' + query)).data as {
                ok: boolean
                user: undefined | User
            }
            if (typeof data.user === 'object') {
                setCurrentUser({ name: data.user.name, email: data.user.email, company: data.user.company, canApprove: data.user.canApprove })
            } else {
                setCurrentUser(null)
            }
            router.history.replace('/admin/campaigns')
        }
        validate()
    }, [])
    return <></>
}

export default CheckAdminAuth
