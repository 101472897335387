import React, { ComponentProps } from "react"
import { useRecoilState } from "recoil"
import { Campaign, DictionaryForOrder } from "../../../types"
import { campaignListOrderState } from "../../../AppState"
import { twMerge } from "tailwind-merge"
import classNames from "classnames"

type Props = ComponentProps<'th'> & {
    label: string
}

const TableHeaderCell = ({ label, className }: Props) => {
    const [{ by }, setOrder] = useRecoilState(campaignListOrderState)

    const handleChangeSearchBy = () => {
        setOrder({
            ascending: true,
            by: label as keyof Campaign,
        })
    }

    const orderDictionary: DictionaryForOrder = {
        client: 'Cliente',
        name: 'Campanha',
        updatedAt: 'Atualizado há',
        createdAt: 'Criado há',
    }

    return (
        <th
            onClick={handleChangeSearchBy}
            className={classNames(twMerge('cursor-pointer', className), {
                'max-sm:hidden': label !== 'name'
            })}
        >
            <span className={classNames('cursor-pointer', {
                'text-blue-500': by === label,
            })}>
                {orderDictionary[label]}
            </span>
        </th>
    )
}

export default TableHeaderCell

