import React from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

import { Creative } from '../../../types'
import CreativeView from '../creative-view/creative-view'
import CreativeType from '../creative-type/creative-type'
import { CampaignDetails, currentCreative, isGridLayout, router } from '../../../AppState'
import CreativeIndicator from '../creative-indicator/creative-indicator'
import CreativeTags from '../creative-tags/creative-tags'
import { useRecoilValue, useSetRecoilState } from 'recoil'

interface ICreativeCardProps {
    creativeKey: string
    creative: Creative
}

export default function CreativeCard({ creative, creativeKey }: ICreativeCardProps) {
    const { revisions, width, height, mimeType, updatedAt, name, status, tags } = creative
    const lastRevision = revisions[revisions.length - 1]
    const params = useParams()
    const client = params.client as string
    const campaign = params.campaign as string
    const campaignDetails = useRecoilValue(CampaignDetails)
    const setCreativeState = useSetRecoilState(currentCreative)
    
    const handleSelect = () => {
        setCreativeState(creative)
        router.history.push(
            `/view/${client}/${campaign}/${campaignDetails.key}/${creativeKey}/${lastRevision}${router.history.location.search}`
        )
    }

    const handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key == 'Enter') handleSelect()
    }

    return (
        <div
            className='cursor-pointer rounded-lg relative'
            onClick={handleSelect}
            onKeyDown={handlePressEnter}
            tabIndex={0}
        >
            <CreativeIndicator status={status}>
                <div className='group relative w-full rounded-lg overflow-hidden'>
                    <CreativeView name={name} revision={lastRevision} width={width} height={height} type={mimeType} />
                    <CreativeTags tags={tags} />
                </div>
            </CreativeIndicator>

            <section className='w-full p-2 flex justify-between items-center flex-nowrap mb-8 space-x-2'>
                <div className='flex-1'>
                    <CreativeType type={mimeType} />
                </div>
                <div className='w-4/5 flex-shrink-0'>
                    <h2 className='font-bold truncate'>{name}</h2>
                    <small> Atualizado {dayjs(updatedAt).locale('pt-br').fromNow()} </small>
                </div>
            </section>
        </div>
    )
}
