import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useRecoilValue } from 'recoil'
import { CampaignDetails, currentCreative, listOpenedState, router } from '@src/AppState'

import ListingController from './listing-controller'
import CreativePreview from '../creative-preview/creative-preview'
import Message from '@components/message/message'
import { PreviewProvider } from '@src/contexts/usePreview/use.preview'
import Comments from '@src/components/editor/comments'
import { EditorProvider } from '@src/contexts/useEditor/use.editor'
import { NavigationProvider } from '@src/utils/useNavigationHandlers'
import { Creative } from '@src/types'
import ToggleGalleryLayout from '../toggle-gallery-layout'

interface Props {
    creatives: Creative[];
}

const CreativeList = ({ creatives }: Props) => {
    const params = useParams()
    const creative = useRecoilValue(currentCreative)
    const campaign = useRecoilValue(CampaignDetails)

    const client = params.client as string
    const campaignParams = params.campaign as string

    const selectedCreative = useMemo(() => {
        const currentCreative = creative || creatives[0]
        if (!currentCreative) return null
        return currentCreative
    }, [creative, creatives])

    useEffect(() => {
        const creativeKey = params.creative
        const foundCreative = creatives.find(c => c.key === creativeKey)

        if (foundCreative || !creatives[0])
            return;

        const key = creatives[0].key
        const revision = creatives[0].revisions.at(-1) as string;

        const tags = router.history.location.search

        router.history.push(`/view/${client}/${campaignParams}/${campaign.key}/${key}/${revision}${tags}`)
    }, [creatives])

    return (
        <NavigationProvider>
            <div className='flex flex-row w-full h-full mb-8 gap-y-8 gap-x-2 relative'>
                {creatives.length === 0 || !selectedCreative ? (
                    <Message text='Nenhum criativo encontrado.' />
                ) : (
                    <>
                        <ListingController creatives={creatives} />

                        <div className='relative flex flex-row w-full h-full'>
                            <EditorProvider>
                                <PreviewProvider>
                                    <CreativePreview className='bg-base-300' />

                                    <div className='w-1/4 min-w-[320px] overflow-hidden flex flex-col rounded-xl space-y-4'>
                                        <div className='w-fit mr-2 ml-auto'>
                                            <ToggleGalleryLayout />
                                        </div>

                                        <Comments
                                            campaignKey={campaign.key}
                                            creative={selectedCreative}
                                        />
                                    </div>
                                </PreviewProvider>
                            </EditorProvider>
                        </div>
                    </>
                )}
            </div>
        </NavigationProvider>
    )
}

export default CreativeList
