import React, { ReactNode } from 'react'
import LoadingSpinner, { LoadingSpinnerProps } from './loading-spinner'

type Props = LoadingSpinnerProps & {
    isLoading?: boolean
    progress?: number
    children?: ReactNode
}

const Loading = ({ text, className, isLoading, progress, children }: Props) => {
    return (
        <>
            {isLoading && <LoadingSpinner progress={progress} text={text} className={className} />}
            {children}
        </>
    )
}

export default Loading
