import React from 'react'
import { FaTrash as TrashIcon } from "react-icons/fa";

interface DeleteButtonProps {
    isDisabled: boolean
    canDelete: boolean
    handleDeleteComment: () => void
}

export default function DeleteButton({ isDisabled, canDelete, handleDeleteComment }: DeleteButtonProps) {
    return (
        <>
            {canDelete ? (
                <button
                    onClick={handleDeleteComment}
                    disabled={isDisabled}
                    data-tooltip-id='my-tooltip'
                    data-tooltip-html='Excluir comentário'
                    data-tooltip-place='top'
                    className={`btn p-1 rounded-full btn-sm btn-ghost ${isDisabled ? 'loading loading-sm' : ''}`}
                >
                    {!isDisabled && <TrashIcon />}
                </button>
            ) : (
                <span />
            )}
        </>
    )
}


