import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
    text: string
}

const Message = ({ text, className }: Props) => {
    return (
        <>
            <div className={twMerge('flex flex-col justify-center items-center bg-base-300 h-16 w-full rounded-md', className)}>
                <h3 className='text-md text-center font-semibold'> {text} </h3>
            </div>
        </>
    )
}

export default Message
