import React, { useEffect, useRef, useState } from 'react'
import { useEditor } from '@src/contexts/useEditor/use.editor'
import useDraw from '@src/utils/useDraw'
import { Layer, Stage, Image } from "react-konva"
import { ShapeElement } from './shape'
import InfiniteViewer from 'react-infinite-viewer'
import { ZOOM_INCREMENT, MAX_ZOOM, MIN_ZOOM } from '@src/utils/zoom'
import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'
import LoadingSpinner from '../loading/loading-spinner'

type Props = {
  file: string
  drawHook: ReturnType<typeof useDraw>
}

export default function Editor({ file, drawHook }: Props) {
  const { shapes, konvaStageRef, isSubmitting } = useEditor()
  const { konvaEvents, selectShape, selectedShape, undoHistory } = drawHook

  const infiniteViewerRef = useRef<InfiniteViewer>({} as InfiniteViewer)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(1)

  useEffect(() => {
    const image = new window.Image()
    image.onload = () => {
      setLoadedImage(image)
    }
    image.src = file
  }, [file])

  useEffect(() => {
    return () => {
      setLoadedImage(undefined)
    }
  }, [])

  useEffect(() => {
    const handleUndo = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'z' && !e.shiftKey) {
        undoHistory()
      }
    }

    window.addEventListener('keydown', handleUndo)
    return () => {
      window.removeEventListener('keydown', handleUndo)
    }
  }, [undoHistory])

  const zoomIn = () => {
    setZoom((value) => value + ZOOM_INCREMENT > MAX_ZOOM ? MAX_ZOOM : value + ZOOM_INCREMENT)
  }

  const zoomOut = () => {
    setZoom((value) => value - ZOOM_INCREMENT < MIN_ZOOM ? MIN_ZOOM : value - ZOOM_INCREMENT)
  }

  useEffect(() => {
    if (infiniteViewerRef.current && containerRef.current && loadedImage) {
      const containerWidth = containerRef.current.offsetWidth
      const containerHeight = containerRef.current.offsetHeight

      const htmlWidth = loadedImage?.width || 1
      const htmlHeight = loadedImage?.height || 1

      infiniteViewerRef.current.scrollTo(
        -(containerWidth / 2 - htmlWidth / 2),
        -(containerHeight / 2 - htmlHeight / 2)
      )
    }
  }, [infiniteViewerRef.current, loadedImage])

  return (
    <div className='w-full h-full relative' ref={containerRef}>
      <InfiniteViewer ref={infiniteViewerRef} className='w-full h-full' zoom={zoom}>
        <div className='w-fit' onClick={(e) => e.stopPropagation()}>
          <Stage className='w-full relative my-2' width={loadedImage?.width} height={loadedImage?.height} {...konvaEvents} ref={konvaStageRef}>
            <Layer>
              <Image image={loadedImage} width={loadedImage?.width} height={loadedImage?.height} />
            </Layer>
            <Layer>
              {shapes.map(({ shape, type }, i) => (
                type === 'pen' || type === 'eraser' ?
                  <ShapeElement
                    key={i}
                    type={type}
                    shapeProps={shape}
                    isSelected={shape.id === selectedShape?.shapeId}
                    onSelect={() =>
                      selectShape({ shapeId: shape.id, type })
                    }
                    drawHook={drawHook}
                  /> : null
              ))}
              {shapes.map(({ shape, type }, i) => (
                type !== 'pen' && type !== 'eraser' ?
                  <ShapeElement
                    key={i}
                    type={type}
                    shapeProps={shape}
                    isSelected={shape.id === selectedShape?.shapeId}
                    onSelect={() =>
                      selectShape({ shapeId: shape.id, type })
                    }
                    drawHook={drawHook}
                  /> : null
              ))}
            </Layer>
          </Stage>
        </div>
      </InfiniteViewer>

      {isSubmitting && (
        <div className='absolute w-full h-full left-0 top-0 flex items-center justify-center bg-[#1d2839]/40'>
          <LoadingSpinner text='Realizando upload da anotação...' spinnerSize={16} />
        </div>
      )}

      <div onClick={(e) => e.stopPropagation()} className={twMerge(
        'absolute right-4 bottom-4 flex flex-row space-x-4 items-center',
        'rounded-md bg-[#1d2839]'
      )}>
        <button onClick={zoomOut} className='p-4'>
          <MagnifyingGlassMinusIcon className='cursor-pointer' width={25} height={25} />
        </button>
        <p className='text-white w-[42px] text-center'>{zoom * 100}%</p>
        <button onClick={zoomIn} className='p-4'>
          <MagnifyingGlassPlusIcon className='cursor-pointer' width={25} height={25} />
        </button>
      </div>
    </div>
  )
}
