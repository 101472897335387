import React from 'react'
import { BsList, BsGrid } from 'react-icons/bs'

import { useRecoilState, useRecoilValue } from 'recoil'
import { CampaignDetails, isGridLayout, router } from '../../../AppState'
import { twMerge } from 'tailwind-merge'

type Props = {
    isVisible?: boolean
}

const ToggleGalleryLayout = ({ isVisible = true }: Props) => {
    if (!isVisible) {
        return null
    }

    const campaignDetails = useRecoilValue(CampaignDetails)
    const [isGrid, setIsGrid] = useRecoilState(isGridLayout)

    const handleChangeViewToGrid = () => {
        setIsGrid(!isGrid)
        const url = `${campaignDetails.url}/${campaignDetails.key}/${router.history.location.search}`
        router.history.push(url)
    }

    return (
        <div className='flex h-10 items-center gap-x-2'>
            <label className='label-text text-sm whitespace-nowrap'>Modo de visualização: </label>
            <span data-tooltip-id='my-tooltip' data-tooltip-place='bottom' data-tooltip-html='Visualização em grade.'>
                <button
                    className={twMerge('rounded-md w-10 h-10 flex items-center justify-center',
                        'bg-[#1d2839] disabled:bg-neutral-focus disabled:opacity-40',
                    )}
                    disabled={isGrid}
                    onClick={handleChangeViewToGrid}
                >
                    <BsGrid className='swap-off fill-current' size={20} />
                </button>
            </span>

            <span data-tooltip-id='my-tooltip' data-tooltip-place='bottom' data-tooltip-html='Visualização em lista.'>
                <button
                    className={twMerge('rounded-md w-10 h-10 flex items-center justify-center ',
                        'bg-[#1d2839] disabled:bg-neutral-focus disabled:opacity-40',
                    )}
                    disabled={!isGrid}
                    onClick={handleChangeViewToGrid}
                >
                    <BsList className='swap-on fill-current' size={20} />
                </button>
            </span>
        </div>
    )
}

export default ToggleGalleryLayout
