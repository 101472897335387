import React, { useEffect, useRef, useState } from 'react'
import PreviewVideoControls from './preview-video.control'
import CarrouselButton from '../components/carrousel-button'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { PlayerStatus, fullscreenState } from '../AppState'

import classNames from 'classnames'
import useToggleLayout from '../utils/useToggleLayout'
import { usePreview } from '@src/contexts/usePreview/use.preview'
import Loading from '@src/components/loading'
import { twMerge } from 'tailwind-merge'
import { usePlayerUtils } from './usePlayerUtils'

type Props = {
    file: string | null
}

const PreviewVideo = ({ file }: Props) => {
    const created = useRef(false)
    const fullscreen = useRecoilValue(fullscreenState)
    const setPlayerStatus = useSetRecoilState(PlayerStatus)
    const [videoRef, setVideoRef] = useState<any>(undefined)
    const { isHovering, setIsHovering } = usePlayerUtils()
    const videoRefHelp = useRef<any>()
    const { useGrid } = useToggleLayout()
    const { createAnnotationFile, loading, downloadProgress } = usePreview()

    const loadVideo = () => {
        if (videoRefHelp.current && !created.current) {
            videoRefHelp.current.onloadedmetadata = (_event: any) => {
                setPlayerStatus((value: any) => {
                    return {
                        ...value,
                        totalTime: ((videoRefHelp.current as HTMLVideoElement)?.duration || 0) * 1000,
                    }
                })

                setVideoRef(videoRefHelp.current)
                created.current = true
            }

            videoRefHelp.current.load()
        }
    }

    useEffect(() => {
        setPlayerStatus({
            isPlaying: false,
            time: 0,
            totalTime: 0,
        })

        loadVideo()
    }, [file])

    useEffect(() => {
        if (videoRefHelp.current && videoRefHelp.current.paused)
            createAnnotationFile('VIDEO', videoRefHelp.current)
    }, [videoRefHelp.current])

    useEffect(() => {
        return () => {
            createAnnotationFile('NULL')
            setPlayerStatus({
                isPlaying: false,
                time: 0,
                totalTime: 0,
            })
        }
    }, [])

    if (!file) return <></>;

    return (
        <Loading
            isLoading={loading}
            progress={downloadProgress}
            className={classNames('bg-base-300', {
                'absolute top-0 left-0 w-screen h-screen z-[999]': useGrid,
                'absolute top-0 left-0 rounded-md z-[99]': !useGrid,
            })}
        >
            <div
                className={'w-full h-full relative'}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <CarrouselButton>
                    <div
                        className={classNames('w-full h-full flex flex-row items-center justify-center relative', {
                        })}
                    >
                        <video
                            src={file}
                            muted
                            preload='auto'
                            className='w-full h-full inset-0 absolute object-contain'
                            ref={videoRefHelp as any}
                            onTimeUpdate={() => {
                                if (videoRefHelp.current && videoRefHelp.current.paused)
                                    createAnnotationFile('VIDEO', videoRefHelp.current)
                            }}
                        />
                    </div>
                </CarrouselButton>

                <div className={twMerge(
                    'absolute bottom-0 left-0 w-full bg-base-300 bg-opacity-80',
                    isHovering ? 'block' : 'hidden'
                )}>
                    <PreviewVideoControls videoRef={videoRef} />
                </div>
            </div>
        </Loading>
    )
}

export default PreviewVideo


