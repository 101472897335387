import React, { useEffect } from 'react'
import { useRef, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { PlayerStatus, currentCreative, zoomState } from '@src/AppState'
import CreativeSelectRevision from '../creative/select-revision'
import { formatTime } from '@utils/time'
import PreviewTimeBarControl from './time-bar-control'
import { MAX_ZOOM, MIN_ZOOM, ZOOM_INCREMENT } from '../utils/zoom'

import { CiPlay1 as PlayIcon, CiPause1 as PauseIcon } from 'react-icons/ci'
import { MdCenterFocusStrong } from 'react-icons/md'
import CreativeZoom from '@src/creative/zoom'

type Props = {
    handleCenterPreview: () => void
}

const PreviewHtmlControls = ({ handleCenterPreview }: Props) => {
    const creative = useRecoilValue(currentCreative)
    const [zoom, setZoom] = useRecoilState(zoomState)
    const [{ time, isPlaying, totalTime }, setStatus] = useRecoilState(PlayerStatus)
    const shouldStop = useRef(false)
    const shouldRestart = useRef(false)
    const start = useRef<number | undefined>(0)
    const [actualTime, setActualTime] = useState(0)
    const actualTimeTmp = useRef(0)

    const onPlay = (timestamp: number) => {
        if (!start.current) {
            start.current = timestamp
        }

        let progress = timestamp - start.current

        let newTime = actualTimeTmp.current >= totalTime ? progress : actualTimeTmp.current + progress
        if (newTime >= totalTime) {
            newTime = totalTime
        }

        if (!shouldStop.current && !shouldRestart.current) {
            if (progress === 0)
                setStatus((state: any) => {
                    return {
                        ...state,
                        isPlaying: true,
                    }
                })

            if (newTime >= totalTime) {
                shouldStop.current = true
            }

            setActualTime(newTime)

            window.requestAnimationFrame(onPlay)
        } else if (shouldRestart.current) {
            onRestart()

            actualTimeTmp.current = 0
            setActualTime(0)

            window.requestAnimationFrame(onPlay)
        } else {
            onPause(newTime)
        }
    }

    const onPause = (time: number) => {
        start.current = undefined
        shouldStop.current = false
        shouldRestart.current = false

        setStatus((state: any) => {
            return {
                ...state,
                isPlaying: false,
                time,
            }
        })
    }

    const onRestart = () => {
        start.current = undefined
        shouldStop.current = false
        shouldRestart.current = false

        setStatus((state: any) => {
            return {
                ...state,
                isPlaying: false,
                time: 0,
            }
        })
    }

    const onStop = () => {
        start.current = undefined
        shouldStop.current = true
        shouldRestart.current = false

        setStatus((state: any) => {
            return {
                ...state,
                isPlaying: false,
                time: 0,
            }
        })
    }

    const handlePause = () => {
        shouldStop.current = true
    }

    const playPauseHandle = () => {
        if (!isPlaying) window.requestAnimationFrame(onPlay)
        else handlePause()
    }

    const restartHandle = () => {
        shouldRestart.current = true

        if (!start.current) onRestart()
    }

    useEffect(() => {
        actualTimeTmp.current = time
        setActualTime(time)
    }, [time])

    useEffect(() => {
        if (isPlaying)
            window.requestAnimationFrame(onPlay)

        return () => {
            onStop()
        }
    }, [])

    const generateRootCurrentTimeVar = useMemo(() => {
        return `
        :root {
          --current-time: ${actualTime}ms !important;
        }
      `
    }, [actualTime])

    return (
        <>
            <PreviewTimeBarControl actualTime={actualTime} />
            <style>{generateRootCurrentTimeVar}</style>
            <div className='w-full grow flex flex-row items-center space-between pt-3'>
                <div className='w-full grow flex flex-row items-center justify-start gap-x-5'>
                    <button className='btn p-1 btn-sm' onClick={playPauseHandle}>
                        {isPlaying ? <PauseIcon /> : <PlayIcon />}
                    </button>
                    <button className='btn p-1 btn-sm' onClick={restartHandle}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-5 h-5'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                            />
                        </svg>
                    </button>
                    <p className='tabular-nums text-sm whitespace-nowrap'>
                        {formatTime(actualTime)} / {formatTime(totalTime)}
                    </p>
                </div>

                <CreativeZoom />

                <div className={`w-full grow flex flex-row items-center justify-end gap-x-5 ml-5`}>
                    {creative && creative.revisions.length <= 1 ? <></> : <CreativeSelectRevision />}
                    <button className='btn btn-sm' onClick={handleCenterPreview} data-tooltip-id='my-tooltip' data-tooltip-content='Centralizar formato'>
                        <MdCenterFocusStrong />
                    </button>
                </div>
            </div>
        </>
    )
}

export default PreviewHtmlControls
