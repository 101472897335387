import dayjs from 'dayjs'
import React from 'react'

import { router } from '../AppState'

type Props = {
    reaction: {
        user: string
        comment: string
        createdAt: string
        create: boolean
        delete: boolean
        icon: number
        url: string
    }
}

const iconTranslate = {
    ':heart:': '❤️',
    ':like:': '👍',
    ':unlike:': '👎',
    ':smile:': '😄',
    ':check:': '✅',
    ':star:': '🌟',
    ':sad:': '😕',
    ':question:': '❓',
}

const Reaction = ({ reaction }: Props) => {
    const { user, comment, createdAt, create, delete: deleted, icon, url } = reaction

    const onClick = () => {
        router.history.push('/view' + url)
    }

    return (
        <div className='pb-4 px-2 mb-4 relative'>
            <div className='flex items-center mb-1'>
                <span className='absolute left-5 top-6 bg-slate-500/75 h-full w-[1px]'></span>
                <div
                    className='text-xs rounded-full w-6 h-6 mr-2 flex justify-center items-center relative border-slate-500/75 bg-slate-300 text-cyan-900 cursor-pointer'
                    onClick={onClick}
                >
                    {iconTranslate[icon]}
                    {deleted && <span className='absolute left-2/4 top-0 bg-red-500 h-full w-[2px] rotate-45'></span>}
                </div>
                <p className='text-xs text-slate-300'>
                    <strong>{user}</strong> {create ? 'reagiu' : 'removeu uma reação'} a um comentário -{' '}
                    {dayjs(createdAt).locale('pt-br').fromNow()}
                </p>
            </div>
            <h3 className='text-xs text-slate-400 ml-8'>
                <strong>Comentário:</strong> {comment}
            </h3>
        </div>
    )
}

export default Reaction
