import React, { ReactNode } from 'react'

import {
    PiRectangleDuotone,
    PiCursorFill,
    PiCircleDuotone,
    PiPenFill,
    PiTextTBold,
    PiTrashSimpleFill,
    PiEraser,
} from 'react-icons/pi'
import classNames from 'classnames'
import ReactDOMServer from 'react-dom/server'

import { DrawTool } from '@utils/useDraw/draw.types'
import { useEditor } from '@src/contexts/useEditor/use.editor'
import useDraw from '@src/utils/useDraw'

import './styles.module.css'
import { twMerge } from 'tailwind-merge'

const iconComponents: { [key in DrawTool]: ReactNode } = {
    rectangle: <PiRectangleDuotone />,
    pen: <PiPenFill />,
    eraser: <PiEraser />,
    circle: <PiCircleDuotone />,
    selection: <PiCursorFill />,
    text: <PiTextTBold />,
}

const Icon = (tool: DrawTool) => {
    const ToolIcon = iconComponents[tool] || null
    return ToolIcon
}

const toolsTranslations = {
    rectangle: 'Retângulo',
    pen: 'Caneta',
    eraser: 'Borracha',
    circle: 'Círculo',
    selection: 'Seleção',
    text: 'Texto',
}

interface ToolsProps {
    drawHook: ReturnType<typeof useDraw>
}

export default function Tools({ drawHook }: ToolsProps) {
    const { deleteSelectedShape, color, setShapeColor } = drawHook
    const {
        tool,
        setTool,
        selectedShape,
        setSelectedShape,
    } = useEditor()
    const toolList = [...new Set<DrawTool>(['selection', 'text', 'circle', 'rectangle', 'pen', 'eraser'])]

    const changeSelectedTool = (option: DrawTool) => {
        setTool(option)
        setSelectedShape(undefined)
    }

    return (
        <div className='z-[99] h-full' onClick={(e) => e.stopPropagation()}>
            <div className='join join-horizontal h-full'>
                {toolList.map((toolItem) => (
                    <button
                        title={toolsTranslations[toolItem]}
                        key={toolItem}
                        onClick={() => changeSelectedTool(toolItem)}
                        className={classNames('btn-sm h-full join-item btn-neutral', {
                            'select-none btn-active bg-secondary-focus': toolItem === tool,
                        })}
                    >
                        {Icon(toolItem)}
                    </button>
                ))}
                <div className='btn-sm h-full join-item btn-neutral rounded-md cursor-pointer flex items-center justify-center'>
                    <div className='w-[20px] h-[20px] overflow-hidden'>
                        <input
                            title='Alterar cor'
                            value={color}
                            onChange={(e) => setShapeColor(e.target.value)}
                            type='color'
                            className=''
                            data-tooltip-id='my-tooltip'
                            data-tooltip-content='Cor das formas.'
                        />
                    </div>
                </div>

                <button
                    title='Excluir forma'
                    disabled={selectedShape === undefined}
                    onClick={deleteSelectedShape}
                    className={twMerge(
                        'btn-sm join-item btn-neutral rounded-md h-full',
                        selectedShape === undefined && 'btn-disabled'
                    )}
                    data-tooltip-id='my-tooltip'
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <div>
                            <span>Excluir forma selecionada </span> <kbd className='kbd kbd-sm'> del </kbd>
                        </div>
                    )}
                >
                    <PiTrashSimpleFill />
                </button>
            </div>
        </div>
    )
}
