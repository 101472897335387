import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { currentUserAtom, router } from "@src/AppState";
import axios from "axios";
import { useSetRecoilState } from "recoil";

export const Logout = () => {
  const setCurrentUser = useSetRecoilState(currentUserAtom)
  const { logout: auth0Logout } = useAuth0()

  const handleLogoutRequested = async () => {
    const { data } = await axios.post<{ ok: boolean }>('/api/logoff')

    if (data.ok) {
      auth0Logout()
      setCurrentUser(null)
      return router.history.push('/')
    }
  }

  useEffect(() => {
    handleLogoutRequested()
  }, [])

  return <></>;
}
