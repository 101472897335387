import React, { useMemo } from 'react'
import CampaignOptions from '../../campaign-options/campaign-options'

import { useRecoilValue } from 'recoil'
import { filteredCampaignListSelector, router } from '../../../AppState'
import dayjs from 'dayjs'

const TableBody = () => {
    const campaigns = useRecoilValue(filteredCampaignListSelector)

    const isEmpty = (str: string) => {
        return str?.trim() === ''
    }

    const displayLastDate = (date: string, message: string): string => {
        try {
            if (isEmpty(date)) {
                return message
            } else {
                return dayjs(date).locale('pt-br').fromNow()
            }
        } catch(e) {
            console.error(e)
        }
    }

    return (
        <tbody>
            {campaigns.map((c, i) => (
                <tr key={`${i} - ${c.key}`} className='even:bg-base-200'>
                    <td className='max-sm:hidden'>{c.client}</td>
                    <td>
                        <button
                            className='btn-quiet transition hover:text-cyan-500 max-w-[232px] max-sm:max-w-[124px] truncate'
                            onClick={() => router.history.push(`${c.url}/${c.key}`)}
                            data-tooltip-id='my-tooltip'
                            data-tooltip-html='Visualizar campanha'
                            data-tooltip-place='top'
                        >
                            {c.name}
                        </button>
                    </td>
                    <td className='max-sm:hidden'>{displayLastDate(c.updatedAt, 'Nenhuma atualização')} </td>
                    <td className='max-sm:hidden'>{displayLastDate(c.createdAt, 'Nenhum comentário')}</td>
                    <td>
                        <CampaignOptions campaign={c} />
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default TableBody
