import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { useRecoilValue } from 'recoil'
import toast from 'react-hot-toast'

import { currentAnimal } from '../../AppState'
import FormInput from './form-input'
import FormSelect from './form-select'
import IconTrash from '../../components/icons/trash'
import LayoutContainer from '../../components/layout-container'
import { Campaign } from '@src/types'

type Props = {
    selectedClient?: string
    selectedCampaign?: string
    onSubmit?: () => void
}

const AdminUsers = ({ selectedClient = '', selectedCampaign = '', onSubmit }: Props) => {
    const userDefault = {
        email: '',
        name: '',
    }
    const animal = useRecoilValue(currentAnimal)

    const [sending, setSending] = useState(false)

    const [users, setUsers] = useState([userDefault])

    const [campaigns, setCampaigns] = useState<any[] | undefined>()
    const [clients, setClients] = useState<any[] | undefined>()

    const [client, setClient] = useState<string>(selectedClient)
    const [campaign, setCampaign] = useState<string>(selectedCampaign)
    const [role, setRole] = useState<string>('Aprovador')

    const roles = [
        {
            value: 'approver',
            label: 'Aprovador',
        },
        {
            value: 'reviewer',
            label: 'Revisor',
        },
    ]

    const onChange = (index: number, name: string, value: string) => {
        setUsers((users) => {
            const newValue = [...users]

            newValue[index] = {
                ...users[index],
                [name]: value,
            }

            return newValue
        })
    }

    const onRemove = (index: number) => {
        if (users.length === 1) return

        setUsers((value) => {
            const newValue = [...value]
            newValue.splice(index, 1)

            return newValue
        })
    }

    const addEmail = () => {
        setUsers((value) => {
            const newValue = [...value, userDefault]

            return newValue
        })
    }

    const getCampaigns = async () => {
        let req = await axios.get<
            { ok: boolean, items: Campaign[] }
        >('/api/admin/campaigns/list')

        if (req.data.ok) {
            const clients: any[] = []

            req.data.items.forEach((item) => {
                const hasItem = clients.find((c) => c.client === item.client)
                if (!hasItem) {
                    clients.push(item)
                }
            })

            setCampaigns(req.data.items)
            setClients(clients)
        }
    }

    const filteredCampaigns = useMemo(() => {
        if (!campaigns || !client) return []

        const sortedCampaigns = campaigns.filter((c) =>
            c?.client === client
        ).map((c) => {
            return { value: c.key, label: c.name }
        }).sort((a, b) => {
            return a.label.localeCompare(b.label)
        })

        return sortedCampaigns;
    }, [campaigns, client])

    const safeClients = useMemo(() => {
        if (!clients) return []

        const sortedClients = clients.map((c) => {
            return { value: c.client, label: c.client }
        }).sort((a, b) => {
            return a.label.localeCompare(b.label)
        })

        return sortedClients;
    }, [clients])

    const handleSubmit = async () => {
        setSending(true)

        const data = {
            users,
            client,
            campaign,
            role,
        }

        try {
            let req = await axios.post<
                { ok: boolean, usersCreated: string[], usersExists: string[] }
            >('/api/admin/users/create', { ...data })

            const response = req.data

            if (response.ok) {
                clean()

                response.usersCreated.forEach((u) => {
                    toast.success(`Usuário ${u} convidado.`)
                })

                response.usersExists.forEach((u) => {
                    toast.error(`Usuário ${u} já existe.`)
                })

                onSubmit?.()
            } else {
                toast.error('Alguma coisa deu errado =/')
            }
        } catch (e) {
            toast.error('Alguma coisa deu errado =/')
        }

        setSending(false)
    }

    const clean = () => {
        setUsers([userDefault])
        setClient('')
        setCampaign('')
        setRole('approver')
    }

    useEffect(() => {
        getCampaigns()
    }, [])

    return (
        <LayoutContainer>
            <div className='flex flex-col gap-y-4 h-full'>
                <div className='w-full'>
                    <h1 className='text-lg sm:text-2xl font-bold grow'>Adicionar usuários</h1>
                </div>

                <div className='w-full overflow-x-hidden bg-base-200 p-4 rounded-md h-[calc(100%-80px)]'>
                    {users.map((user, index) => {
                        return (
                            <div className='flex flex-row items-top w-full gap-4 pb-2 relative max-sm:flex-wrap max-sm:pb-4' key={`form-${index}`}>
                                <FormInput
                                    index={index}
                                    name='email'
                                    label='E-mail:'
                                    value={user.email}
                                    onChange={onChange}
                                    description={`e.g.: ${animal}`}
                                    disabled={sending}
                                />
                                <FormInput
                                    index={index}
                                    name='name'
                                    label='Name:'
                                    value={user.name}
                                    onChange={onChange}
                                    description={`e.g.: ${animal}`}
                                    disabled={sending}
                                />
                                {index > 0 && (
                                    <button
                                        className='btn btn-neutral text-xs p-2 h-8 min-h-0 absolute -top-1 right-0'
                                        onClick={() => onRemove(index)}
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-html='Excluir usuário'
                                        data-tooltip-place='top'
                                    >
                                        <IconTrash alt='Excluir usuário' />
                                    </button>
                                )}
                            </div>
                        )
                    })}

                    <button className='btn btn-neutral text-xs p-2 h-8 min-h-0 mb-2 max-sm:w-full' onClick={addEmail} disabled={sending}>
                        Adicionar novo usuário
                    </button>

                    <FormSelect
                        label='Clientes'
                        value={client}
                        onChange={setClient}
                        items={safeClients}
                        disabled={sending || selectedClient !== ''}
                    />

                    <FormSelect
                        label='Campanha'
                        value={campaign}
                        onChange={setCampaign}
                        items={filteredCampaigns}
                        disabled={sending || selectedCampaign !== ''}
                    />

                    <FormSelect
                        label='Tipo de usuário'
                        value={role}
                        onChange={setRole}
                        items={roles}
                        disabled={sending}
                    />

                </div>

                <div className='form-control'>
                    <button className='btn btn-primary' onClick={handleSubmit} disabled={sending}>
                        {sending ? 'Enviando...' : 'Convidar'}
                    </button>
                </div>
            </div>
        </LayoutContainer>
    )
}

export default AdminUsers
