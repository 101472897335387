import React, { ComponentProps, forwardRef } from 'react'
import ButtonClose from '../button-close.dialog'

type Props = ComponentProps<'dialog'> & {
    title: string
    describe: string
    onConfirm: () => void | (() => Promise<any>)
}

const ConfirmationDialog = (
    { title, describe, onConfirm }: Props,
    ref: React.LegacyRef<HTMLDialogElement> | undefined
) => {
    return (
        <dialog className='modal' ref={ref}>
            <div className='modal-box w-[50rem] max-w-none h-auto overflow-hidden rounded-md'>
                <form method='dialog'>
                    <ButtonClose />
                    <h3 className='font-bold text-lg'> {title} </h3>
                    <small className='text-info text-sm'>{describe}</small>

                    <div className='bg-base-100 sm:rounded-box sm:py-4 sm:px-0 grow'>
                        <div className='modal-action'>
                            <button className='btn btn-neutral'>
                                Cancelar
                            </button>
                            <button className='btn btn-primary' onClick={onConfirm}>
                                Confirmar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </dialog>
    )
}
export default forwardRef(ConfirmationDialog)
