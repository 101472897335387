import React, { useState, ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useMemo } from 'react'

import { IoSend as SendIcon } from 'react-icons/io5'
import { RiAttachment2 as AttachmentIcon } from 'react-icons/ri'

import toast from 'react-hot-toast'
import classNames from 'classnames'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { CommentsList, currentFileIsHTML, PlayerStatus } from '@src/AppState'
import { useEditor } from '@src/contexts/useEditor/use.editor'
import { formatTime } from '@utils/time'
import type { Creative } from '@src/types'
import { ApiService } from '@src/services/api/api.service'

type Props = {
    onSuccess?: () => void
    onEditComment?: (value: boolean) => void
    creative: Creative
    campaignKey: string
    disableTime?: boolean
    inputStyle?: 'primary' | 'secondary'
}

const Submit = ({
    onSuccess,
    onEditComment,
    creative,
    campaignKey,
    disableTime,
    inputStyle = 'primary'
}: Props) => {
    const apiService = new ApiService()
    const [comment, setComment] = useState<string>('')
    const [sending, setSending] = useState(false)

    const { time, isPlaying } = useRecoilValue(PlayerStatus)
    const isHTML = useRecoilValue(currentFileIsHTML)
    const setComments = useSetRecoilState(CommentsList)

    const blockTimeOnExtensions = ['jpg', 'jpeg', 'png']
    const hiddenTime = blockTimeOnExtensions.some((e) => creative.mimeType.includes(e))
    const formDisabled = sending || comment.length < 1 || isPlaying

    const { commentNoteIsSelected, setShapes, konvaStageRef, setSelectedShape, setIsSubmitting } = useEditor()

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setSending(true)

        const revision = creative.revisions[creative.revisions.length - 1]
        const img = await konvaStageRef.current.toBlob() as Blob

        try {
            setIsSubmitting(true)

            const response = await apiService.postComment({
                campaignKey,
                creativeKey: creative.key,
                revisionKey: revision,
                time: disableTime ? 0 : time,
                comment,
                img
            })

            const result = response.data
            if (result.ok === true) {
                toast.success('Comentário adicionado com sucesso!')
                setComments((comments) => [...comments, result.item])
                setComment('')
                onSuccess && onSuccess()
            }
        } catch (err) {
            toast.error('Erro ao adicionar comentário 😕')
            console.error(err)
        } finally {
            setSending(false)
            setShapes([])
            setIsSubmitting(false)
        }
    }

    const updateComment = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setComment(value)
        onEditComment && onEditComment(value.length > 0)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !formDisabled) {
            e.preventDefault()
            handleSubmit(e)
        }
    }

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
    }

    useEffect(() => {
        return () => {
            setComment('')
        }
    }, [])

    const tooltipMsg = useMemo(() => isPlaying ? 'Pause o vídeo para comentar' : '', [isPlaying])

    const timeOnComment = useMemo(() => {
        switch (creative.mimeType) {
            case 'image/gif':
                return `STEP:${time === 0 ? 1 : time}`
            case 'application/zip':
                return isHTML ? formatTime(time) : `STEP:${time === 0 ? 1 : time}`
            case 'video/mp4':
                return formatTime(time)
            default:
                null
        }
    }, [isHTML, creative.mimeType])

    return (
        <form
            className='w-full btn-group'
            encType='multipart/form-data'
            onSubmit={handleSubmit}
            data-tooltip-id='my-tooltip'
            data-tooltip-content={tooltipMsg}
        >
            <div
                className={classNames('flex flex-row bg-transparent w-full', {
                    'bg-[#070B17] p-2': inputStyle === 'secondary',
                })}
            >
                {!hiddenTime && (
                    <button
                        disabled
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content='Tempo atual da peça'
                        className={classNames(
                            'bg-slate-300 text-gray-800 cursor-default py-2 px-4 w-fit font-mono rounded-l-md whitespace-nowrap',
                            {
                                'btn-sm': inputStyle === 'secondary',
                            }
                        )}
                        onClick={handleClick}
                    >
                        {timeOnComment}
                    </button>
                )}
                <div
                    className={classNames('input-group bg-base-300 w-full', {
                        'bg-base-200': inputStyle === 'secondary',
                    })}
                >
                    {!commentNoteIsSelected && (
                        <button
                            data-tooltip-id='my-tooltip'
                            data-tooltip-content='Anexo adicionado'
                            className={classNames('btn-neutral cursor-default px-2', {
                                'btn-sm': inputStyle === 'secondary',
                            })}
                            onClick={handleClick}
                        >
                            <AttachmentIcon />
                        </button>
                    )}
                    <input
                        className={classNames(
                            'input w-full min-w-[320px] max-w-full bg-slate-200 text-black focus:outline-none placeholder:text-gray-500',
                            {
                                'input-sm': inputStyle === 'secondary',
                            }
                        )}
                        placeholder='Escreva um comentário...'
                        onChange={updateComment}
                        onClick={() => setSelectedShape(undefined)}
                        onKeyDown={handleKeyDown}
                        value={comment}
                        disabled={isPlaying}
                        style={{
                            resize: 'none',
                            borderTopLeftRadius: !hiddenTime ? 0 : undefined,
                            borderBottomLeftRadius: !hiddenTime ? 0 : undefined
                        }}
                    />
                    <button
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content='Enviar'
                        className={classNames('btn btn-ghost px-4 disabled:btn-disabled h-full', {
                            'btn-sm': inputStyle === 'secondary',
                            'bg-slate-300': !formDisabled,
                        })}
                        disabled={formDisabled}
                        type='submit'
                    >
                        <SendIcon color={formDisabled ? '' : 'black'} />
                    </button>
                </div>
            </div>
        </form>
    )
}

export default Submit
