import type { ReqComments, ReqCreativeFile } from "@services/api/api.dto"

export const endpoints = {
  campaign: {

  },
  creative: {
    getFile: ({ name, revisionKey }: ReqCreativeFile) => `/api/common/file/${name}/${revisionKey}`,
    list: () => '/campaigns/details/${key}',
  },
  comments: {
    getFile: (filename: string) => `/api/common/comments/image/${filename}`,
    get: ({ campaignKey, creativeKey, revisionKey }: ReqComments) => `/api/common/comments/${campaignKey}/creative/${creativeKey}/${revisionKey}`,
    post: ({ campaignKey, creativeKey, revisionKey }: ReqComments) => `/api/common/comments/${campaignKey}/creative/${creativeKey}/${revisionKey}`
  },
  status: {
    update: () => '/status/{clientName}/{campaignName}/{campaignKey}/{creativeKey}/{revisionKey}'
  },
  auth: {
    generateAuth: '/generate-authorization-url',
    validate: '/validate'
  },
  user: {
    getSession: '/api/me',
    logout: '/api/logoff',
    login: '/api/login',
  }
}

