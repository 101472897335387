import React from 'react'
import { useRecoilValue } from 'recoil'
import { currentCreative, CampaignDetails } from '../AppState'
import CommentSubmit from '../comments/comment-submit'

type Props = {
    onClose: () => void
    onSubmit: () => void
}

const StatusDialog = ({ onSubmit, onClose }: Props) => {
    const creative = useRecoilValue(currentCreative)
    const campaign = useRecoilValue(CampaignDetails)

    if (!creative || !campaign) {
        return null
    }

    const { name } = creative

    const hiddenBodyOverflow = `
        body { overflow: hidden }
    `

    return (
        <div className='modal modal-open bg-slate-800/90'>
            <style>{hiddenBodyOverflow}</style>
            <div className='modal-box w-[50rem] max-w-none h-auto overflow-hidden rounded-sm'>
                <button className='btn btn-sm btn-circle absolute right-4 top-4' onClick={onClose}>
                    ✕
                </button>
                <h3 className='font-bold text-lg'>
                    Tem certeza que deseja rejeitar o criativo: <small className='text-info text-sm'>{name}?</small>
                </h3>
                <div className='bg-base-100 sm:rounded-box sm:py-4 sm:px-0 grow'>
                    <p className='font-normal text-md mb-4'>
                        Você deve explicar o motivo para poder rejeitar um criativo.
                    </p>

                    <div className='w-full flex'>
                        <CommentSubmit
                            onSubmit={onSubmit}
                            campaignKey={campaign.key}
                            creative={creative}
                            disableTime={true}
                            className='h-14'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusDialog
