// import ReactDOM from "react-dom";
import { App } from './App'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import dayjs from 'dayjs'
import { Auth0Provider } from '@auth0/auth0-react'

require('dayjs/locale/pt-br')
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const container = document.getElementById('app')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
    <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENTID}
        audience={process.env.AUTH0_AUDIENCE}
        authorizationParams={{
            redirect_uri: window.location.origin + '/admin/campaigns',
        }}
    >
        <RecoilRoot children={<App />} />
    </Auth0Provider>
)
