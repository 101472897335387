import React, { useEffect, useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

interface PaginationFrameProps {
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleGoToPage: (page: number) => void;
  currentPage: number;
  perPage: number;
  totalItems: number;
}

export const PaginationFrame = ({
  handleNextPage,
  handlePreviousPage,
  handleGoToPage,
  currentPage,
  perPage,
  totalItems,
}: PaginationFrameProps) => {
  const visiblePageButtons = useMemo(() => {
    const totalPages = Math.ceil(totalItems / perPage);

    if (totalPages <= 7)
      return [...Array(totalPages)].map((_, index) => index + 1);

    const firstThreePages = [1, 2, 3];
    const lastThreePages = [2, 1, 0].map((remove) => totalPages - remove);

    if (!firstThreePages.includes(currentPage) && !lastThreePages.includes(currentPage)) {
      const visiblePages: (string | number)[] = []

      visiblePages.push(...firstThreePages)

      if (currentPage > 4)
        visiblePages.push('...')

      visiblePages.push(currentPage)

      if (currentPage < totalPages - 3)
        visiblePages.push('...')

      visiblePages.push(...lastThreePages)

      return visiblePages;
    }

    return [...firstThreePages, '...', ...lastThreePages]
  }, [currentPage, perPage, totalItems]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        currentPage > 1 && handlePreviousPage();
      }
      else if (event.key === 'ArrowRight') {
        event.preventDefault();
        currentPage * perPage < totalItems && handleNextPage();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [currentPage, handleNextPage, handlePreviousPage, perPage, totalItems])

  return (
    <div className="flex items-center justify-between border-b border-neutral-focus py-4">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-dark-blue-300">
            Mostrando <span className="font-medium">{perPage * (currentPage - 1) + 1}</span> a&nbsp;
            <span className="font-medium">
              {perPage * currentPage > totalItems ? totalItems : perPage * currentPage}
            </span> de&nbsp;
            <span className="font-medium">{totalItems}</span> resultados
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePreviousPage()}
              className={twMerge(
                'relative inline-flex items-center rounded-l-md px-2 py-2 text-dark-blue-400 hover:bg-dark-blue-50 focus:z-20 focus:outline-offset-0',
                currentPage === 1 && 'cursor-not-allowed opacity-50'
              )}
            >
              <span className="sr-only">Anterior</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {visiblePageButtons.map((page, index) => {
              if (page === '...') {
                return (
                  <span
                    key={index}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-dark-blue-300 focus:outline-offset-0"
                  >
                    ...
                  </span>
                );
              }

              return (
                <button
                  key={index}
                  onClick={() => handleGoToPage(page as number)}
                  disabled={page === currentPage}
                  className={twMerge(
                    'relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus:outline-offset-0 ring-border-neutral-focus',
                    'text-white',
                    page === currentPage
                      ? 'bg-neutral/60 cursor-not-allowed text-opacity-60'
                      : 'bg-neutral'
                  )}
                >
                  {page}
                </button>
              );
            })}

            <button
              disabled={currentPage * perPage >= totalItems}
              onClick={() => handleNextPage()}
              className={twMerge(
                'relative inline-flex items-center rounded-r-md px-2 py-2 text-dark-blue-400 hover:bg-dark-blue-50 focus:z-20 focus:outline-offset-0',
                currentPage * perPage >= totalItems && 'cursor-not-allowed opacity-50'
              )}
            >
              <span className="sr-only">Próxima</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
