import React from 'react'
import classNames from 'classnames'

interface ICreativeIndicatorProps {
    children?: JSX.Element
    status: string
}

type ValidColors = 'badge-info' | 'badge-success' | 'badge-warning' | 'badge-error'

type Dictionary = {
    [key: string]: {
        name: string
        color: ValidColors
    }
}

export default function CreativeIndicator({ children, status }: ICreativeIndicatorProps) {
    const statusDictionary: Dictionary = {
        new: {
            name: 'Nova',
            color: 'badge-info',
        },
        approved: {
            name: 'Aprovado',
            color: 'badge-success',
        },
        reproved: {
            name: 'Reprovado',
            color: 'badge-error',
        },
        revised: {
            name: 'Revisado',
            color: 'badge-warning',
        },
    }

    const { name, color } = statusDictionary[status]

    if(!children) {
        return (
            <>
                <div className='flex w-full max-h-fit'>
                    <span className={classNames('badge badge-sm', color)}>{name}</span>
                </div>
            </>
        )
    }

    return (
        <div className='indicator flex w-full h-full'>
            <span className={classNames('indicator-item badge translate-x-1/4 ', color)}>{name}</span>
            <div className='bg-base-300 rounded-lg place-items-center z-1 w-full'>{children}</div>
        </div>
    )
}
