export const ZOOM_DICTIONARY = {
  2: 0.25,
  3: 0.50,
  4: 1.00,
  5: 1.25,
  6: 1.50,
  7: 1.75,
  8: 2.00,
  9: 2.25,
  10: 2.50,
  11: 2.75,
  12: 3.00
}

export const MAX_ZOOM = 3
export const MIN_ZOOM = 0.25
export const ZOOM_INCREMENT = 0.25