import React, { useEffect, useRef, useState } from 'react'
import { unzip, ZipEntry } from 'unzipit'
import InfiniteViewer from 'react-infinite-viewer'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { PlayerStatus, currentFileIsHTML, zoomState } from '../AppState'
import PreviewHtmlControls from './preview-html.controls'

import classNames from 'classnames'
import useToggleLayout from '../utils/useToggleLayout/use.toggle-layout'
import CarrouselButton from '../components/carrousel-button/carrousel-button'
import { usePreview } from '@src/contexts/usePreview/use.preview'
import Loading from '@src/components/loading'
import { MIN_ZOOM, ZOOM_INCREMENT } from '../utils/zoom'
import { twMerge } from 'tailwind-merge'
import { usePlayerUtils } from './usePlayerUtils'

type Props = {
    file: string | null
}

type HTMLProps = {
    styles: string | string[]
    html: string
}

type FontProps = {
    name: string
    dataUrl: string
    format: string
}[]

const PreviewHtml = ({ file }: Props) => {
    const [html, setHtml] = useState<HTMLProps | null>(null)
    const [font, setFont] = useState<FontProps>([])
    const { isHovering, setIsHovering, isDragging, dragEvents } = usePlayerUtils()
    const [zoom, setZoom] = useRecoilState(zoomState)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const viewer = useRef<InfiniteViewer>({} as InfiniteViewer)
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const sizes = useRef({ width: 0, height: 0 })
    const [playerStatusValue, setPlayerStatus] = useRecoilState(PlayerStatus)
    const setIsHTML = useSetRecoilState(currentFileIsHTML)
    const { useGrid } = useToggleLayout()
    const { createAnnotationFile, loading, downloadProgress } = usePreview()

    const resetPreviewStyles = `
        .previewView, .viewBox { position: relative !important; overflow: clip !important; }
    `

    const convertZipEntryToBase64 = async (zipEntry: ZipEntry) => {
        const arrayBuffer = await zipEntry.arrayBuffer();

        const base64String = Buffer.from(arrayBuffer).toString('base64');

        const extension = zipEntry.name.split('.').pop()!.toLowerCase();
        let format;
        switch (extension) {
            case 'ttf':
                format = 'truetype';
                break;
            case 'otf':
                format = 'opentype';
                break;
            case 'woff':
                format = 'woff';
                break;
            case 'woff2':
                format = 'woff2';
                break;
            default:
                throw new Error('Unsupported font format');
        }

        const dataUrl = `data:font/${format};base64,${base64String}`;

        return {
            dataUrl,
            format,
        };
    }

    const readFiles = async (url: string): Promise<void> => {
        try {
            const { entries } = await unzip(url)

            for (const res of Object.entries(entries)) {
                const name = res[0]
                const entry = res[1]

                if (name.startsWith('__MACOSX/')) continue

                if (name.includes('.json')) {
                    try {
                        const jsonText = await entry.text()
                        const htmlJson = JSON.parse(jsonText)

                        const regexWidth = /(?:\.viewBox|\.previewView)\s*\{[^}]*width:\s*(\d+)px;/u
                        const regexHeight = /(?:\.viewBox|\.previewView)\s*\{[^}]*height:\s*(\d+)px;/u

                        let matchWidth = regexWidth.exec(htmlJson?.styles)
                        let matchHeight = regexHeight.exec(htmlJson?.styles)

                        let width = Number(matchWidth?.[1] ?? 0)
                        let height = Number(matchHeight?.[1] ?? 0)

                        sizes.current = { width, height }

                        setHtml({
                            styles: htmlJson?.styles,
                            html: htmlJson?.html,
                        })

                        setPlayerStatus({
                            time: 0,
                            isPlaying: true,
                            totalTime: htmlJson?.duration,
                        })
                    } catch (error) {
                        if (error instanceof Error) {
                            console.error({ message: error.message })
                        }

                        console.error(error)
                    }
                } else if (['ttf', 'otf', 'woff', 'woff2'].includes(name.split('.').pop()!.toLowerCase())) {
                    try {
                        const convertedFont = await convertZipEntryToBase64(entry)
                        setFont((prev) => [...prev, { ...convertedFont, name: name.split('/').pop()!.split('.')![0] || '' }])
                    } catch (error) {
                        console.error(error)
                    }
                }
            }
        } catch (fault) {
            const error = fault as Error
            console.error(error.cause, error.message, error.name, error.stack)
        }
    }

    const onLoad = () => {
        if (!sizes.current || !containerRef.current) return
        const containerWidth = containerRef.current.offsetWidth
        const containerHeight = containerRef.current.offsetHeight

        const htmlWidth = sizes.current.width
        const htmlHeight = sizes.current.height

        const zoom = Math.round(Math.min(containerWidth / htmlWidth, containerHeight / htmlHeight) / ZOOM_INCREMENT) * ZOOM_INCREMENT;
        const closestZoom = Math.max(Math.min(zoom, 1.0), MIN_ZOOM) // não deixa o inicial ser maior que 1.0

        viewer.current.setZoom(closestZoom)
        viewer.current.scrollCenter()
        setZoom(closestZoom)
    }

    useEffect(() => {
        onLoad()
    }, [html])

    useEffect(() => {
        if (viewer.current?.setZoom)
            viewer.current.setZoom(zoom)
    }, [zoom])

    useEffect(() => {
        if (!file) return;

        setPlayerStatus({
            isPlaying: false,
            time: 0,
            totalTime: 0,
        })

        readFiles(file)
    }, [file])

    useEffect(() => {
        clearTimeout(timerRef.current)

        if (!playerStatusValue.isPlaying && html !== null) {
            timerRef.current = setTimeout(() => {
                if (svgRef.current)
                    createAnnotationFile(typeof html?.styles === 'string' ? 'HTML' : 'OLD-HTML', svgRef.current)
            }, 500);
        }

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [playerStatusValue.isPlaying, playerStatusValue.time])

    useEffect(() => {
        setIsHTML(true)

        return () => {
            createAnnotationFile('NULL')
            setPlayerStatus({
                isPlaying: false,
                time: 0,
                totalTime: 0,
            })
        }
    }, [])

    return html ? (
        <>
            <svg
                id='draw-html-svg'
                ref={svgRef}
                xmlns="http://www.w3.org/2000/svg"
                width={sizes.current?.width || 0}
                height={sizes.current?.height || 0}
            >
                <foreignObject width="100%" height="100%">
                    <div>
                        <style>
                            {font.map((f) => (
                                `@font-face {font-family: "${f?.name}"; src: url("${f?.dataUrl}") format(${f?.format});}\n`
                            ))}
                            {`
                                #draw-html-svg { display: none; }
                                ${typeof html?.styles === 'string' ? html?.styles : html?.styles.join('\n')}
                                :root { --current-time: ${playerStatusValue.time || 0}ms }
                            `}
                        </style>
                        <div
                            dangerouslySetInnerHTML={{ __html: html?.html || '' }}
                            style={{
                                width: `${sizes.current?.width || 0}px`,
                                height: `${sizes.current?.height || 0}px`,
                                display: 'inline-block',
                            }}>
                        </div>
                    </div>
                </foreignObject>
            </svg>

            <Loading
                isLoading={loading}
                progress={downloadProgress}
                className={classNames('bg-base-300', {
                    'absolute top-0 left-0 w-screen h-screen z-[999]': useGrid,
                    'absolute top-0 left-0 rounded-md z-[99]': !useGrid,
                })}
            >
                <div
                    className={classNames('w-full grow flex flex-row items-center justify-center h-full relative', {
                        'h-[calc(95vh-17rem)]': useGrid,
                    })}
                    ref={containerRef}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <style>{resetPreviewStyles}</style>
                    <style>
                        {font.map((f) => (
                            `@font-face {font-family: "${f?.name}"; src: url("${f?.dataUrl}") format(${f?.format});}\n`
                        ))}
                        {html?.styles}
                    </style>
                    <CarrouselButton>
                        <InfiniteViewer
                            ref={viewer}
                            className='w-full h-full bg-base-300'
                            {...dragEvents}
                        >
                            <div
                                onLoad={onLoad}
                                dangerouslySetInnerHTML={{ __html: html?.html || '' }}
                                style={{
                                    display: 'inline-block',
                                }}
                            />
                        </InfiniteViewer>
                    </CarrouselButton>

                    <div className={twMerge(
                        'absolute bottom-0 left-0 w-full bg-base-300 bg-opacity-80',
                        isHovering && !isDragging ? 'block' : 'hidden'
                    )}>
                        <PreviewHtmlControls handleCenterPreview={onLoad} />
                    </div>
                </div>
            </Loading>
        </>
    ) : (
        <CarrouselButton>
            <div className='w-full grow flex flex-row items-center justify-center h-[calc(95vh-13rem)]'>
                <>HTML não encontrado</>
            </div>
        </CarrouselButton>
    )
}

export default PreviewHtml
