import React, { useRef, useEffect, useState, createContext, PropsWithChildren, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { CampaignDetails, CreativesList, currentCreative, filteredListSelector, router } from '../../AppState'
import { useRecoilValue, useSetRecoilState } from 'recoil'

type subscribeCallback = (currentCreativeIndex: number, nextCreativeIndex: number) => void;

type NavigationContext = {
    handleNext: () => void
    handlePrevious: () => void
    setAllowKeyPressNavigation: (allow: boolean) => void
    subscribeNavigation: (callback: subscribeCallback) => void
}

const NavigationContext = createContext<NavigationContext | undefined>(undefined)

export const NavigationProvider = ({ children }: PropsWithChildren) => {
    const filteredList = useRecoilValue(filteredListSelector)
    const creativeList = useRecoilValue(CreativesList)
    const campaign = useRecoilValue(CampaignDetails)
    const setCurrentCreative = useSetRecoilState(currentCreative)
    const [allowKeyPressNavigation, setAllowKeyPressNavigation] = useState(true)
    const [navigationSubscribed, setNavigationSubscribed] = useState<subscribeCallback[]>([])

    const activeCreativeIndex = useRef<number | null>(null)

    const params = useParams()
    const client = params.client as string
    const creative = params.creative as string
    const campaignParams = params.campaign as string

    const updateRoute = (c: any) => {
        const lastRevision = c.revisions[c.revisions.length - 1]

        router.history.push(
            `/view/${client}/${campaignParams}/${campaign.key}/${c.key}/${lastRevision}${router.history.location.search}`
        )
    }

    const handleNext = () => {
        if (activeCreativeIndex.current === null) return

        notifySubscribers(activeCreativeIndex.current, (activeCreativeIndex.current + 1) % filteredList.length)
        updateRoute(filteredList[(activeCreativeIndex.current + 1) % filteredList.length])
    }

    const handlePrevious = () => {
        if (activeCreativeIndex.current === null) return

        const newIndex = activeCreativeIndex.current - 1 < 0 ? filteredList.length - 1 : activeCreativeIndex.current - 1

        notifySubscribers(activeCreativeIndex.current, newIndex)
        updateRoute(filteredList[newIndex])
    }

    const subscribeNavigation = (callback: subscribeCallback): void => {
        setNavigationSubscribed((prev) => [...prev, callback])
    }

    const notifySubscribers = (currentCreativeIndex: number, nextCreativeIndex: number) => {
        navigationSubscribed.forEach((callback) => callback(currentCreativeIndex, nextCreativeIndex))
    }

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (!allowKeyPressNavigation) return;

            switch (e.key) {
                case 'ArrowUp':
                case 'ArrowLeft':
                    handlePrevious()
                    e.preventDefault()
                    break
                case 'ArrowDown':
                case 'ArrowRight':
                    handleNext()
                    e.preventDefault()
                    break
            }
        }

        document.addEventListener('keydown', handleKeyPress, true)

        return () => {
            document.removeEventListener('keydown', handleKeyPress, true)
        }
    }, [allowKeyPressNavigation, handleNext, handlePrevious])

    useEffect(() => {
        if (creativeList.length > 0 && creative) {
            const activeCreative = creativeList.find((c) => c.key === creative)

            if (activeCreative) {
                setCurrentCreative(activeCreative)
                activeCreativeIndex.current = filteredList.findIndex((c) => c.key === creative) || 0
            }
        } else {
            activeCreativeIndex.current = null
            setCurrentCreative(null)
        }
    }, [creativeList, filteredList, creative])

    return (
        <NavigationContext.Provider value={{
            handleNext,
            handlePrevious,
            setAllowKeyPressNavigation,
            subscribeNavigation,
        }}>
            {children}
        </NavigationContext.Provider>
    )
}

export const usePreviewNavigation = () => {
    const context = useContext(NavigationContext)

    if (!context) {
        throw new Error('usePreviewNavigation error')
    }

    return context
}
