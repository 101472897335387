import React, { ComponentProps } from 'react'

type Props = ComponentProps<'button'> & {
    icon: JSX.Element
    label?: string
}

const ButtonOption = ({ icon, label = '', ...props }: Props) => {
    return (
        <button  className='btn btn-neutral' {...props}>
            {icon}
            {label}
        </button>
    )
}

export default ButtonOption
