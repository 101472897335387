import React from 'react'
import { useRecoilValue } from 'recoil'
import { currentCreative } from '../AppState'
import CreativeSelectRevision from '../creative/select-revision'
import CreativeZoom from '../creative/zoom'
import { MdCenterFocusStrong } from 'react-icons/md'

type Props = {
    handleCenterPreview: () => void
}

const PreviewControls = ({ handleCenterPreview }: Props) => {
    const creative = useRecoilValue(currentCreative)
    
    if(!creative) return null

    return (
        <>
            <div className='w-full grow flex flex-row items-center space-between py-2'>
                <div className='w-full grow flex flex-row items-center justify-start gap-x-5'> </div>
                <CreativeZoom />
                <div className={`w-full grow flex flex-row items-center justify-end gap-x-5 ml-5`}>
                    {creative.revisions.length <= 1 ? <></> : <CreativeSelectRevision />}
                    <button className='btn btn-sm' onClick={handleCenterPreview} data-tooltip-id='my-tooltip' data-tooltip-content='Centralizar formato'>
                        <MdCenterFocusStrong />
                    </button>
                </div>
            </div>
        </>
    )
}

export default PreviewControls
