import React, { useMemo } from 'react'
import { currentFileIsHTML } from '@src/AppState'
import { formatTime } from '@src/utils/time'
import { useRecoilValue } from 'recoil'

type Props = {
    time: number
    type: string
    isStatus?: boolean | undefined
}

export default function ({ time, type, isStatus = false }: Props) {
    const isHTML = useRecoilValue(currentFileIsHTML)

    // Separar isso em um componente
    const blockTimeOnExtensions = ['jpg', 'jpeg', 'png']
    const shouldHideTime = isStatus || blockTimeOnExtensions.some((e) => type.includes(e))

    const timeOnComment = useMemo(() => {
        if (shouldHideTime) return null
        switch (type) {
            case 'image/gif':
                return (
                <span className='text-xs leading-5 font-light text-right opacity-50 h-5 px-1 bg-slate-600 m-0 mr-2 rounded whitespace-nowrap'>
                    {`STEP: ${time === 0 ? 1 : time}`}
                </span>
                )
            case 'application/zip':
                return (
                    <span className='text-xs leading-5 font-light text-right opacity-50 h-5 px-1 bg-slate-600 m-0 mr-2 rounded whitespace-nowrap'>
                        {isHTML ? formatTime(time) : `STEP: ${time === 0 ? 1 : time}`}
                    </span>
                )
            case 'video/mp4':
                return (
                    <span className='text-xs leading-5 font-light text-right opacity-50 h-5 px-1 bg-slate-600 m-0 mr-2 rounded'>
                        {formatTime(time)}
                    </span>
                )
            default:
                null
        }
    }, [isHTML, type])

    return (
        <>{timeOnComment}</>
    )
}
