import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { filteredListSelector } from "@src/AppState"
import { ApiService } from '@src/services/api/api.service'

import type { Creative, CreativeType } from "@src/types"
import { unzip } from "unzipit"
import { useParams } from "react-router-dom"
import PreviewImage from "@src/preview/preview-image"
import PreviewVideo from "@src/preview/preview-video"
import PreviewGif from "@src/preview/preview-gif"
import PreviewHtml from "@src/preview/preview-html"
import { cachedFiles } from "@src/Views/gallery/creative-view/creative-view"
import PreviewDevelopments from "@src/preview/preview-devlopments"
import { v4 } from "uuid"

// This one must be a 'require' instead of 'import' because there's no type definition for this package
const gifFrames = require('gif-frames')

type Props = {
  children: ReactNode
}

type CreativeDimensions = {
  width: number
  height: number
}

type CreativeFile = {
  blob: string
  type: CreativeType
}

type PreviewContext = {
  creative: Creative | undefined
  file: CreativeFile | undefined
  annotationFile: CreativeFile | undefined
  createAnnotationFile: (
    type: 'GIF' | 'VIDEO' | 'HTML' | 'OLD-HTML' | 'IMAGE-LIST' | 'NULL' | 'IMAGE',
    originalFile?: HTMLVideoElement | SVGSVGElement | string,
    frame?: number,
  ) => void
  loading: boolean,
  displayByType: ReactNode,
  dimensions: CreativeDimensions | undefined
  downloadProgress: number
}

const PreviewContext = createContext<PreviewContext | undefined>(undefined)

const mimeTypeDictionary: { [key: string]: CreativeType } = {
  'image/jpg': 'IMAGE',
  'image/jpeg': 'IMAGE',
  'image/png': 'IMAGE',
  'image/gif': 'GIF',
  'video/mp4': 'VIDEO'
}

export const PreviewProvider = ({ children }: Props) => {
  const apiService = new ApiService()
  const filteredCreatives = useRecoilValue(filteredListSelector)

  const [file, setFile] = useState<CreativeFile | undefined>(undefined)
  const [annotationFile, setAnnotationFile] = useState<CreativeFile | undefined>(undefined)
  const [creative, setCreative] = useState<Creative | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [dimensions, setDimensions] = useState<CreativeDimensions>()
  const [downloadProgress, setDownloadProgress] = useState<number>(0)
  const currentLoadingFile = useRef<string | null>(null)
  const abortControllerRef = useRef<AbortController | null>(null)

  const params = useParams()
  const creativeKey = params.creative
  const creativeVersion = params.revision

  const getFileType = async (currentFile: string, mimeType: string): Promise<CreativeType> => {
    if (mimeType === 'application/zip') {
      const { entries } = await unzip(currentFile)
      const isHTML = Object.entries(entries).some((file) => file[0].endsWith('.json'))
      return isHTML ? 'HTML' : 'IMAGE-LIST'
    } else {
      return mimeTypeDictionary[mimeType]
    }
  }

  const getFileFromCache = (url: string): string | null => {
    if (cachedFiles.has(url)) {
      return cachedFiles.get(url)
    }
    return null
  }

  const getFile = useCallback(async () => {
    if (!creative || !creativeVersion) return
    const url = `/api/common/file/${creative.name}/${creativeVersion}`
    const cachedFile = getFileFromCache(url)

    try {
      setDownloadProgress(0)
      setLoading(true)
      currentLoadingFile.current = url

      if (cachedFile) {
        const type = await getFileType(cachedFile, creative.mimeType)
        setFile({
          blob: cachedFile,
          type: type,
        });
        return;
      }

      abortControllerRef.current?.abort()

      const abortController = new AbortController()
      abortControllerRef.current = abortController

      const res = await apiService.getCreativeFile({
        name: creative.name,
        revisionKey: creativeVersion,
        progressCallback: (progress) => setDownloadProgress(progress),
        signal: abortController.signal,
      })

      if (!res.data) throw new Error('Get creative file request failed')

      const blob = res.data.slice(0, res.data.size, creative.mimeType)
      const data = URL.createObjectURL(blob)

      try {
        const type = await getFileType(data, creative.mimeType)
        cachedFiles.set(url, data)

        if (currentLoadingFile.current === url)
          setFile({
            blob: data,
            type: type,
          })
      } catch (err) {
        console.error('Error getting file type at use.preview()', err)
      }
    } catch (err: any) {
      if (err?.message === 'canceled')
        console.info('Request aborted at use.preview()')
      else
        console.error('Error loading file at use.preview()', err)
      setFile(undefined)
    } finally {
      setLoading(false)
    }
  }, [creative, creativeVersion])

  useEffect(() => {
    const current = filteredCreatives.find(c => c.key === creativeKey)
    if (!current) return
    setCreative(current)
  }, [filteredCreatives, creativeKey])

  useEffect(() => {
    getFile()
  }, [getFile])

  useEffect(() => {
    if (!file) return
    if (file.type === 'IMAGE') {
      const img = new window.Image()
      img.src = file.blob
      img.onload = () => {
        setDimensions({
          width: img.width,
          height: img.height
        })
      }
    }
  }, [file])

  const displayByType = useMemo(() => {
    if (!file) return null

    const key = v4();
    switch (file.type) {
      case 'IMAGE':
        return <PreviewImage file={file.blob} key={key} />
      case 'VIDEO':
        return <PreviewVideo file={file.blob} key={key} />
      case 'GIF':
        return <PreviewGif gif={file.blob} key={key} />
      case 'IMAGE-LIST':
        return <PreviewDevelopments file={file.blob} key={key} />
      case 'HTML':
        return <PreviewHtml file={file.blob} key={key} />
      default:
        return null
    }
  }, [file])

  const createVideoAnnotationFile = (video: HTMLVideoElement) => {
    var canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    var canvasContext = canvas.getContext("2d");
    (canvasContext as CanvasRenderingContext2D).drawImage(video, 0, 0);

    setAnnotationFile({
      blob: canvas.toDataURL(),
      type: 'IMAGE'
    })
  }

  const createGifAnnotationFile = async (gif: string, frame: number) => {
    const frames = await gifFrames({ url: gif, frames: frame, cumulative: true })

    if (!frames[0]) return;

    const decodedImg = Buffer.from(frames[0].getImage()._obj)
    const blobImg = new Blob([decodedImg], { type: 'image/png' })

    setAnnotationFile({
      blob: URL.createObjectURL(blobImg),
      type: 'IMAGE'
    })
  }

  const createHTMLAnnotationFile = async (svg: SVGSVGElement, isOldFormat: boolean) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    canvas.width = svg.width.baseVal.value;
    canvas.height = svg.height.baseVal.value;;

    const hiddenSvgString = new XMLSerializer().serializeToString(svg);
    const visibleSvgString = hiddenSvgString.replace('#draw-html-svg { display: none; }', '')

    const img = new Image();

    img.onload = () => {
      ctx.drawImage(img, 0, 0);

      setAnnotationFile({
        blob: canvas.toDataURL(),
        type: 'IMAGE'
      })
    };

    let finalSvgString: string;

    if (isOldFormat) {
      finalSvgString = visibleSvgString
    } else {
      finalSvgString = encodeURIComponent(visibleSvgString)
        .replace(/%([0-9A-F]{2})/g, (_match, p1) => {
          return String.fromCharCode(0x00 + parseInt(p1, 16));
        })
    }

    img.src = 'data:image/svg+xml;base64,' + btoa(finalSvgString);
  }

  const createAnnotationFile: PreviewContext['createAnnotationFile'] = (
    type,
    originalFile,
    frame,
  ) => {
    if ((type !== 'NULL' && type !== 'HTML') && !originalFile)
      throw new Error('File is required for this type of annotation.')
    if (type === 'GIF' && typeof frame !== 'number')
      throw new Error('Frame is required for GIF annotation.')

    switch (type) {
      case 'NULL':
        setAnnotationFile(undefined)
        break
      case 'IMAGE':
        setAnnotationFile(file)
        break
      case 'IMAGE-LIST':
        setAnnotationFile({
          blob: originalFile as string,
          type: 'IMAGE'
        })
        break
      case 'VIDEO':
        createVideoAnnotationFile(originalFile as HTMLVideoElement)
        break
      case 'GIF':
        createGifAnnotationFile(originalFile as string, frame as number)
        break
      case 'HTML':
        createHTMLAnnotationFile(originalFile as SVGSVGElement, false)
        break
      case 'OLD-HTML':
        createHTMLAnnotationFile(originalFile as SVGSVGElement, true)
        break
      default:
        setAnnotationFile(undefined)
        throw new Error('Annotation file type not supported. Use NULL, GIF, VIDEO, IMAGE or HTML.')
    }
  }

  return (
    <PreviewContext.Provider value={{
      creative,
      file,
      annotationFile,
      createAnnotationFile,
      loading,
      displayByType,
      dimensions,
      downloadProgress,
    }}>
      {children}
    </PreviewContext.Provider>
  )
}

export const usePreview = () => {
  const context = useContext(PreviewContext)

  if (!context) {
    throw new Error('usePreview error')
  }

  return context
}