import React, { Dispatch, SetStateAction, useState } from 'react'
import { useRecoilValue } from 'recoil'

import CommentReactions from './reactions'
import DeleteButton from './delete-button'

import { currentUserAtom } from '@src/AppState'

import { Comment, Reaction } from '@src/types'
import CommentTime from './comment-time'
import CommentDate from './comment-date'
import LightBox from '@src/components/dialogs/light-box/light-box'

type Props = {
    comment: Comment
    campaign: string
    creative: string
    revision: string
    onDelete?: (value: string) => Promise<boolean>
    disableReactions?: boolean
    creativeType: string
    isSelected?: boolean
    setSelectedComment: Dispatch<SetStateAction<string | undefined>>
}

export default function CommentItem({
    comment,
    campaign,
    creative,
    revision,
    onDelete,
    disableReactions,
    creativeType,
}: Props) {
    const currentUser = useRecoilValue(currentUserAtom)
    const [reactions, setReactions] = useState<Reaction[] | undefined>(comment.reactions)
    const [deletingComment, setDeletingComment] = useState(false)

    if (!currentUser) return null

    const deleteComment = async () => {
        setDeletingComment(true)
        await onDelete?.(comment.key)
        setDeletingComment(false)
    }

    const onChange = (value: Reaction[]) => {
        setReactions(value)
    }

    return (
        <div className='flex justify-end items-center space-x-4 relative rounded-md w-full'>
            <div className='bg-[#111728] px-4 py-2 rounded-md overflow-hidden'>
                <div className='flex space-x-4 items-center justify-between'>
                    <div className='chat-header'>
                        <span className='font-bold text-[#48A5E3]'>{comment.authorName}</span>
                        <CommentDate createdAt={comment.createdAt} />
                    </div>
                    <DeleteButton
                        isDisabled={deletingComment}
                        canDelete={comment.authorEmail === currentUser?.email}
                        handleDeleteComment={deleteComment.bind(comment.key)}
                    />
                </div>
                <div className='flex flex-col'>
                    <div className='flex pb-2 flex-row gap-4 text-sm'>
                        <p style={{ overflowWrap: 'anywhere' }}>
                            <CommentTime type={creativeType} isStatus={comment.isStatus} time={comment.time} />
                            {comment.text}
                        </p>
                    </div>
                    {comment.hasNote && <LightBox commentKey={comment.key} commentText={comment.text} />}
                    {!disableReactions && !comment.isStatus && (
                        <CommentReactions
                            campaign={campaign}
                            creative={creative}
                            revision={revision}
                            comment={comment.key}
                            reactions={reactions || []}
                            onChange={onChange}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

