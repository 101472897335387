import React, {
    useEffect,
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useState,
    ReactNode,
    ChangeEvent,
    useRef
} from 'react'
import { DrawTool, Layer, Shape } from '@utils/useDraw/draw.types'
import Konva from 'konva'

type Props = {
    children: ReactNode
}

type SetState<T> = Dispatch<SetStateAction<T>>

type DrawerContext = {
    tool: DrawTool
    shapes: Shape[]
    selectedShape: Layer | undefined
    commentNoteIsSelected: boolean
    setTool: SetState<DrawTool>
    setShapes: SetState<Shape[]>
    setSelectedShape: SetState<Layer | undefined>
    setCommentNoteIsSelected: SetState<boolean>
    layers: Layer[] | undefined
    konvaStageRef: React.MutableRefObject<Konva.Stage>
    isSubmitting: boolean
    setIsSubmitting: SetState<boolean>
}

const EditorContext = createContext<DrawerContext | undefined>(undefined)

export const EditorProvider = ({ children }: Props) => {
    const konvaStageRef = useRef<Konva.Stage>({} as Konva.Stage)
    const [shapes, setShapes] = useState<Shape[]>([])
    const [selectedShape, setSelectedShape] = useState<Layer | undefined>(undefined)
    const [tool, setTool] = useState<DrawTool>('selection')
    const [layers, setLayers] = useState<Layer[]>([])
    const [commentNoteIsSelected, setCommentNoteIsSelected] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        const allShapes: Layer[] = []

        shapes.forEach(({ type, shape: { id } }) => {
            allShapes.push({ type: type, shapeId: id })
        })

        setLayers(allShapes)
    }, [shapes])

    return (
        <EditorContext.Provider
            value={{
                shapes,
                setShapes,
                tool,
                setTool,
                layers,
                commentNoteIsSelected,
                setCommentNoteIsSelected,
                selectedShape,
                setSelectedShape,
                konvaStageRef,
                isSubmitting,
                setIsSubmitting
            }}
        >
            {children}
        </EditorContext.Provider>
    )
}

export const useEditor = () => {
    const context = useContext(EditorContext)

    if (!context) {
        throw new Error('useEditor error')
    }

    return context
}
