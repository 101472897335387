import React from 'react'

const Warning = () =>  {

    if(process.env.NODE_ENV !== 'development') return null

    return(
        <div className='flex items-center justify-between px-4 w-full h-16 bg-red-700/90 text-white z-[999]'>
            <p className='text-sm max-sm:hidden'>Atenção este é um ambiente de testes.</p>
            <button className='btn btn-outline btn-sm max-sm:w-full truncate px-2'> 
               <a href='https://aprove.orom.com.br/'> Ir para ambiente de produção </a>
            </button>
        </div>
    )
}

export default Warning 