import dayjs from 'dayjs'
import React from 'react'

import { router } from '../AppState'
import { statusDictionaryBadge, statusDictionaryTranslate } from '../utils/status'

type Props = {
    statusChange: {
        user: string
        creative: string
        createdAt: string
        status: string
        version?: number
        url: string
    }
}

const StatusChange = ({ statusChange }: Props) => {
    const { user, creative, createdAt, status, version, url } = statusChange

    const onClick = () => {
        router.history.push('/view' + url)
    }

    return (
        <div className='pb-4 px-2 mb-4 relative'>
            <div className='flex items-center mb-1'>
                <span className='absolute left-5 top-6 bg-slate-500/75 h-full w-[1px]'></span>
                <div
                    className='border rounded-full w-6 h-6 mr-2 flex justify-center items-center relative border-slate-500/75 bg-slate-300 text-cyan-900 cursor-pointer'
                    onClick={onClick}
                >
                    <svg xmlns='http://www.w3.org/2000/svg' height='14' viewBox='0 0 18 18' width='14'>
                        <title>S Actions 18 N</title>
                        <rect id='Canvas' fill='currentColor' opacity='0' width='18' height='18' />
                        <path
                            fill='currentColor'
                            d='M12.7675,10.669l-1.604,1.6055,4.3925,4.392a.6815.6815,0,0,0,.96379.00071l.00071-.00071.64-.64a.6815.6815,0,0,0,.00071-.96379L17.1605,15.062Z'
                        />
                        <path
                            fill='currentColor'
                            d='M3.329,9.7655l.726-.726c.2665-.2665-.011-.644-.011-.644l.746-.719A.68149.68149,0,0,0,5.75,7.67l.4055-.4055.781.7805L8.541,6.4405,7.7585,5.66l.264-.2645a.6815.6815,0,0,0,.00071-.96379L8.0225,4.431l-.32-.32s.9425-1.058,1.14-1.256c.8325-.832,2.6755-.2955,2.7605-.7215S7.5115.1275,5.2245,2.414L2.845,4.794a.6815.6815,0,0,0-.00222.96378L2.845,5.76l.161.155L2.3,6.65a.4535.4535,0,0,0-.64081-.02621L1.65,6.6325l-.728.726a.341.341,0,0,0,0,.482l1.9245,1.925a.3405.3405,0,0,0,.48154.001Z'
                        />
                        <path
                            fill='currentColor'
                            d='M5.5205,15.2615c-.787.283-1.7705.6385-2.45.8815l.877-2.45Zm9.1-13.183-11.19,11.19a.56358.56358,0,0,0-.132.2065l-1.062,2.932a.42.42,0,0,0,.55.5545l2.947-1.05a.56353.56353,0,0,0,.21-.1335L17.131,4.578a.4785.4785,0,0,0,.0435-.673l-1.882-1.872A.4785.4785,0,0,0,14.6205,2.0785Z'
                        />
                    </svg>
                </div>
                <p className='text-xs text-slate-300'>
                    <strong>{user}</strong> alterou o status para: <strong>{statusDictionaryTranslate[status]}</strong>{' '}
                    {statusDictionaryBadge[status]}- {dayjs(createdAt).locale('pt-br').fromNow()}
                </p>
            </div>
            <h3 className='text-xs text-slate-400 ml-8'>
                <strong>Criativo:</strong> {creative}
                {version ? <span className='text-xs border rounded-full py-1 px-2'>{version}</span> : null}
            </h3>
        </div>
    )
}

export default StatusChange
