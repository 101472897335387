import React from 'react';

export const statusDictionaryBadge = {
    new: <span className='badge badge-xs badge-info mr-2'></span>,
    'need-revision': <span className='badge badge-xs badge-warning mr-2'></span>,
    approved: <span className='badge badge-xs badge-success mr-2'></span>,
    reproved: <span className='badge badge-xs badge-error mr-2'></span>,
    revised: <span className='badge badge-xs badge-warning mr-2'></span>,
}

export const statusDictionary = {
    new: <>{statusDictionaryBadge['new']} Nova</>,
    'need-revision': <>{statusDictionaryBadge['need-revision']} Revisado</>,
    approved: <>{statusDictionaryBadge['approved']} Aprovado</>,
    reproved: <>{statusDictionaryBadge['reproved']} Reprovado</>,
    revised: <>{statusDictionaryBadge['need-revision']} Revisado</>,
}

export const statusDictionaryTranslate = {
    new: 'Nova',
    'need-revision': 'Revisado',
    approved: 'Aprovado',
    reproved: 'Reprovado',
    revised: 'Revisado',
}
