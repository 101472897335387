import dayjs from 'dayjs'
import React from 'react'

import { router } from '../AppState'

type Props = {
    creative: {
        authorEmail: string
        name: string
        createdAt: string
        modified: boolean
        version?: number
        url: string
    }
}

const CreativeComponent = ({ creative }: Props) => {
    const { authorEmail, name, createdAt, modified, version, url } = creative

    const onClick = () => {
        router.history.push('/view' + url)
    }

    return (
        <div className='pb-4 px-2 mb-4 relative'>
            <div className='flex items-center mb-1'>
                <span className='absolute left-5 top-6 bg-slate-500/75 h-full w-[1px]'></span>
                <div
                    className={`border rounded-full w-6 h-6 mr-2 flex justify-center items-center relative border-slate-500/75 bg-slate-300 cursor-pointer ${
                        modified ? 'text-warn-900' : 'text-green-600'
                    }`}
                    onClick={onClick}
                >
                    {modified ? (
                        <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
                            <title>S Edit 18 N</title>
                            <rect id='Canvas' fill='currentColor' opacity='0' width='18' height='18' />
                            <path
                                fill='currentColor'
                                d='M16.7835,4.1,13.9,1.216a.60751.60751,0,0,0-.433-.1765H13.45a.6855.6855,0,0,0-.4635.203L2.542,11.686a.49494.49494,0,0,0-.1255.211L1.0275,16.55c-.057.1885.2295.4255.3915.4255a.12544.12544,0,0,0,.031-.0035c.138-.0315,3.933-1.172,4.6555-1.389a.486.486,0,0,0,.207-.1245L16.7565,5.014a.686.686,0,0,0,.2-.4415A.61049.61049,0,0,0,16.7835,4.1ZM5.7,14.658c-1.0805.3245-2.431.7325-3.3645,1.011L3.34,12.304Z'
                            />
                        </svg>
                    ) : (
                        <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
                            <title>S Add 18 N</title>
                            <rect id='Canvas' fill='currentColor' opacity='0' width='18' height='18' />
                            <path
                                fill='currentColor'
                                d='M14.5,8H10V3.5A.5.5,0,0,0,9.5,3h-1a.5.5,0,0,0-.5.5V8H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8v4.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V10h4.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,14.5,8Z'
                            />
                        </svg>
                    )}
                </div>
                <p className='text-xs text-slate-300'>
                    {authorEmail} <strong>{modified ? 'modificou' : 'criou'}</strong> o criativo -{' '}
                    {dayjs(createdAt).locale('pt-br').fromNow()}
                </p>
            </div>
            <h3 className='text-xs text-slate-400 ml-8'>
                <strong>Criativo:</strong> {name}
                {version ? <span className='text-xs border rounded-full py-1 px-2'>{version}</span> : null}
            </h3>
        </div>
    )
}

export default CreativeComponent
