import React from 'react'
import { useRecoilState } from 'recoil'
import { zoomState } from '../AppState'
import { MAX_ZOOM, MIN_ZOOM, ZOOM_INCREMENT } from '../utils/zoom'

const CreativeZoom = () => {
    const [zoom, setZoom] = useRecoilState(zoomState)

    const zoomIn = () => {
        setZoom((value) => {
            return value + ZOOM_INCREMENT > MAX_ZOOM ? MAX_ZOOM : value + ZOOM_INCREMENT
        })
    }

    const zoomOut = () => {
        setZoom((value) => {
            return value - ZOOM_INCREMENT < MIN_ZOOM ? MIN_ZOOM : value - ZOOM_INCREMENT   
        })
    }

    return (
        <div className='flex justify-between items-center'>
            <button className='btn p-1 btn-sm' onClick={zoomOut}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-5 h-5'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6'
                    />
                </svg>
            </button>

            <span className='w-tabular-nums w-20 text-white text-center'>
                {zoom * 100}%
            </span>

            <button className='btn p-1 btn-sm' onClick={zoomIn}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-5 h-5'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6'
                    />
                </svg>
            </button>
        </div>
    )
}

export default CreativeZoom
