import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import CreativeTagsOnList from './creative-tags.list'
import CreativeIndicator from '../../creative-indicator/creative-indicator'
import CreativeType from '../../creative-type/creative-type'

import { Creative } from '../../../../types'

import { useRecoilValue } from 'recoil'
import { router, currentCreative, CampaignDetails } from '../../../../AppState'

import classNames from 'classnames'
import dayjs from 'dayjs'
import { twMerge } from 'tailwind-merge'

type Props = {
    creative: Creative
}

const CreativeItem = ({ creative }: Props) => {
    const campaignDetails = useRecoilValue(CampaignDetails)
    const creativeState = useRecoilValue(currentCreative)
    const isSelected = creative.key === creativeState?.key
    const [isHovering, setIsHovering] = useState(false)
    const hoverTimerRef = useRef<NodeJS.Timeout | undefined>(undefined)

    const params = useParams()
    const client = params.client as string
    const campaign = params.campaign as string

    const onSelect = () => {
        const tags = router.history.location.search
        const lastRevision = creative.revisions[creative.revisions.length - 1]
        router.history.push(`/view/${client}/${campaign}/${campaignDetails.key}/${creative.key}/${lastRevision}${tags}`)
    }

    const { mimeType, status, name, tags } = creative

    const hoverTimer = (enable: boolean) => {
        clearTimeout(hoverTimerRef.current)

        if (enable)
            hoverTimerRef.current = setTimeout(() => {
                setIsHovering(true)
            }, 300)
        else
            setIsHovering(false)
    }

    return (
        <div className='flex flex-col w-full max-h-fit border-base-100 relative'>
            {isHovering &&
                <span className={twMerge(
                    'absolute z-20 top-8 left-16 bg-slate-600 text-white text-xs p-1 rounded-md',
                    'w-[70%] h-auto',
                )}>
                    {name.replaceAll('_', ' ')}
                </span>
            }

            <div
                className={classNames('flex w-full h-full cursor-pointer items-center p-2 space-x-4 hover:bg-neutral', {
                    'bg-neutral-focus': isSelected,
                })}
                onClick={onSelect}
            >
                <div className='flex-1'>
                    <CreativeType type={mimeType} />
                </div>
                <div className='flex-grow min-w-0 flex-shrink'>
                    <div className='flex flex-col space-y-[0.1rem]'>
                        <span
                            className='text-sm font-bold truncate'
                            onMouseEnter={() => hoverTimer(true)}
                            onMouseLeave={() => hoverTimer(false)}
                        >
                            {name}
                        </span>
                        <span className='text-xs font-light truncate'>Atualizado {dayjs(creative.updatedAt).locale('pt-br').fromNow()}</span>
                    </div>
                </div>
                <div className='flex-1'>
                    <CreativeIndicator status={status} />
                </div>
            </div>

            {isSelected && tags.length !== 0 && (
                <div className='flex flex-wrap grow w-full h-fit p-2 bg-neutral-focus/40 '>
                    <CreativeTagsOnList tags={tags} />
                </div>
            )}
        </div>
    )
}

export default CreativeItem
