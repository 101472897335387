import useDraw from "@src/utils/useDraw"
import { Shapes } from "@src/utils/useDraw/draw.types"
import React, { ChangeEvent, forwardRef, useRef, useState } from "react"
import { Text } from 'react-konva'
import { Html } from "react-konva-utils"

type Props = {
  shapeProps: Shapes
  drawHook: ReturnType<typeof useDraw>
}

export const TextShape = forwardRef(({ shapeProps, drawHook, ...extraProps }: Props, ref) => {
  const [text, setText] = useState(shapeProps.text || '')
  const [textAreaPos, setTextAreaPos] = useState({ x: shapeProps.x, y: shapeProps.y })
  const [isTextEditable, setIsTextEditable] = useState(false)
  const { updateShapeProperties, addToHistory } = drawHook
  const initEditingText = useRef<string | null>(null)


  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
    updateShapeProperties(shapeProps.id!, {
      text: e.target.value,
    }, false)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
      e.stopPropagation();
      return;
    }

    switch (e.key) {
      case 'Escape':
        e.stopPropagation();
        e.preventDefault();
        setIsTextEditable(false);
        break;
      case 'Delete':
      case 'Backspace':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
        e.stopPropagation();
        break;
      default:
    }
  }

  const handleFinishEditing = () => {
    setIsTextEditable(false)

    if (text === initEditingText.current) {
      initEditingText.current = null;
      return;
    }

    addToHistory('text-editing', {
      text: initEditingText.current,
      id: shapeProps.id
    })
    initEditingText.current = null;
  }

  return (
    <>
      {isTextEditable && (
        <Html groupProps={{ x: textAreaPos.x, y: (textAreaPos.y || 0) - 60 }}>
          <textarea
            id='text-editor'
            name='text-editor'
            value={text}
            onChange={handleChangeText}
            autoFocus
            onFocus={(e) => {
              e.target.selectionStart = e.target.value.length;
              initEditingText.current = e.target.value;
            }}
            onBlur={handleFinishEditing}
            onKeyDown={handleKeyDown}
            style={{
              height: 50,
            }}
          />
        </Html>
      )}

      <Text
        ref={ref as React.Ref<any>}
        onDragMove={(e) => setTextAreaPos({ x: e.target.x(), y: e.target.y() })}
        onDblClick={() => setIsTextEditable(!isTextEditable)}
        {...extraProps}
      />
    </>
  )
})