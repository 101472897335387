import React from 'react'

import TableHeader from './table-header'
import TableBody from './table-body/table-body'
import classNames from 'classnames'

const CampaignList = () => {

    return (
        <div className={classNames('overflow-x-auto w-full mx-auto h-full', {})}>
            <table className='table table-pin-rows table-pin-cols w-full rounded-md bg-base-400'>
                <TableHeader />
                <TableBody />
            </table>
        </div>
    )
}

export default CampaignList
