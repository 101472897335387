import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { KonvaNodeEvents } from 'react-konva'

import { LineConfig } from 'konva/lib/shapes/Line'
import { RectConfig } from 'konva/lib/shapes/Rect'
import { CircleConfig } from 'konva/lib/shapes/Circle'
import { TextConfig } from 'konva/lib/shapes/Text'

import { useEditor } from '@src/contexts/useEditor/use.editor'
import type { DrawAction, Layer, Shape, ShapePropertiesMap, ShapeType, Shapes } from './draw.types'
import Konva from 'konva'
import { useHistory } from '../useHistory/useHistory'
import { KonvaEventObject } from 'konva/lib/Node'

const useDraw = () => {
	const {
		shapes,
		setShapes,
		tool,
		setTool,
		selectedShape,
		setSelectedShape,
	} = useEditor()
	const { addToHistory, undoHistory, clearHistory } = useHistory()

	const [action, setAction] = useState<DrawAction>('none')
	const [color, setColor] = useState<string>('#48A5E3')

	const propertiesTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

	useEffect(() => {
		return () => {
			clearTimeout(propertiesTimerRef.current)
		}
	}, [])

	const _generateRandomId = () => {
		return Math.random().toString(36).substring(2, 15)
	}

	const _selectLastOnMouseUp = () => {
		const lastShape = shapes[shapes.length - 1]
		const {
			type,
			shape: { id },
		} = lastShape

		if (id && tool !== 'pen' && tool !== 'eraser') {
			setTool('selection')
			setSelectedShape({ shapeId: id, type })
		}
	}

	const _getLastShapeFromType = useCallback((type: ShapeType) => {
		const allShapesFromType = shapes.filter((el) => el.type === type)
		return allShapesFromType[allShapesFromType.length - 1]
	}, [shapes])

	const selectShape = ({ shapeId, type }: Layer) => {
		if (shapeId) setSelectedShape({ shapeId, type })
	}

	const deleteSelectedShape = () => {
		const id = selectedShape?.shapeId
		const filteredShapes = []
		let deletedShape: Shape | undefined = undefined;
		let deletedShapeIndex: number | undefined = undefined;

		for (let i = 0; i < shapes.length; i++) {
			const shape = shapes[i]
			if (shape.shape.id !== id) {
				filteredShapes.push(shape)
			} else {
				deletedShape = shape
				deletedShapeIndex = i
			}
		}

		if (deletedShape)
			addToHistory('delete', deletedShape, deletedShapeIndex)

		if (id) setShapes(filteredShapes)
		setSelectedShape(undefined)
	}

	const createShape = useCallback(
		(type: ShapeType, startPoint: { x: number; y: number }): Shapes | null => {
			const generalProps = {
				id: _generateRandomId(),
				onTransformEnd: (e: KonvaEventObject<Event>) => {
					const targetId = e.target.id()
					if (!targetId) return;

					const { offsetX, offsetY, ...restAttrs } = e.target.attrs
					updateShapeProperties(targetId, {
						...restAttrs,
						offset: { x: offsetX, y: offsetY },
					})
				}
			}

			switch (type) {
				case 'rectangle':
					return {
						x: startPoint.x,
						y: startPoint.y,
						width: 0,
						height: 0,
						fill: 'transparent',
						stroke: color,
						strokeWidth: 3,
						...generalProps,
					} as RectConfig
				case 'pen':
					return {
						points: [startPoint.x, startPoint.y],
						stroke: color,
						strokeWidth: 4,
						...generalProps,
					} as LineConfig
				case 'eraser':
					return {
						points: [startPoint.x, startPoint.y],
						stroke: color,
						strokeWidth: 20,
						globalCompositeOperation: 'destination-out',
						...generalProps,
					} as LineConfig
				case 'text':
					return {
						x: startPoint.x,
						y: startPoint.y,
						text: 'Seu texto aqui',
						fontSize: 14,
						fontFamily: 'Arial',
						fill: color,
						...generalProps,
					} as TextConfig
				case 'circle':
					return {
						x: startPoint.x,
						y: startPoint.y,
						radius: 0,
						fill: 'transparent',
						stroke: color,
						strokeWidth: 3,
						...generalProps,
					} as CircleConfig
				default:
					return null
			}
		}, [color])

	const updateShape = useCallback((type: ShapeType, shape: Shapes, point: { x: number; y: number }): Shapes => {
		const { shape: lastShape } = _getLastShapeFromType(type)
		switch (type) {
			case 'pen':
			case 'eraser':
				if (!lastShape.points?.length) return shape
				lastShape.points = Array.from(lastShape.points).concat([point.x, point.y])
				return { ...lastShape }
			case 'rectangle':
			case 'text':
				if (!lastShape.x || !lastShape.y) return shape
				lastShape.width = point.x - lastShape.x
				lastShape.height = point.y - lastShape.y
				return { ...lastShape }
			case 'circle': {
				if (!lastShape.x || !lastShape.y) return shape
				const radius = Math.sqrt(Math.pow(point.x - lastShape.x, 2) + Math.pow(point.y - lastShape.y, 2)) / 2
				lastShape.radius = radius
				lastShape.offset = {
					x: -radius,
					y: -radius,
				}
				return { ...lastShape }
			}
			default:
				return shape
		}
	}, [_getLastShapeFromType])

	const updateShapeProperties = useCallback((
		shapeId: string,
		properties: Partial<ShapePropertiesMap[ShapeType]>,
		enableHistory = true
	) => {
		setShapes(prev =>
			prev.map((item, index) => {
				if (item.shape.id === shapeId) {
					if (enableHistory) {
						addToHistory('properties', item.shape, index);
					}
					return {
						...item,
						shape: {
							...item.shape,
							...properties,
						},
					}
				}
				return item
			})
		)
	}, [])

	const setShapeColor = useCallback((newColor: string) => {
		const type = selectedShape?.type
		const shapeId = selectedShape?.shapeId

		clearTimeout(propertiesTimerRef.current)

		propertiesTimerRef.current = setTimeout(() => {
			if (type && shapeId)
				updateShapeProperties(shapeId, { [type === 'text' ? 'fill' : 'stroke']: newColor })

			setColor(newColor)
		}, 100);
	}, [selectedShape])

	const konvaEvents: KonvaNodeEvents = useMemo(() => ({
		onClick: (e) => {
			if (tool !== 'selection') return;

			if (e.target instanceof Konva.Line || e.target instanceof Konva.Image)
				setSelectedShape(undefined)
			else if (selectedShape?.shapeId !== e.target.id())
				setSelectedShape({
					type: e.target.getClassName().toLowerCase() as keyof ShapePropertiesMap,
					shapeId: e.target.id()
				})
		},
		onTouchStart: (e) => {
			const stage = e.target.getStage()
			if (!stage) return

			const isEmpty = e.target === stage
			if (isEmpty) setSelectedShape(undefined)
		},
		onMouseDown: (e) => {
			if (tool !== 'selection') setSelectedShape(undefined)

			const stage = e.target.getStage()
			if (!stage) return;

			const point = stage.getPointerPosition()
			if (!point) return;

			if (tool === 'selection') {
				const isEmpty = e.target === stage
				if (isEmpty) setSelectedShape(undefined)
				return;
			}

			const type = tool
			const newShape = createShape(type, point)

			if (newShape) {
				setShapes([...shapes, { type, shape: newShape }])
				addToHistory('create')
			}

			setAction('drawing')
		},
		onMouseMove: (e) => {
			if (tool === 'selection') return;

			const stage = e.target.getStage()
			if (!stage) return

			const point = stage.getPointerPosition()
			if (!point) return

			if (action == 'drawing') {
				const type = tool
				const shapeToUpdate = _getLastShapeFromType(type)
				const updatedShape = updateShape(type, shapeToUpdate, point)
				updateShapeProperties(shapeToUpdate.shape.id!, updatedShape, false)
			}
		},
		onMouseUp: () => {
			if (action === 'drawing') {
				_selectLastOnMouseUp()
			}
			setAction('none')
		},
		onDragEnd: (e) => {
			if (tool !== 'selection') return;

			const id = e.target.id()
			if (!id) return;

			updateShapeProperties(id, {
				x: e.target.x(),
				y: e.target.y(),
			})
		},
	}), [tool, action, shapes, selectedShape, createShape, updateShape, updateShapeProperties])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Delete' || event.key === 'Backspace') deleteSelectedShape()
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [deleteSelectedShape])

	return {
		konvaEvents,
		selectShape,
		selectedShape,
		shapes,
		updateShapeProperties,
		deleteSelectedShape,
		setShapeColor,
		color,
		undoHistory,
		clearHistory,
		addToHistory,
	}
}

export default useDraw
