import React, { useState } from 'react'

type Props = {
    index: number
    name: string
    label: string
    value: string
    onChange: (index: number, name: string, value: string) => void
    description?: string
    disabled?: boolean
}

const FormInput = ({ name, index, label, value, onChange, description, disabled }: Props) => {
    const [error, setError] = useState<string | undefined>()

    const handleChange = (e: any) => {
        const value = e.target.value
        const name = e.target.name

        onChange(index, name, value)
    }

    return (
        <div className='form-control w-full'>
            <div className='flex justify-between items-center'>
                <label className='label'>
                    <span className='label-text'>{label}</span>
                </label>
            </div>
            <input
                type='text'
                className='input input-bordered'
                name={name}
                value={value}
                onChange={handleChange}
                disabled={disabled}
            />
            {error && (
                <label>
                    <span className='label-text-alt text-red-600'>{error}</span>
                </label>
            )}
            {!error && description && (
                <label>
                    <span className='label-text-alt'>{description}</span>
                </label>
            )}
        </div>
    )
}

export default FormInput
