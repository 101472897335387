import React, { useState } from 'react'

import CampaignList from '../../components/campaign-list/campaign-list'
import CreateCampaignDialog from '../../components/dialogs/create-campaign-dialog'
import SearchBar from '../../components/search-bar/search-bar'

import { useSetRecoilState } from 'recoil'
import { campaignsListState, router } from '../../AppState'
import { campaignOptions } from '../../services/campaign.service'
import LayoutContainer from '../../components/layout-container'
import Loading from '../../components/loading'
import toast from 'react-hot-toast'

export function AdminCampaigns() {
    const [loading, setLoading] = useState(true)
    const setCampaigns = useSetRecoilState(campaignsListState)
    const { onGetCampaignList } = campaignOptions

    const handleSetCampaign = async () => {
        try {
            const campaigns = await onGetCampaignList()
            setCampaigns(campaigns)
            setLoading(false)
        } catch (error: any) {
            if (error?.status === 401) {
                toast.error('Sua sessão expirou, por favor faça login novamente.')
                return router.history.push('/logout')
            }

            throw error
        }
    }

    React.useEffect(() => {
        handleSetCampaign()
    }, [setCampaigns])

    return (
        <LayoutContainer>
            <div className='flex flex-col w-full mx-auto h-28'>
                <header className='flex justify-between'>
                    <h1 className='text-lg sm:text-2xl font-bold grow'>Campanhas</h1>
                    <CreateCampaignDialog />
                </header>
                <SearchBar />
            </div>
            <div className='h-[calc(100%-128px)]'>
                <Loading className='flex-1' isLoading={loading}>
                    <CampaignList />
                </Loading>
            </div>
        </LayoutContainer>
    )
}
