import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { campaignListOrderState } from '../../AppState'

import { TbSortAscendingLetters as AscendingIcon, TbSortDescendingLetters as DescendingIcon } from 'react-icons/tb'
import classNames from 'classnames'

type TooltipMessageOrder = {
    ascending: string
    descending: string
}

const ButtonChangeOrder = () => {
    const [{ ascending, by }, setOrder] = useRecoilState(campaignListOrderState)

    const handleSetOrder = () => {
        setOrder((prev) => {
            return {
                by: prev.by,
                ascending: !prev.ascending,
            }
        })
    }

    const tooltipMessage: TooltipMessageOrder = useMemo(() => {
        const orderByDate = by === 'updatedAt' || by === 'createdAt'
        if (orderByDate) {
            return { ascending: 'Ordenar por: mais recentes', descending: 'Ordenar por: mais antigas' }
        } else {
            return { ascending: 'Ordem alfabética (A-Z)', descending: 'Ordem alfabética (Z-A)' }
        }
    }, [ascending, by])

    return (
        <div className='flex gap-x-4'>
            <label className='label-text'>Ordem de visualização: </label>
            <span data-tooltip-id='my-tooltip' data-tooltip-content={tooltipMessage.ascending}>
                <button
                    disabled={ascending}
                    onClick={handleSetOrder}
                    className={classNames('w-16 btn btn-neutral', {
                        'disabled:bg-neutral-focus disabled:text-white': ascending,
                    })}
                >
                    <AscendingIcon size='1.2rem' />
                </button>
            </span>
            <span data-tooltip-id='my-tooltip' data-tooltip-content={tooltipMessage.descending}>
                <button
                    disabled={!ascending}
                    onClick={handleSetOrder}
                    className={classNames('w-16 btn btn-neutral', {
                        'disabled:bg-neutral-focus disabled:text-white': !ascending,
                    })}
                >
                    <DescendingIcon size='1.2rem' />
                </button>
            </span>
        </div>
    )
}

export default ButtonChangeOrder
