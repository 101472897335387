import React, { useEffect, useMemo, useState } from 'react'

import Panel from '../panel/panel'
import Submit from './submit'
import CommentComponent from './comment-item/comment-item'
import Loading from '../../loading'

import axios from 'axios'
import dayjs from 'dayjs'
import { CommentsList } from '@src/AppState'
import { useRecoilState } from 'recoil'
import type { Creative } from '@src/types'
import { ApiService } from '@src/services/api/api.service'

type Props = {
    creative: Creative
    campaignKey: string
}

const Comments = ({ campaignKey, creative }: Props) => {
    const apiService = new ApiService()
    const [comments, setComments] = useRecoilState(CommentsList)
    const [selectedComment, setSelectedComment] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [sort, setSort] = useState('oldests')

    const getComments = async () => {
        try {
            setLoading(true)
            const revision = creative.revisions[creative.revisions.length - 1]
            const { data: result } = await apiService.getComments({ campaignKey, creativeKey: creative.key, revisionKey: revision })
            if (result && result.ok) {
                setComments(result.items.sort((a: { createdAt: string }, b: { createdAt: any }) => a.createdAt.localeCompare(b.createdAt)))
            }
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    const onDelete = async (key: string) => {
        try {
            const request = await axios.put(`/api/common/comments/${campaignKey}/creative/${creative.key}/${key}`)
            const result = request.data
            if (result.ok === true) {
                setComments((comments) => [...comments].filter((c) => c.key !== key))
                return true
            }

            return false
        } catch (err) {
            console.error(err)
            return false
        }
    }

    const sortedComments = useMemo(() => {
        if (comments.length === 0) return []

        if (sort === 'recents')
            return [...comments].sort((a, b) => (dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1))
        else return [...comments].sort((a, b) => (dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? -1 : 1))
    }, [comments, sort])

    useEffect(() => {
        getComments()
    }, [creative.key])

    const markSelected = (val: string) => {
        return sort === val ? '⏺' : ''
    }

    return (
        <Panel
            title='Comentários'
            options={[
                {
                    action: () => setSort('recents'),
                    title: `⬆ mais antigos ${markSelected('recents')}`
                },
                {
                    action: () => setSort('oldests'),
                    title: `⬇ mais recentes ${markSelected('oldests')}`,
                },
            ]}
        >
            <Loading isLoading={loading}>
                <div className='flex flex-col min-h-max h-full justify-between'>
                    <div className='flex flex-col p-4 space-y-6 overflow-y-auto mt-auto'>
                        {sortedComments?.map((comment, i) => {
                            return (
                                <CommentComponent
                                    key={`${comment.key}/${comment.authorName}`}
                                    comment={comment}
                                    campaign={campaignKey}
                                    creative={creative.key}
                                    revision={creative.revisions[creative.revisions.length - 1]}
                                    onDelete={onDelete}
                                    creativeType={creative.mimeType}
                                    setSelectedComment={() => setSelectedComment(comment.key)}
                                    isSelected={comment.key === selectedComment}
                                />
                            )
                        })}

                        {comments.length === 0 && (
                            <div className='flex flex-col justify-center py-10 bg-base-300'>
                                <h3 className='text-md text-center font-semibold grow'>Nenhum comentário</h3>
                            </div>
                        )}
                    </div>
                </div>
            </Loading>
        </Panel>
    )
}

export default Comments
