export type NamespacedTag = {
    position: number
    namespace: string
    tag: string
}

// uma namespaced tag é uma tag que tem um namespace e opcionalmente uma posição,
// eg.: "Empresa: Meu cliente favorito", "1-Empresa: Meu Cliente Favorito"
export function stringToNamespacedTag(s: string): NamespacedTag {
    const sameTag = {
        position: 0,
        namespace: '',
        tag: s,
    }

    if (!s.includes(':') || s.match(/.*?:\s?$/g)) {
        return sameTag
    }

    const match = s.match(/^((\d+)\s?-\s?)?((.*?):\s?)?(.*)$/i)
    if (!match) {
        return sameTag
    }

    const [, position, , , namespace, tag] = match
    return {
        position: Number.parseInt(position ? position.trim() : '9999') || 9999,
        namespace: namespace.trim() || '',
        tag: tag.trim(),
    }
}

function humanCompareNamespacedTags(a: NamespacedTag, b: NamespacedTag): number {
    if (a.position !== b.position) {
        return a.position - b.position
    }

    if (a.namespace !== b.namespace) {
        return a.namespace.localeCompare(b.namespace)
    }

    const aPrefix = extractPrefix(a.tag)
    const bPrefix = extractPrefix(b.tag)
    if (aPrefix.localeCompare(bPrefix) !== 0) {
        return aPrefix.localeCompare(bPrefix)
    }

    const aDate = extractDate(a.tag)
    const bDate = extractDate(b.tag)
    if (aDate && bDate) {
        return compareDates(aDate, bDate)
    }

    const aNumber = extractNumber(a.tag)
    const bNumber = extractNumber(b.tag)
    if (aNumber && bNumber) {
        return aNumber - bNumber
    }

    return a.tag.localeCompare(b.tag)
}

function groupTagsByNamespace(tags: NamespacedTag[]): Array<[string, NamespacedTag[]]> {
    const grouped = new Map<string, NamespacedTag[]>()
    const namespaceOrder: Record<string, number> = {}
    for (const tag of tags) {
        const group = grouped.get(tag.namespace) || []
        namespaceOrder[tag.namespace] = tag.position

        group.push(tag)
        grouped.set(tag.namespace, group)
    }

    return Array.from(grouped.entries())
        .sort(([a], [b]) => {
            const diff = namespaceOrder[a] - namespaceOrder[b]
            return diff === 0 ? a.localeCompare(b) : diff
        })
        .map(([namespace, tags]) => [namespace, tags.sort(humanCompareNamespacedTags)])
}

export function isSameTag(a: string | NamespacedTag, b: string | NamespacedTag): boolean {
    let tagA: NamespacedTag
    let tagB: NamespacedTag

    if (typeof a === 'string') {
        tagA = stringToNamespacedTag(a)
    } else {
        tagA = a
    }

    if (typeof b === 'string') {
        tagB = stringToNamespacedTag(b)
    } else {
        tagB = b
    }

    return tagA.namespace === tagB.namespace && tagA.tag === tagB.tag
}

export function useTagManager(tags: string[]) {
    const allTags = groupTagsByNamespace(tags.map(stringToNamespacedTag))
    const notNamespacedTags = allTags.find((i) => i[0] === '') || ['', []]
    const namespacedTags = allTags.filter((i) => i[0] !== '') || []
    return {
        tags: notNamespacedTags[1].map((i) => i.tag),
        namespacedTags: namespacedTags,
    }
}

// Helper functions for date and number extraction and comparison

function extractDate(str: string): Date | null {
    const dateRegex = /(\d{1,2}\/\d{1,2}\/\d{2,4}|\d{1,2}-\d{1,2}-\d{2,4})/
    const match = str.match(dateRegex)
    if (match) {
        const dateString = match[1]
        let [day, month, year] = dateString.split(/\/|-/).map(s => Number.parseInt(s, 10))
        if (year < 100) {
            year += 2000
        }
        return new Date(year, month - 1, day)
    }
    return null
}

function compareDates(date1: Date, date2: Date): number {
    return date1.getTime() - date2.getTime()
}

function extractNumber(str: string): number | null {
    const numberRegex = /(\d+)/
    const match = str.match(numberRegex)
    if (match) {
        return parseInt(match[1])
    }
    return null
}


function extractPrefix(str: string): string {
    const index = str.search(/\d/)
    return index !== -1 ? str.substring(0, index).trim() : str.trim()
}