import React, { useEffect, useMemo, useState } from 'react'
import CreativeCard from '../creative-card'
import { Creative } from '../../../types'
import Message from '../../../components/message'

import Masonry from 'react-masonry-css'
import useToggleLayout from '../../../utils/useToggleLayout/use.toggle-layout'
import classNames from 'classnames'
import { CreativeDialog } from '../creative-dialog'
import { PaginationFrame } from '@src/components/PaginationFrame'
type Props = {
    creatives: Creative[]
}

const breakpointObj = {
    default: 4,
    10000: 10,
    4000: 7,
    3000: 6,
    2000: 5,
    1200: 3,
    1000: 2,
    500: 1,
}

const perPage = 50

export default function CreativeGallery({ creatives }: Props) {
    const { isMobile } = useToggleLayout()
    const [currentPage, setCurrentPage] = useState<number>(1)

    const paginatedCreatives = useMemo(() => (
        creatives.slice((currentPage - 1) * perPage, currentPage * perPage)
    ), [currentPage, creatives])

    useEffect(() => {
        setCurrentPage(1)
    }, [creatives])

    return (
        <div className='pt-4 overflow-x-auto'>
            {creatives.length === 0 ? (
                <Message text='Nenhum criativo encontrado.' />
            ) : (
                <>
                    <PaginationFrame
                        totalItems={creatives.length}
                        currentPage={currentPage}
                        perPage={perPage}
                        handleNextPage={() => setCurrentPage(prev => prev + 1)}
                        handlePreviousPage={() => setCurrentPage(prev => prev - 1)}
                        handleGoToPage={setCurrentPage}
                    />
                    <Masonry className={classNames('flex animate-slide-fwd space-x-8 w-[93%] mt-6', {
                        'w-[calc(100%-2rem)]': isMobile
                    })} breakpointCols={breakpointObj}>
                        {paginatedCreatives.map((creative) => (
                            <CreativeCard key={creative.key} creative={creative} creativeKey={creative.key} />
                        ))}
                    </Masonry>

                    <CreativeDialog />
                </>
            )}
        </div>
    )
}
