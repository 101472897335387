import React, { useState, ComponentProps } from 'react'
import axios from 'axios'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { CommentsList, PlayerStatus } from '../AppState'
import { Creative } from '../types'
import toast from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'
import { usePreviewNavigation } from '@src/utils/useNavigationHandlers'

type Props = ComponentProps<'input'> & {
    onSubmit?: () => void
    creative: Creative
    campaignKey: string
    disableTime?: boolean
}

const CommentSubmit = ({ onSubmit, campaignKey, creative, disableTime, className }: Props) => {
    const [comment, setComment] = useState('')
    const { time, isPlaying } = useRecoilValue(PlayerStatus)
    const [sending, setSending] = useState(false)
    const setComments = useSetRecoilState(CommentsList)
    const { setAllowKeyPressNavigation } = usePreviewNavigation()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const revision = creative.revisions[creative.revisions.length - 1]

        try {
            setSending(true)
            const request = await axios.post(
                `/api/common/comments/${campaignKey}/creative/${creative.key}/${revision}`,
                {
                    comment,
                    time: disableTime ? 0 : time,
                }
            )
            const result = request.data
            if (result.ok === true) {
                toast.success('Comentário adicionado com sucesso!')
                setComments((comments) => [...comments, result.item]);
                (e as any).target.reset();
                setComment('')
                onSubmit?.()
            }
        } catch (err) {
            toast.error('Erro ao adicionar comentário 😕')
            console.error(err)
        } finally {
            setSending(false)
        }
    }

    const updateComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setComment(value)
    }

    const btnSendTooltip = (): string => {
        if (comment.length < 1) return 'Digite um comentário...'
        if (isPlaying) return 'Pause a animação para enviar...'
        else return 'Enviar'
    }

    const message = btnSendTooltip()

    return (
        <form className={twMerge('flex-grow btn-group h-12', className)} onSubmit={handleSubmit}>
            <input
                name='comment'
                className={twMerge(
                    'w-full h-full px-4',
                    'rounded-lg resize-none rounded-r-none bg-slate-800 border-slate-700 focus:z-[99]'
                )}
                placeholder='Deixe um comentário'
                value={comment}
                onChange={updateComment}
                onFocus={() => setAllowKeyPressNavigation(false)}
                onBlur={() => setAllowKeyPressNavigation(true)}
                disabled={sending}
                autoComplete='off'
            ></input>
            <span data-tooltip-id='my-tooltip' data-tooltip-html={message}>
                <button
                    type='submit'
                    className='btn h-full bg-slate-700 rounded-l-none font-normal border-slate-700 text-white focus:z-[99]'
                    disabled={sending || comment.length < 1 || isPlaying}
                >
                    Enviar
                </button>
            </span>
        </form>
    )
}

export default CommentSubmit
