
import { endpoints } from '@src/constants/endpoints';
import { HttpService, HttpResponseProps } from '../infra/http.service';

import type {
    ReqCreativeFile,
    PostCommentDTO,
    ReqComments,
    ResPostComment,
    ResGetComments
} from './api.dto';

export class ApiService extends HttpService {
    constructor() {
        super()
    }

    async getCreativeFile({ name, revisionKey, progressCallback, signal }: ReqCreativeFile): Promise<HttpResponseProps<Blob>> {
        return await this.request({
            method: 'GET',
            responseType: 'blob',
            url: endpoints.creative.getFile({ name, revisionKey }),
            timeout: 1000 * 60 * 10, // 10 minutes,
            signal,
            onDownloadProgress: (progressEvent) => {
                if (progressCallback && !signal?.aborted) {
                    const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1))
                    progressCallback(progress)
                }
            },
        })
    }

    async postComment({ campaignKey, creativeKey, revisionKey, comment, time, img }: PostCommentDTO): Promise<HttpResponseProps<ResPostComment>> {
        const formData = new FormData()
        formData.append('time', time.toString())
        formData.append('comment', comment)
        if (img) formData.append('note', img, 'img.png')

        return await this.request({
            method: 'POST',
            url: endpoints.comments.post({ campaignKey, creativeKey, revisionKey }),
            data: formData
        })
    }

    async getComments({ campaignKey, creativeKey, revisionKey }: ReqComments): Promise<HttpResponseProps<ResGetComments>> {
        return await this.request({
            method: 'GET',
            url: endpoints.comments.get({ campaignKey, creativeKey, revisionKey })
        })
    }

    async getCommentFile(commentKey: string): Promise<HttpResponseProps<Blob>> {
        return await this.request({
            method: 'GET',
            responseType: 'blob',
            url: endpoints.comments.getFile(commentKey),
        })
    }

}
