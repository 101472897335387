import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'

import IconTrash from '../../../components/icons/trash'
import ConfirmDialog from '../../../components/confirm-dialog/confirm-dialog'

type Props = {
    user: {
        email: string
        key: string
    }
    onDelete: (key: string) => void
}

const UserComponent = ({ user, onDelete }: Props) => {
    const { email, key } = user

    const params = useParams()
    const { campaign } = params

    const [showDialog, setShowDialog] = useState(false)
    const [sending, setSending] = useState(false)
    const [excluding, setExcluding] = useState(false)

    const handleDelete = async () => {
        setSending(true)

        try {
            const request = await axios.delete(`/api/admin/campaign/${campaign}/users/${key}`)
            const response = request.data

            if (response.ok) {
                toast.success(`Usuário ${email} removido com sucesso`)
                onDelete(key)
            } else {
                toast.error('Alguma coisa deu errado =/')
            }
        } catch (e) {
            toast.error('Alguma coisa deu errado =/')
        } finally {
            setSending(false)
        }
    }

    const openConfirmDialog = async () => {
        setShowDialog(true)
    }

    const handleCloseDialog = () => {
        setShowDialog(false)
    }

    const handleConfirmDelete = async () => {
        setExcluding(true)
        await handleDelete()
        setExcluding(false)
        setShowDialog(false)
    }

    return (
        <div className='border-b last:border-none border-slate-700 pb-2 px-2 mb-2 last:mb-0 last:pb-0 flex justify-between items-center'>
            <p className='text-mx text-slate-300'>{email}</p>
            <button
                onClick={openConfirmDialog}
                className={`btn btn-sm btn-ghost ml-2 ${sending ? 'loading' : ''}`}
                disabled={sending}
                data-tooltip-id='my-tooltip'
                data-tooltip-html='Excluir usuário'
                data-tooltip-place='top'
            >
                <IconTrash alt='Excluir usuário'/>
            </button>

            {showDialog && (
                <ConfirmDialog
                    isLoading={excluding}
                    onClose={handleCloseDialog}
                    onConfirm={handleConfirmDelete}
                    title='Tem certeza que deseja excluir o usuário abaixo?'
                    description={email}
                />
            )}
        </div>
    )
}

export default UserComponent
