import React, { useEffect, useRef, useState } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { PlayerStatus, currentFileIsHTML, zoomState } from '../AppState'

import InfiniteViewer from 'react-infinite-viewer'
import CarrouselButton from '../components/carrousel-button'
import PreviewControls from './preview.controls'

import useToggleLayout from '../utils/useToggleLayout'
import classNames from 'classnames'
import { unzip } from 'unzipit'
import { MIN_ZOOM, ZOOM_INCREMENT } from '../utils/zoom'
import { usePreview } from '@src/contexts/usePreview/use.preview'
import Loading from '@src/components/loading'
import { twMerge } from 'tailwind-merge'
import { usePlayerUtils } from './usePlayerUtils'

type Props = {
    file: string
}

type ImageObject = {
    index: number,
    url: string
}

const PreviewDevelopments = ({ file }: Props) => {
    const [imageURLS, setImageURLS] = useState<string[]>([])
    const [position, setPosition] = useState(0)
    const { isHovering, setIsHovering, isDragging, dragEvents } = usePlayerUtils()

    const setStatus = useSetRecoilState(PlayerStatus)
    const imageRef = useRef<HTMLImageElement | null>(null)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const viewer = useRef<InfiniteViewer>({} as InfiniteViewer)
    const [zoom, setZoom] = useRecoilState(zoomState)
    const setIsHTML = useSetRecoilState(currentFileIsHTML)
    const { useGrid } = useToggleLayout()
    const { createAnnotationFile, loading, downloadProgress } = usePreview()

    const onLoad = () => {
        if (!imageRef.current || !containerRef.current) return
        const containerWidth = containerRef.current.offsetWidth
        const containerHeight = containerRef.current.offsetHeight

        const imageWidth = imageRef.current.offsetWidth
        const imageHeight = imageRef.current.offsetHeight

        const zoom = Math.round(Math.min(containerWidth / imageWidth, containerHeight / imageHeight) / ZOOM_INCREMENT) * ZOOM_INCREMENT
        const closestZoom = Math.max(Math.min(zoom, 1.0), MIN_ZOOM) // não deixa o inicial ser maior que 1.0

        viewer.current.setZoom(closestZoom)
        viewer.current.scrollCenter()
        setZoom(closestZoom)
    }

    const goToPosition = (step: number) => {
        setPosition(step)
    }

    const handleChangeImageList = async () => {
        try {
            const { entries } = await unzip(file)
            const imageObjects: ImageObject[] = []
            const allowedExtensions = ['.png', '.jpg', '.jpeg']

            for (const [name, entry] of Object.entries(entries)) {
                if (allowedExtensions.some((extension) => name.includes(extension)) && !name.startsWith('__MACOSX/')) {
                    const [, index] = name.match(/_(\d+)\.(jpg|jpeg|png)$/i) || []
                    if (index) {
                        const blob = await entry.blob()
                        const newBlob = blob.slice(0, blob.size, 'image/png')
                        const newData = URL.createObjectURL(newBlob)
                        imageObjects.push({ index: Number(index), url: newData })
                    }
                }
            }

            imageObjects.sort((a, b) => a.index - b.index)
            const imageArray = imageObjects.map((obj) => obj.url)
            setImageURLS(imageArray)
            setStatus({
                isPlaying: false,
                time: position,
                totalTime: imageArray.length,
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleChangeImageList()
        setPosition(0)
        createAnnotationFile('NULL')
    }, [file])

    useEffect(() => {
        viewer.current.setZoom(zoom)
        viewer.current.scrollCenter()
    }, [zoom, containerRef])

    useEffect(() => {
        setStatus((prev) => {
            return {
                ...prev,
                time: position + 1,
            }
        })
    }, [position])

    useEffect(() => {
        if (imageURLS[position])
            createAnnotationFile('IMAGE-LIST', imageURLS[position])
    }, [position, imageURLS])

    useEffect(() => {
        setIsHTML(false)
    }, [])

    return (
        <Loading
            isLoading={loading}
            progress={downloadProgress}
            className={classNames('bg-base-300', {
                'absolute top-0 left-0 w-screen h-screen z-[999]': useGrid,
                'absolute top-0 left-0 rounded-md z-[99]': !useGrid,
            })}
        >
            <div
                className={classNames('w-full flex flex-row grow items-center justify-center relative', {
                    'h-full bg-base-300': !useGrid,
                    'h-[calc(95vh-17rem)]': useGrid,
                })}
                ref={containerRef}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <CarrouselButton>
                    <div className='w-full h-full bg-base-300 p-2 rounded-md'>
                        <InfiniteViewer
                            ref={viewer}
                            className='w-full h-full relative'
                            {...dragEvents}
                        >
                            {position == -1 ? (
                                <div className='w-fit flex' ref={imageRef} onLoad={onLoad}>
                                    {imageURLS.map((src) => (
                                        <img
                                            key={src}
                                            src={src}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className='w-fit'>
                                    <img
                                        ref={imageRef}
                                        src={imageURLS[position]}
                                        onLoad={onLoad}
                                    />
                                </div>
                            )}
                        </InfiniteViewer>
                    </div>
                </CarrouselButton>

                <div className={twMerge(
                    'btn-group absolute bottom-4 right-4',
                    isHovering && !isDragging && 'bottom-16'
                )}>
                    <div className='join'>
                        {imageURLS.length > 0 && (
                            <button
                                onClick={() => goToPosition(-1)}
                                data-tooltip-id='my-tooltip'
                                data-tooltip-content={`Vizualizar todos`}
                                className={classNames('join-item btn btn-sm !ring-0', {
                                    'btn-neutral pointer-events-none': position == -1,
                                })}
                            >
                                Todos
                            </button>
                        )}

                        {imageURLS.map((_, i) => (
                            <button
                                key={i}
                                onClick={() => goToPosition(i)}
                                data-tooltip-id='my-tooltip'
                                data-tooltip-content={`Ir para step ${i + 1}`}
                                className={classNames('join-item btn btn-sm !ring-0', {
                                    'btn-neutral pointer-events-none': position == i,
                                })}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>

                <div className={twMerge(
                    'absolute bottom-0 left-0 w-full bg-base-300 bg-opacity-80',
                    isHovering && !isDragging ? 'block' : 'hidden'
                )}>
                    <PreviewControls handleCenterPreview={onLoad} />
                </div>
            </div>
        </Loading>
    )
}

export default PreviewDevelopments
