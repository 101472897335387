import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { CampaignDetails, isGridLayout, router } from '../../AppState'

const useToggleLayout = () => {
    const isGrid = useRecoilValue(isGridLayout)
    const campaign = useRecoilValue(CampaignDetails)
    const [ isMobile, setIsMobile ] = useState<boolean>(window.innerWidth < 1024)

    const useGrid = useMemo(() => isGrid || isMobile, [isGrid, isMobile])

    useEffect(() => {
        const url = `${campaign.url}/${campaign.key}/${router.history.location.search}`
        if(isMobile) router.history.push(url)
    }, [isMobile])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    
    return {
        useGrid,
        isMobile
    }
}

export default useToggleLayout
