import React, { useRef, useEffect, useState } from 'react'
import PreviewControls from './preview.controls'
import CarrouselButton from '../components/carrousel-button'
import InfiniteViewer from 'react-infinite-viewer'

import classNames from 'classnames'
import { useRecoilState } from 'recoil'
import { zoomState } from '../AppState'

import useToggleLayout from '../utils/useToggleLayout/use.toggle-layout'
import Loading from '@src/components/loading'
import { usePreview } from '@src/contexts/usePreview/use.preview'
import { MIN_ZOOM, ZOOM_INCREMENT } from '../utils/zoom'
import { twMerge } from 'tailwind-merge'
import { usePlayerUtils } from './usePlayerUtils'

type Props = {
    file: string
}

const PreviewImage = ({ file }: Props) => {
    const imageRef = useRef<HTMLImageElement | null>(null)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const viewer = useRef<InfiniteViewer>({} as InfiniteViewer)
    const [zoom, setZoom] = useRecoilState(zoomState)
    const { isHovering, setIsHovering, isDragging, dragEvents } = usePlayerUtils()
    const { useGrid } = useToggleLayout()
    const { createAnnotationFile, loading, downloadProgress } = usePreview()

    const onLoad = () => {
        if (!imageRef.current || !containerRef.current) return
        const containerWidth = containerRef.current.offsetWidth
        const containerHeight = containerRef.current.offsetHeight

        const imageWidth = imageRef.current.naturalWidth
        const imageHeight = imageRef.current.naturalHeight

        const zoom = Math.round(Math.min(containerWidth / imageWidth, containerHeight / imageHeight) / ZOOM_INCREMENT) * ZOOM_INCREMENT;
        const closestZoom = Math.max(Math.min(zoom, 1.0), MIN_ZOOM) // não deixa o inicial ser maior que 1.0

        viewer.current.setZoom(closestZoom)
        viewer.current.scrollCenter()
        setZoom(closestZoom)
    }

    useEffect(() => {
        viewer.current.setZoom(zoom)
    }, [zoom, containerRef, imageRef])

    useEffect(() => {
        createAnnotationFile('IMAGE', file)

        return () => createAnnotationFile('NULL')
    }, [file])

    return (
        <Loading
            isLoading={loading}
            progress={downloadProgress}
            className={classNames('bg-base-300', {
                'absolute top-0 left-0 w-screen h-screen z-[999]': useGrid,
                'absolute top-0 left-0 rounded-md z-[99]': !useGrid,
            })}
        >
            <div
                className={classNames('w-full flex flex-row grow items-center justify-center relative', {
                    'h-full bg-base-300': !useGrid,
                    'h-[calc(95vh-17rem)]': useGrid,
                })}
                ref={containerRef}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <CarrouselButton>
                    <InfiniteViewer
                        ref={viewer}
                        className='w-full h-full rounded-md bg-base-300'
                        {...dragEvents}
                    >
                        <div className='w-fit'>
                            <img
                                ref={imageRef}
                                src={file}
                                onLoad={onLoad}
                            />
                        </div>
                    </InfiniteViewer>
                </CarrouselButton>

                <div className={twMerge(
                    'absolute bottom-0 left-0 w-full bg-base-300 bg-opacity-80',
                    isHovering && !isDragging ? 'block' : 'hidden'
                )}>
                    <PreviewControls handleCenterPreview={onLoad} />
                </div>
            </div>
        </Loading>
    )
}

export default PreviewImage
