import { stringToNamespacedTag } from '@src/utils/tags'
import React from 'react'

type Props = {
    tags: string[]
}

const CreativeTags = ({ tags }: Props) => {
    return (
        <div className='opacity-0 group-hover:opacity-100 transition duration-150 ease-out left-0 top-0 h-full w-full absolute bg-base-300/40 pointer-events-none p-1'>
            {tags.map((tag) => (
                <span
                    className='badge bg-slate-500/60 border-none m-1 z-100 rounded-md text-xs text-w text-slate-50 h-auto'
                    key={tag}
                >
                    {stringToNamespacedTag(tag).tag}
                </span>
            ))}
        </div>
    )
}

export default CreativeTags
