import { RefObject, useEffect, useRef, useState } from 'react'

interface IArgsProps extends IntersectionObserverInit {
    freezeOnceVisible?: boolean
}

/**
 *  @typedef {object} IArgsProps
 *  @property {RefObject<Element>} [elementRef = null] - Referencia ao elemento observado.
 *  @property {Object} options - Opções do Intersection Observer.
 *  @property {boolean} [options.freezeOnceVisible = false] - Congela a interesecção após tornar o elemento visível.
 *  @property {number} [options.threshold = 0] - Valor mínimo da intersecçao para acionar a função de retorno.
 *  @property {Element} [options.root = null] - Elemento usado como área de interseção.
 *  @property {string} [options.rootMargin = '0%'] - Margem da área de interseção.
 */

export default function useIntersectionObserver(
    elementRef: RefObject<Element>,
    { freezeOnceVisible = false, threshold = 0, root = null, rootMargin = '0%' }: IArgsProps
) {
    const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null)
    const frozen = entry?.isIntersecting && freezeOnceVisible
    const observerRef = useRef<IntersectionObserver | null>(null)

    const handleUpdateEntry = ([entry]: IntersectionObserverEntry[]): void => {
        setEntry(entry)
    }

    useEffect(() => {
        init()
        return () => destroy()
    }, [])

    const destroy = () => {
        observerRef.current?.disconnect()
        observerRef.current = null
    }

    const init = () => {
        if (observerRef.current) return
        const node = elementRef.current
        const iOSupport = !!window.IntersectionObserver

        if (!iOSupport || frozen || !node) return
        const options = { threshold, root, rootMargin }
        const observer = new IntersectionObserver(handleUpdateEntry, options)
        observerRef.current = observer

        if (!node) return
        observer.observe(node)
    }

    return {
        entry,
        destroy,
        init,
    }
}
