import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useEditor } from '@src/contexts/useEditor/use.editor'
import ButtonClose from '../button-close.dialog'
import { PiPlusBold } from 'react-icons/pi'
import Tools from '@src/components/editor/tools'
import Submit from '@src/components/editor/comments/submit'
import { useRecoilValue } from 'recoil'
import { CampaignDetails, PlayerStatus } from '@src/AppState'
import Editor from '@src/components/editor'
import { usePreview } from '@src/contexts/usePreview/use.preview'
import { twMerge } from 'tailwind-merge'
import useDraw from '@src/utils/useDraw'
import { usePreviewNavigation } from '@src/utils/useNavigationHandlers'

export default function AddNoteDialog() {
  const dialogRef = useRef<HTMLDialogElement | null>(null)
  const { setShapes, shapes, setSelectedShape } = useEditor()
  const drawHook = useDraw()
  const campaign = useRecoilValue(CampaignDetails)
  const { isPlaying } = useRecoilValue(PlayerStatus)
  const { creative, annotationFile } = usePreview()
  const { setAllowKeyPressNavigation } = usePreviewNavigation()
  const hasEdited = useRef<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const openModal = () => {
    if (!dialogRef.current) return;
    dialogRef.current.showModal()
    setIsEditing(true)
    setAllowKeyPressNavigation(false)
  }

  const closeModal = useCallback((askConfirmation = false) => {
    if (!dialogRef.current) return;

    if (askConfirmation && (shapes.length > 0 || hasEdited.current)) {
      const confirm = window.confirm('Você tem anotações não salvas. Deseja descartá-las?')
      if (!confirm) return;
    }

    dialogRef.current.close()
    setShapes([])
    hasEdited.current = false
    setIsEditing(false)
    drawHook.clearHistory()
    setAllowKeyPressNavigation(true)
  }, [drawHook.clearHistory, setShapes, shapes])

  useEffect(() => {
    setShapes([])
  }, [annotationFile])

  const handleClickOutsideStage = useCallback(() => {
    setSelectedShape(undefined)
  }, [])

  useEffect(() => {
    if (!dialogRef.current) return;

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        closeModal(true)
      }
    }

    (dialogRef.current as HTMLDialogElement).addEventListener('keydown', handleKeyPress)
    return () => {
      if (!dialogRef.current) return;
      (dialogRef.current as HTMLDialogElement).removeEventListener('keydown', handleKeyPress)
    }
  }, [closeModal])

  if (!creative) return null

  return (
    <>
      <button
        onClick={openModal}
        className={twMerge(
          'btn btn-sm btn-neutral',
          isPlaying && 'hidden',
        )}
        disabled={isPlaying}>
        <PiPlusBold />
        Adicionar nota
      </button>

      <dialog className='modal p-4' ref={dialogRef} onClick={handleClickOutsideStage}>
        <ButtonClose onClick={() => closeModal(true)} />

        {isEditing && (
          <div className='modal-box w-full h-full max-w-none max-h-screen p-2'>
            <div className='h-full space-y-2'>
              <div className='flex flex-col overflow-hidden space-x-2 h-full'>
                <div className='w-full h-full'>
                  <Editor file={annotationFile?.blob || ''} drawHook={drawHook} />
                </div>

                <div className={twMerge(
                  'grid grid-cols-2 py-4 px-8 bg-[#090F1D] rounded-xl mt-4',
                )}>
                  <div className='w-full h-full flex items-center justify-end pr-8'>
                    <Tools drawHook={drawHook} />
                  </div>
                  <div className='w-1/2 h-full'>
                    <Submit
                      onSuccess={() => closeModal()}
                      onEditComment={(value) => { hasEdited.current = value }}
                      creative={creative}
                      campaignKey={campaign.key}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </dialog>
    </>
  )
}