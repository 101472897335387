import { useRecoilState } from 'recoil'

import { PlayerStatus } from '../AppState'

type Props = {
    step?: number
    actualTime: number
}

const PreviewTimeBarControl = ({ step, actualTime }: Props) => {
    const [{ isPlaying, totalTime }, setStatus] = useRecoilState(PlayerStatus)

    const updateActualTime = (e: any) => {
        if (!isPlaying) {
            const value = e.target.value

            setStatus((state: any) => {
                return {
                    ...state,
                    time: Number(value),
                }
            })
        }
    }

    return (
        <input
            type='range'
            min='0'
            max={totalTime}
            className='range range-xs w-full'
            value={actualTime}
            step={step || 1}
            onChange={updateActualTime}
        />
    )
}

export default PreviewTimeBarControl
