import React, { ComponentProps } from 'react'

type Props<T> = ComponentProps<'input'> & {
    label: string
    onStateChange: React.Dispatch<React.SetStateAction<T>>
}

const TextField = <T,>({label, name, placeholder, value, onStateChange }: Props<T>) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onStateChange(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value 
            }
        })
    }
    
    return (
        <>
            <label className='label'>
                <span className='label-text'>{label}</span>
            </label>
            <input
                type='text'
                className='input input-bordered w-full'
                value={value}
                name={name}
                onChange={handleChange}
            />
            <label className='hidden sm:label'>
                <span className='label-text-alt '>Ex: {placeholder} </span>
            </label>
        </>
    )
}

export default TextField
