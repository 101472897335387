import React, { useState } from 'react'
import dayjs from 'dayjs'
import { twMerge } from 'tailwind-merge'

type Props = {
    createdAt: string
}

export default function ({ createdAt }: Props) {
    const [isHovering, setIsHovering] = useState(false)

    return (
        <time
            className='ml-2 text-xs relative'
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <span className='opacity-50'>{dayjs(createdAt).locale('pt-br').fromNow()}</span>
            {isHovering && (
                <span className={twMerge(
                    'absolute top-4 -left-4 z-20 bg-slate-600 text-white text-xs p-1 rounded-md',
                    ' whitespace-nowrap',
                )}>
                    {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
                </span>
            )}
        </time>
    )
}
