export const formatTime = (milliseconds: number) => {
  if (milliseconds < 0) return ''

  const millisecondsTime = milliseconds % 1000

  if (milliseconds < 1000) return `00:${milliseconds > 99 ? milliseconds.toFixed(0) : milliseconds > 9 ? '0' + milliseconds.toFixed(0) : '00' + milliseconds.toFixed(0)}`

  const seconds = Math.floor(milliseconds / 1000)
  const secondsTime = seconds > 9 ? seconds.toFixed(0) : '0' + seconds.toFixed(0)

  return `${secondsTime}:${millisecondsTime > 99 ? millisecondsTime.toFixed(0) : millisecondsTime > 9 ? '0' + millisecondsTime.toFixed(0) : '00' + millisecondsTime.toFixed(0)}`
}

export const positionFromTime = (milliseconds: number, totalTime: number): string => {
  return `${((milliseconds / totalTime) * 100).toFixed(2)}%`
}
