import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import UserComponent from './user.component'
import { router } from '../../../AppState'
import Loading from '../../../components/loading'
import AddUsersDialog from '../../users/add-users-dialog'


type User = {
    email: string
    key: string
}

const Users = () => {
    const [showDialog, setShowDialog] = useState(false)
    const params = useParams()
    const { client, campaign } = params

    const [users, setUsers] = useState<User[]>([])
    const [loading, isLoading] = useState(true)

    const getUsers = async () => {
        isLoading(true)

        try {
            const request = await axios.get(`/api/admin/campaign/${campaign}/users`)
            const response = request.data
    
            if (response.ok) {
                setUsers(response.items)
            }
        } catch (err) {
            console.error(err)
        } finally {
            isLoading(false)
        }
    }

    useEffect(() => {
        getUsers()
    }, [campaign])

    const onDelete = (key: string) => {
        setUsers((value) => {
            return value.filter((v: any) => v.key !== key)
        })
    }

    const openDialog = () => {
        setShowDialog(true)
    }

    const closeDialog = () => {
        setShowDialog(false)
    }

    const onSubmit = async () => {
        await getUsers()
        closeDialog()
    }

    return loading ? (
        <Loading className='h-96' />
    ) : (
        <>
            <div className='container mx-auto w-full max-w-4xl bg-base-300 sm:rounded-box mt-6 p-4 relative'>
                <div className='w-full mx-auto p-4 flex flex-row justify-between items-center'>
                    <h1 className='text-lg sm:text-2xl font-bold grow'>Lista de usuários</h1>
                    <button
                        className='btn btn-neutral btn-sm'
                        onClick={openDialog}
                        data-tooltip-id='my-tooltip'
                        data-tooltip-html='Adicionar usuário'
                        data-tooltip-place='top'
                    >
                        +
                    </button>
                </div>

                <div className='w-full mx-auto p-4 bg-base-200'>
                    {users.map((u) => (
                        <UserComponent onDelete={onDelete} user={u} key={u.key}/>
                    ))}
                    {users.length === 0 && (
                        <div className='flex flex-col justify-center py-10'>
                            <h3 className='text-md text-center font-semibold grow mb-4'>
                                Nenhum usuário cadastrado usuários
                            </h3>
                            <a
                                className='btn btn-primary mx-auto'
                                onClick={openDialog}
                            >
                                Adicionar usuários
                            </a>
                        </div>
                    )}
                </div>
            </div>
            {showDialog && client && campaign && (
                <AddUsersDialog client={client} campaign={campaign} onClose={closeDialog} onSubmit={onSubmit} />
            )}
        </>
    )
}

export default Users
