import axios from 'axios'
import React, { useEffect, useState, memo, useRef } from 'react'
import { unzip } from 'unzipit'

import useIntersectionObserver from '../../../utils/useIntersectObserver'

interface ICreativeViewProps {
    // creativeKey: string
    revision: string
    width: number
    height: number
    name: string
    type: 'video/mp4' | 'image/jpg' | 'image/jpeg' | 'image/png' | 'gif' | 'image/gif' | string
}

export const cachedFiles = new Map()

const CreativeView = ({ height, width, revision, name, type }: ICreativeViewProps) => {
    const elementRef = useRef<HTMLDivElement | null>(null)
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [loading, setLoading] = useState(true)
    const [file, setFile] = useState<string | null>(null)

    const { entry, init, destroy } = useIntersectionObserver(elementRef, {})

    const loadFile = async () => {
        try {
            setLoading(true)
            destroy()

            const url = `/api/common/file/${name}/${revision}`

            let cachedUrl = url
            if (type === 'application/zip') {
                cachedUrl += '/image'
            }
            if (cachedFiles.has(cachedUrl)) {
                setFile(cachedFiles.get(cachedUrl))
                return
            }

            const request = await axios.get(url, {
                responseType: 'blob',
            })
            const result = request.data

            if (result) {
                const blob = result.slice(0, result.size, type)
                const data = URL.createObjectURL(blob)

                if (type === 'application/zip') {
                    const imageBlob = await loadImageFromZip(data)

                    if (!imageBlob) throw new Error('Imagem de backup não encontrada')

                    const newBlob = imageBlob.slice(0, imageBlob.size, 'image/jpg')
                    const newData = URL.createObjectURL(newBlob)

                    cachedFiles.set(cachedUrl, newData)
                    setFile(newData)
                } else {
                    cachedFiles.set(url, data)
                    setFile(data)
                    if (type === 'video/mp4') {
                        videoRef.current?.addEventListener('loadedmetadata', function () {
                            this.currentTime = this.duration - 1;
                        });
                    }
                }
            }
        } catch (e) {
            console.error('Creative preview failed', e)
        } finally {
            setLoading(false)
        }
    }

    const loadImageFromZip = async (file: string): Promise<Blob | undefined> => {
        const { entries } = await unzip(file)

        for (const res of Object.entries(entries)) {
            const name = res[0]
            const entry = res[1]

            if (name.includes('.jpg') || name.includes('.jpeg') || name.includes('.png')) {
                const blob = await entry.blob()

                return blob
            }
        }
    }

    useEffect(() => {
        const isVisible = !!entry?.isIntersecting

        if (isVisible) loadFile()
    }, [entry])

    useEffect(() => {
        init()

        return () => {
            destroy()
        }
    }, [name, revision])

    const onMouseEnter = () => {
        videoRef.current?.play()
    }

    const onMouseLeave = () => {
        videoRef.current?.pause()
    }

    if (!loading && file === null) {
        return (
            <div className='artboard-demo bg-base-300 p-10 text-center'>
                Preview indisponível <br /> {width && height && `${width}x${height}`}
            </div>
        )
    }

    return (
        <div
            ref={elementRef}
            className={`teste-ratio w-full rounded-lg bg-slate-500 relative ${loading && 'animate-pulse'}`}
            style={height !== undefined && width !== undefined && height / width > 0.2
                ? { height: 0, paddingBottom: (height / width) * 100 + '%' }
                : { minHeight: '20px' }
            }
        >
            {file &&
                (type === 'video/mp4' ? (
                    <video
                        ref={videoRef}
                        src={file}
                        className='rounded-lg w-full'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        muted
                        loop
                        onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = (e.target as HTMLVideoElement).duration }}
                        onPlay={e => { (e.target as HTMLVideoElement).currentTime = (e.target as HTMLVideoElement).duration - 0.1 }}
                    />
                ) : (
                    <img className='rounded-lg w-full' src={file} alt={file} />
                ))}
        </div>
    )
}

const equal = (prevProps: any, nextProps: any) => {
    return prevProps.creativeKey !== nextProps.creativeKey
}

export default memo(CreativeView, equal)
