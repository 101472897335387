const Empty = () => {
    return (
        <div className='hero h-[calc(100vh-4rem)] bg-base-200'>
            <div className='hero-content text-center'>
                <div className='max-w-md'>
                    <h1 className='text-5xl font-bold'>404</h1>
                    <p className='py-6'>
                        Não há conteúdo disponível nesse endereço. Pode ser que o link esteja incorreto ou
                        desatualizado.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Empty
