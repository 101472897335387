import { useEditor } from '@src/contexts/useEditor/use.editor';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Shape } from '../useDraw/draw.types';

interface ActionStack {
  action: string
  value?: any
  index?: number
}

export const useHistory = () => {
  const {
    shapes,
    setShapes,
  } = useEditor()

  const [actionsStack, setActionsStack] = useState<ActionStack[]>([])
  const shapesRef = useRef<Shape[]>([])

  const addToHistory = useCallback((action: string, value?: any, index?: number) => {
    setActionsStack(prev => [
      ...prev,
      { action, value, index },
    ])
  }, [])

  const removeLastFromHistory = useCallback(() => {
    const allActions = [...actionsStack]
    const lastAction = allActions.pop()
    setActionsStack(allActions)
    return lastAction;
  }, [actionsStack])

  const deleteLastShape = useCallback(() => {
    const allShapes = [...shapesRef.current]
    allShapes.pop()
    setShapes(allShapes)
  }, [])

  const restoreDeletedShape = useCallback((deletedShape: Shape, index: number) => {
    const allShapes = [...shapesRef.current]
    allShapes.splice(index, 0, deletedShape)
    setShapes(allShapes)
  }, [])

  const restoreShapeProperties = useCallback((properties: Shape, index: number) => {
    const allShapes = [...shapesRef.current]
    allShapes[index].shape = properties
    setShapes(allShapes)
  }, [])

  const restoreText = useCallback((text: string, id: string) => {
    const allShapes = [...shapesRef.current]
    const index = allShapes.findIndex((shape) => shape.shape.id === id)
    if (index === -1) return;

    allShapes[index].shape.text = text
    setShapes(allShapes)
  }, [])

  const undoHistory = useCallback(() => {
    const lastAction = removeLastFromHistory()
    if (!lastAction) return

    const { action, value, index } = lastAction

    if (action === 'create') {
      deleteLastShape()
    } else if (action === 'delete') {
      restoreDeletedShape(value, index as number)
    } else if (action === 'properties') {
      restoreShapeProperties(value, index as number)
    } else if (action === 'text-editing') {
      restoreText(value.text, value.id)
    }
  }, [actionsStack, removeLastFromHistory, deleteLastShape, restoreDeletedShape, restoreShapeProperties])

  const clearHistory = () => {
    setActionsStack([])
  }

  useEffect(() => {
    return () => {
      clearHistory()
    }
  }, [])

  useEffect(() => {
    shapesRef.current = shapes
  }, [shapes])

  return {
    addToHistory,
    clearHistory,
    undoHistory,
    shapesRef,
    actionsStack,
  };
}
