import React, { useState } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import toast from 'react-hot-toast'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import { currentCreative, CreativesList, currentUserAtom, CampaignDetails } from '../AppState'
import { statusDictionary } from '../utils/status'
import { useFireworks } from './useFireworks'
import StatusDialog from './status-reprove-dialog'

const CreativeStatus = () => {
    const [statusUpdating, setStatusUpdating] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const { animation, fire } = useFireworks()
    const [creative, setCreative] = useRecoilState(currentCreative)
    const setCreatives = useSetRecoilState(CreativesList)
    const params = useParams()
    const client = params.client as string
    const campaign = params.campaign as string
    const currentUser = useRecoilValue(currentUserAtom)
    const { key: campaignKey } = useRecoilValue(CampaignDetails)

    if (!creative || !creative.status) {
        return null
    }

    const updateCreativeStatus = async (status: string) => {
        setStatusUpdating(true)
        if (!creative) return
        try {
            const latestRevision = [...creative.revisions].pop()
            const request = await axios.post(
                `/api/common/status/${client}/${campaign}/${campaignKey}/${creative.key}/${latestRevision}`,
                {
                    status,
                }
            )
            const result = request.data

            if (result.ok === true) {
                if (status === 'approved') {
                    try {
                        fire()
                    } catch (e) {}
                }

                setCreative((c: any) => ({
                    ...c,
                    status,
                }))

                setCreatives((creatives) => {
                    const creativesTemp = [...creatives]

                    return creativesTemp.map((c) => {
                        const cTemp = { ...c }

                        if (cTemp.key === creative.key)
                            return {
                                ...c,
                                status,
                            }
                        else return c
                    })
                })

                toast.success('Status alterado com sucesso')
            }
        } catch (err) {
            toast.error('Alguma coisa deu errado =/')
        }
        setStatusUpdating(false)
    }

    const openDialog = () => {
        setShowDialog(true)
    }

    const closeDialog = () => {
        setShowDialog(false)
    }

    const onReprove = () => {
        updateCreativeStatus('reproved')
        closeDialog()
    }

    return (
        <div className='flex flex-col xl:flex-row xl:items-center'>
            <h3 className='text-md pr-2'>Status:</h3>
            <div className='dropdown dropdown-top dropdown-end w-full'>
                <div className='text-xs flex items-center'>
                    <label
                        tabIndex={0}
                        className={`btn btn-sm text-xs ${currentUser?.isReviewer ? 'btn-disabled' : ''} ${
                            statusUpdating ? 'loading' : ''
                        }`}
                    >
                        {statusUpdating ? '' : statusDictionary[creative.status as keyof typeof statusDictionary]}
                        <ChevronDownIcon className='ml-4 h-4' />
                    </label>
                </div>
                {!currentUser?.isReviewer && (
                    <ul tabIndex={0} className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52'>
                        {/* <li
                            hidden={currentUser?.company === 'stormx'}
                            onClick={
                                currentUser?.company === 'stormx' ? () => updateCreativeStatus('revised') : undefined
                            }
                        >
                            <a>{statusDictionary['revised']}</a>
                        </li> */}
                        <li
                            hidden={!currentUser?.canApprove || currentUser?.company === 'stormx'}
                            onClick={() => updateCreativeStatus('approved')}
                        >
                            <a>{statusDictionary['approved']}</a>
                        </li>
                        <li onClick={openDialog}>
                            <a>{statusDictionary['reproved']}</a>
                        </li>
                    </ul>
                )}
            </div>
            {showDialog && <StatusDialog onSubmit={onReprove} onClose={closeDialog} />}
            {animation}
        </div>
    )
}

export default CreativeStatus
