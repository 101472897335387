import { stringToNamespacedTag } from '@src/utils/tags'
import React from 'react'

type Props = {
    tags: string[]
}

const CreativeTagsOnList = ({ tags }: Props) => {
    return (
        <>
            {tags.map((tag) => (
                <span
                    className='badge bg-slate-500/60 border-none m-1 z-100 rounded-md text-xs text-w text-slate-50 h-auto'
                    key={tag}
                >
                    {stringToNamespacedTag(tag).tag}
                </span>
            ))}
        </>
    )
}

export default CreativeTagsOnList
