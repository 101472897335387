import React, { useMemo } from 'react'
import { GifIcon, VideoCameraIcon, PhotoIcon, ArchiveBoxIcon } from '@heroicons/react/24/outline'
interface ICreativeTypeProps {
    type: 'video/mp4' | 'image/jpg' | 'image/jpeg' | 'image/png' | 'gif' | 'image/gif' | string
}

export default function CreativeType({ type }: ICreativeTypeProps) {
    const iconByType = useMemo(() => {
        switch (type) {
            case 'video/mp4':
                return <VideoCameraIcon color='#1E1E1E' />
            case 'image/jpg':
                return <PhotoIcon color='#1E1E1E' />
            case 'image/jpeg':
                return <PhotoIcon color='#1E1E1E' />
            case 'image/png':
                return <PhotoIcon color='#1E1E1E' />
            case 'gif':
                return <GifIcon color='#1E1E1E' />
            case 'image/gif':
                return <GifIcon color='#1E1E1E' />
            default:
                return <ArchiveBoxIcon color='#1E1E1E' />
        }
    }, [type])

    return <div className='rounded-full bg-slate-300 w-10 h-10 p-[0.6rem] opacity-40'>{iconByType}</div>
}
