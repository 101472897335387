import React, { useEffect } from 'react'

import CreativeItem from './creative-item'
import { TbLayoutSidebarRightExpand as SidebarOpened, TbLayoutSidebarLeftExpand as SidebarClosed } from 'react-icons/tb'

import { useRecoilState, useRecoilValue } from 'recoil'
import { CampaignDetails, campaignDetailsSelector, listOpenedState } from '../../../AppState'
import classNames from 'classnames'

import { Creative } from '../../../types'
import Dropdown from '../../../components/dropdown/dropdown'
import CampaignStatus from '../../../components/campaign-status'
import { usePreviewNavigation } from '@src/utils/useNavigationHandlers'
import { twMerge } from 'tailwind-merge'
import FilterArea from '../filter-area'

type Props = {
    creatives: Creative[]
}

const creativeItemHeight = 60;

const ListingController = ({ creatives }: Props) => {
    const [listOpened, setListOpened] = useRecoilState(listOpenedState)
    const details = useRecoilValue(campaignDetailsSelector)
    const campaign = useRecoilValue(CampaignDetails)
    const creativeListRef = React.useRef<HTMLDivElement>(null)
    const { subscribeNavigation } = usePreviewNavigation()

    const handleOpenList = () => {
        setListOpened(!listOpened)
    }

    useEffect(() => {
        subscribeNavigation((_, nextCreativeIndex) => {
            scrollToCreative(nextCreativeIndex)
        })
    }, [])

    const scrollToCreative = (selectedCreativeIndex: number) => {
        if (!creativeListRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = creativeListRef.current
        if (scrollHeight <= clientHeight) return;

        const creativeItemOffset = selectedCreativeIndex * creativeItemHeight

        if (scrollTop + clientHeight <= creativeItemOffset) {
            creativeListRef.current.scrollTo({
                top: creativeItemOffset - clientHeight + creativeItemHeight,
                behavior: 'smooth',
            })
        } else if (scrollTop > creativeItemOffset) {
            creativeListRef.current.scrollTo({
                top: creativeItemOffset,
                behavior: 'smooth',
            })
        }

    }

    return (
        <div
            className={twMerge('flex relative',
                listOpened ? 'w-[300px]' : 'w-8',
            )}
        >
            <div className='w-full flex flex-col gap-y-4'>
                <h2 className={twMerge(
                    'text-md text-info font-semibold pl-2 mb-4',
                    listOpened ? 'block' : 'hidden',
                )}>
                    Campanha: <span className='uppercase'>{campaign.name}</span>
                </h2>

                <div className={twMerge(
                    'pr-3',
                    listOpened ? 'block' : 'hidden',
                )}>
                    <FilterArea />
                </div>

                <div
                    ref={creativeListRef}
                    className={classNames(
                        'flex w-full h-full bg-base-300 flex-col overflow-y-scroll overflow-c overflow-x-hidden rounded-md divide-y-4 custom-scrollbar transition-all duration-300',
                        {
                            'h-[0] opacity-0 max-w-0': !listOpened,
                            'h-auto opacity-100 max-w-full': listOpened,
                        }
                    )}
                >
                    {creatives.map((creative) => (
                        <CreativeItem key={creative.key} creative={creative} />
                    ))}
                </div>

                {listOpened && (
                    <Dropdown
                        title='Detalhes'
                        label={`Exibindo ${details.displaying} de ${details.total}.`}
                        children={<CampaignStatus />}
                    />
                )}
            </div>

            <button
                className={classNames(
                    'btn btn-neutral w-8 btn-md p-[0.3rem] focus:z-[999]',
                    {
                        'h-full': !listOpened,
                        'absolute -ml-3 z-[100] left-[100%] rounded-l-none': listOpened,
                    }
                )}
                onClick={handleOpenList}
                data-tooltip-id='my-tooltip'
                data-tooltip-place='right'
                data-tooltip-content={listOpened ? 'Minimizar lista de criativos' : 'Expandir lista de criativos'}
            >
                {listOpened ? <SidebarOpened size='2rem' /> : <SidebarClosed size='2rem' />}
            </button>
        </div>
    )
}

export default ListingController
