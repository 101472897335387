import Loading from '@src/components/loading';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FaFileImage as AttachedImageIcon } from "react-icons/fa";
import ButtonClose from '../button-close.dialog';
import { ApiService } from '@src/services/api/api.service';
import { twMerge } from 'tailwind-merge';

type Props = {
  commentKey: string
  commentText: string
}

export default function LightBox({ commentKey, commentText }: Props) {
  const apiService = new ApiService()
  const dialogRef = useRef<HTMLDialogElement | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [limitModalWidth, setLimitModalWidth] = useState<boolean>(false)

  const handlePress = (evt: MouseEvent<HTMLButtonElement>) => {
    if (!dialogRef.current) return

    evt.preventDefault?.()
    const dialog = dialogRef.current

    if (dialog.open) {
      dialog.close()
    } else {
      dialog.showModal()
    }
  }

  const getImage = useCallback(async () => {
    setLoading(true)

    const { data } = await apiService.getCommentFile(commentKey)
    const img = URL.createObjectURL(data)

    const imgElement = new Image()

    imgElement.onload = () => {
      setLimitModalWidth(imgElement.width <= 520)

      if (imgRef.current) {
        imgRef.current.src = img
      }

      setLoading(false)
    }

    imgElement.src = img
  }, [commentKey])

  useEffect(() => {
    getImage()
  }, [commentKey])

  return (
    <>
      <button className='btn btn-sm btn-neutral w-fit' onClick={handlePress}>
        <AttachedImageIcon />
        Ver anexo
      </button>
      <dialog className='modal bg-[#0e1729]/80' ref={dialogRef} onClick={() => handlePress({} as any)}>
        <div
          className='modal-box w-fit max-w-[90%] relative'
          onClick={(e) => e.stopPropagation()}
        >
          <ButtonClose onClick={handlePress} />
          <div className='dialog w-fit'>
            <div className={twMerge(
              'h-full flex flex-col space-y-6',
              limitModalWidth && 'w-[520px]'
            )}>
              <Loading isLoading={loading}>
                <img ref={imgRef} className='mx-auto w-fit h-full' />
              </Loading>
              <p className='p-4 rounded-lg bg-black/30 w-full'>
                {commentText}
              </p>
            </div>
          </div>
        </div>
      </dialog>
    </>
  )
}