import React from 'react'
import { useRecoilValue } from 'recoil'
import { campaignDetailsSelector } from '../../AppState'
import { statusDictionary } from '../../utils/status'
const CampaignStatus = () => {
    const details = useRecoilValue(campaignDetailsSelector)

    return(
        <div className='flex flex-col gap-y-1'>
            {Object.keys(details).map(prop => {
                if(!statusDictionary.hasOwnProperty(prop)) return null
                return <p key={prop}> {statusDictionary[prop]}: {details[prop]} </p>
            })}
        </div>
    )
}

export default CampaignStatus