import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode
    label: string 
    title: string
}
const Dropdown = ({ label, title, children}) => {
    return (
        <div className='dropdown w-full dropdown-end dropdown-top'>
            <label tabIndex={0} className='btn btn-neutral btn-sm w-full'>
                {label}
            </label>
            <div
                tabIndex={0}
                className='dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-base-100 text-white'
            >
                <div className='card-body'>
                    <h3 className='card-title'>{title}</h3>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default Dropdown
