import React, { ReactNode } from 'react'
import { BsChevronLeft as LeftIcon, BsChevronRight as RightIcon } from 'react-icons/bs'
import { usePreviewNavigation } from '../../utils/useNavigationHandlers'

type Props = {
    children: ReactNode
}

const CarrouselButton = ({ children }: Props) => {
    const { handleNext, handlePrevious } = usePreviewNavigation()

    return (
        <>
            <div className='w-full h-full relative'>
                <div className='flex items-center justify-between w-full h-full absolute p-2'>
                    <button
                        className='btn btn-circle btn-sm opacity-75 z-[99]'
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content='Anterior [←]'
                        data-tooltip-place='right'
                        onClick={handlePrevious}
                    >
                        <LeftIcon />
                    </button>
                    <button
                        className='btn btn-circle btn-sm opacity-75 z-[99]'
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content='Próximo [→]'
                        data-tooltip-place='left'
                        onClick={handleNext}
                    >
                        <RightIcon />
                    </button>
                </div>
                {children}
            </div>
        </>
    )
}
export default CarrouselButton
